import { IoIosClose } from "react-icons/io"

function AdminUserProfile({ user, onClose }) {

    const formatBirthday = (date) => {
        const d = new Date(date);
        const day = d.getDate().toString().padStart(2, '0');
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const year = d.getFullYear();
        return `${day}.${month}.${year}`;
    };   

    return (
        <div className='overflow-y-hidden overflow-x-hidden fixed top-0 left-0 right-0 bottom-0 bg-black/50 z-[10000] justify-center items-center flex'>
            <div className='bg-white mobile:rounded-none xl:rounded-lg mobile:p-0 xl:p-8 mobile:w-screen mobile:h-screen xl:h-[600px] flex flex-row xl:w-5/12'>
                <div className='mobile:w-full w-8/12'>
                    <div className='w-full justify-end flex mobile:p-4 xl:p-0'>
                        <button onClick={onClose} className='w-7 h-7 justify-center items-center bg-black/80 rounded-full flex group hover:opacity-50'>
                            <IoIosClose className='group-hover:opacity-50' size={24} color='#FFF'/>
                        </button>
                    </div>
                    <div className='px-8 overflow-x-auto h-full'>
                        <p className='text-2xl font-medium'>Профиль</p>
                        <div className='flex mobile:flex-col justify-between'>
                            <div className='flex mobile:flex-col xl:flex-col items-center mobile:space-x-0 space-y-3'>
                                <img src={`https://avto-pro.kz/api-userImage/${user.photo}`}  className='w-32 h-32 rounded-full object-cover'/>
                                <div className='flex mobile:flex-row xl:flex-row space-x-6'>
                                    <div className='items-center justify-center flex flex-col'>
                                        <p className='text-xl text-black/50'>Имя</p>
                                        <p className='text-xl text-black'>{user.fullname}</p>
                                    </div>
                                    <div className='items-center justify-center flex flex-col'>
                                        <p className='text-xl text-black/50'>Фамилия</p>
                                        <p className='text-xl text-black'>{user.lastname}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex mobile:flex-col lg:flex-row lg:px-32 xl:px-0 justify-between lg:space-x-12 lg:space-y-0 mobile:space-y-4 xl:space-y-0 xl:flex-row xl:justify-between mt-4 mb-12'>
                            <div className='space-y-6'>
                                <div className="flex flex-col justify-start items-start">
                                    <p className='text-2xl text-black/50 text-left'>Email</p>
                                    <p className='text-2xl text-black text-left'>{user.email}</p>
                                </div>
                                <div>
                                    <p className='text-2xl text-black/50'>Номер телефона</p>
                                    <p className='text-2xl text-black'>{user.phone}</p>
                                </div>
                            </div>
                            <div className='space-y-6'>
                                <div>
                                    <p className='text-2xl text-black/50'>Пол</p>
                                    <p className='text-2xl text-black'>{user.sex}</p>
                                </div>
                                <div>
                                    <p className='text-2xl text-black/50'>Дата рождения</p>
                                    <p className='text-2xl text-black'>{formatBirthday(user.birthday)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AdminUserProfile