import React, { Component } from 'react';
import { IoIosClose } from "react-icons/io";

class NewsModal extends Component {
    render() {
        const { data, onClose } = this.props;
        return (
            <div className='fixed top-0 left-0 right-0 bottom-0 bg-black/50 z-[10000] justify-center items-center flex'>
                <div className='bg-white rounded-lg p-4 mobile:w-10/12 xl:w-6/12'>
                    <div className='w-full justify-end flex'>
                        <button onClick={() => onClose()} className='w-7 h-7 justify-center items-center bg-black/80 rounded-full flex group hover:opacity-50'>
                            <IoIosClose className='group-hover:opacity-50' size={24} color='#FFF'/>
                        </button>
                    </div>
                    <div className='px-6 py-6 space-y-3 overflow-y-scroll mt-4 h-[500px]'>
                        <p className='text-2xl font-bold'>{data.title}</p>
                        <p className='text-black/50 text-xl'>{data.time}</p>
                        <p className='text-black text-base'>{data.description}</p>
                        { data.secondPhoto && <img src={`https://avto-pro.kz/api-newsImage/${data.secondPhoto}`} className='w-full mobile:h-64 lg:h-80 xl:h-96 object-cover' loading='eager'/> }
                    </div>
                </div>
            </div>
        );
    }
}

export default NewsModal;
