import React, { useState, useEffect } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import queryString from "query-string";

function Pagination({ totalCount }) {
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState(1);
    const queryParams = queryString.parse(location.search);
    const { item, category } = useParams();
    const navigation = useNavigate();

    useEffect(() => {
        setCurrentPage(parseInt(queryParams.page));
    }, [queryParams.page]);

    const totalPages = Math.ceil(totalCount / 10); // Определение общего количества страниц
    let startPage = Math.max(currentPage - 9, 1); // Начальная страница для отображения
    let endPage = Math.min(startPage + 9, totalPages); // Конечная страница для отображения

    // Метод для увеличения значения currentPage на один
    const handleNextPage = () => {
        const nextPage = currentPage + 1;
        navigation(`/${item}/${category}?city=${queryParams.city}&page=${nextPage}`);
    };

    // Метод для уменьшения значения currentPage на один
    const handlePreviousPage = () => {
        const prevPage = currentPage - 1;
        navigation(`/${item}/${category}?city=${queryParams.city}&page=${prevPage}`);
    };

    return (
        <div className='w-screen justify-center items-center flex flex-row space-x-4 mobile:mt-6 laptop:mt-12 mobile:mb-6 laptop:mb-0'>
            <button disabled={currentPage === 1} className='bg-[#6398E7]/15 mobile:w-7 mobile:h-7 sm:w-10 sm:h-10 rounded-lg justify-center items-center flex group hover:bg-[#6398E7]/50' onClick={handlePreviousPage}>
                <IoIosArrowBack className='text-[#4D89E3]/25 group-hover:text-[#4D89E3]' size={24} />
            </button>
            <div className='flex flex-row space-x-2'>
                {[...Array(endPage - startPage + 1)].map((_, index) => {
                    const pageNumber = startPage + index;
                    return (
                        <Link to={`/${item}/${category}?city=${queryParams.city}&page=${pageNumber}`} key={pageNumber} className={currentPage === pageNumber ? 'bg-[#6398E7] mobile:w-7 mobile:h-7 sm:w-10 sm:h-10 rounded-lg justify-center items-center flex group' : 'bg-[#6398E7]/15 mobile:w-7 mobile:h-7 sm:w-10 sm:h-10 rounded-lg justify-center items-center flex group hover:bg-[#6398E7]' }>
                            <p className='text-white font-medium text-xl'>{pageNumber}</p>
                        </Link>
                    )
                })}
            </div>
            <button disabled={currentPage === totalPages} className='bg-[#6398E7]/15 mobile:w-7 mobile:h-7 sm:w-10 sm:h-10 rounded-lg justify-center items-center flex group hover:bg-[#6398E7]/50' onClick={handleNextPage}>
                <IoIosArrowForward className='text-[#4D89E3]/25 group-hover:text-[#4D89E3]' size={24} />
            </button>
        </div>
    );
}

export default Pagination;

