import React, { Component, useState } from 'react';
import { FaRegEye, FaRegEyeSlash  } from "react-icons/fa6";
import { IoMdCheckmark } from 'react-icons/io';

function Auth({ onToggleMethod }) {
    const [login, onChangeLogin] = useState();
    const [password, onChangePassword] = useState();
    const [isCheckBox, setIsCheckBox] = useState(false);
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    const setCookie = (name, value, days) => {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        const expires = "expires=" + date.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
    };

    const auth = async () => {
        const data = {
            login: login,
            password: password,
        }
        
        try {
            const response = await fetch('https://avto-pro.kz/api-login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json' // Установка заголовка Content-Type на application/json
                },
                body: JSON.stringify(data) // Преобразование объекта в JSON-строку
            });

            
            if (response.ok) {
                const responseJson = await response.json();
                if (responseJson.success) {
                    setErrorMessage();
                    setCookie('auth_token', responseJson.token, 7);
                    window.location.reload();
                } else {
                    setErrorMessage(responseJson.message);
                }
            }
            
        } catch (error) {

        }
    };

    return (
        <div>
            <p className='text-2xl font-semibold'>Вход в учетную запись</p>
            <p className='text-xl mt-1'>У вас ещё нет учетной записи? <button onClick={() => onToggleMethod('Registration')}><p className='text-[#6398E7]'>Зарегистрироваться</p></button></p>
            <div className='mt-6'>
                <p className='ml-6 text-lg'>Логин</p>
                <div className='bg-[#FAFAFA] w-full rounded-lg p-3 px-6'>
                    <input value={login} onChange={e => onChangeLogin(e.target.value)} className="outline-none bg-transparent text-xl w-full" placeholder='Введите email'/>
                </div>
            </div>
            <div className='mt-6'>
                <p className='ml-6 text-lg'>Пароль</p>
                <div className='bg-[#FAFAFA] w-full rounded-lg p-3 px-6 flex flex-row items-center justify-between'>
                    <input value={password} onChange={e => onChangePassword(e.target.value)} type={ isShowPassword ? '' : 'password' } className="outline-none bg-transparent text-xl w-full" placeholder='Введите пароль'/>
                    <button onClick={() => setIsShowPassword(!isShowPassword)}>
                        { isShowPassword ? (
                            <FaRegEye className='text-black/25 hover:opacity-50' size={24}/>
                        ) : (
                            <FaRegEyeSlash className='text-black/25 hover:opacity-50' size={24}/>
                        ) }
                    </button>
                </div>
            </div>
            <div className='flex flex-row items-center space-x-4 mt-4'>
                <button onClick={() => setIsCheckBox(!isCheckBox)} className={ isCheckBox ? 'flex-shrink-0 h-5 w-5 rounded-sm bg-[#4D89E3] hover:opacity-50 justify-center items-center flex' : 'flex-shrink-0 h-5 w-5 border-2 rounded-sm border-black/25 hover:opacity-50 justify-center items-center flex' }>
                    { isCheckBox && <IoMdCheckmark className='text-xl text-white'/> }
                </button>
                <p className='text-md'>Я соглашаюсь с передачей и обработкой моих данных</p>
            </div>
            { errorMessage && <p className='text-xl text-center text-red-500 mt-4'>{errorMessage}</p> }
            <button onClick={auth} className='w-full p-2 mt-6 justify-center items-center flex bg-[#4D89E3] rounded-lg group hover:opacity-50'>
                <p className='text-white text-xl group-hover:opacity-50'>Войти</p>
            </button>
            <button onClick={() => onToggleMethod('ChangePassword')}>
                <p className="text-xl mt-4 text-[#4D89E3] hover:opacity-50">Забыли пароль?</p>
            </button>
        </div>
    )
};

export default Auth