import { useState, useEffect } from "react";
import { IoIosClose, IoIosArrowDown } from "react-icons/io";
import AdminUserProfile from "./AdminUserProfile";

function AdminUsersList() {
    const [data, setData] = useState([]);
    const [isOpenProfile, setIsOpenProfile] = useState(false);
    const [selectedUser, setSelectedUser] = useState([]);

    useEffect(() => {
        const interval = setInterval(fetchData, 1000);
    
        // Возвращаем функцию очистки эффекта
        return () => clearInterval(interval);
    }, [data]);
    

    const fetchData = async () => {
        fetch(`https://avto-pro.kz/api-allUsers`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка сети');
                }
                return response.json();
            })
                .then(data => {
                setData(data);
            })
                .catch(error => {
            });
    }

    const toggleOpenProfile = async (user) => {
        setSelectedUser(user);
        setIsOpenProfile(true)
    }

    const formatBirthday = (date) => {
        const d = new Date(date);
        const day = d.getDate().toString().padStart(2, '0');
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const year = d.getFullYear();
        return `${day}.${month}.${year}`;
    };   

    return (
        <div className="w-full flex flex-row justify-between items-center flex-wrap px-16 overflow-y-hidden mt-12 mb-6">
            { data && (
                <>
                    {data.map((item, index) => (
                        <div key={index} className="p-6 border-[1px] bg-white rounded-xl flex flex-row space-x-16 mt-6">
                            <img src={`https://avto-pro.kz/api-userImage/${item.photo}`} alt="Photo Profile" className="h-80 w-80 object-cover rounded-xl"/>
                            <div className="flex flex-col">
                                <div className="flex flex-row justify-between w-[430px]">
                                    <div>
                                        <p className="text-xl text-black/25">Имя</p>
                                        <p className="text-xl text-black/25">Фамилия</p>
                                        <p className="text-xl text-black/25">Номер</p>
                                        <p className="text-xl text-black/25">Email</p>
                                        <p className="text-xl text-black/25">Дата рождения</p>
                                    </div>
                                    <div>
                                        <p className="text-xl text-black text-right">{item.fullname}</p>
                                        <p className="text-xl text-black text-right">{item.lastname}</p>
                                        <p className="text-xl text-black text-right">{item.phone}</p>
                                        <p className="text-xl text-black text-right">
                                            {item.email.length > 20 ? item.email.substring(0, 20) + '...' : item.email}
                                        </p>
                                        <p className="text-xl text-black text-right">{formatBirthday(item.birthday)}</p>
                                    </div>
                                </div>
                                <button className="border-2 p-2 border-red-500 w-full rounded-xl group hover:bg-red-500 mt-10">
                                    <p className="text-xl text-red-500 group-hover:text-white">Заблокировать</p>
                                </button>
                                <button onClick={() => toggleOpenProfile(item)} className="border-2 p-2 border-[#6398E7] w-full rounded-xl group hover:bg-[#6398E7] mt-4">
                                    <p className="text-xl text-[#6398E7] group-hover:text-white">Посмотреть профиль</p>
                                </button>
                            </div>
                        </div>
                    ))}
                </>
            ) }
            { isOpenProfile && <AdminUserProfile user={selectedUser} onClose={() => setIsOpenProfile(false)}/> }
        </div>
    )
};

export default AdminUsersList;