import React, { Component, useState } from 'react';
import car from '../../img/icons/car.svg'
import truck from '../../img/icons/truck.svg'
import ship from '../../img/icons/ship.svg'
import settings from '../../img/icons/settings.svg'
import services from '../../img/icons/services.svg'
import { Link } from 'react-router-dom';
import queryString from "query-string";
import { useLocation, useParams } from 'react-router-dom';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";


function Categories() {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(true);
    const queryParams = queryString.parse(location.search);
    const { item, category } = useParams();

    console.log(item, category, queryParams);

    
    
    return (
        <div>
            <div className='w-screen py-4 justify-center items-center flex flex-row mobile:space-x-2 sm:space-x-4 lg:space-x-6'>
                <Link to={`/cars/ads?city=${queryParams.city}&page=1`} className='items-center flex flex-col'>
                    <div className='xl:w-24 xl:h-24 mobile:h-[60px] mobile:w-[60px] sm:w-[90px] sm:h-[90px] rounded-full justify-center items-center flex bg-[#D3E2F8] hover:bg-[#90B5EE]'>
                        <img className='xl:w-18 xl:h-18 mobile:w-[50px] mobile:h-[50px] sm:w-[70px] sm:h-[70px]' src={car}/>
                    </div>
                    <p className='sm:text-xl mobile:hidden sm:block'>Легковые</p>
                </Link>
                <Link to={`/trucks/ads?city=${queryParams.city}&page=1`} className='items-center flex flex-col'>
                    <div className='xl:w-24 xl:h-24 mobile:h-[60px] mobile:w-[60px] sm:w-[90px] sm:h-[90px] rounded-full justify-center items-center flex bg-[#D3E2F8] hover:bg-[#90B5EE]'>
                        <img className='xl:w-18 xl:h-18 mobile:w-[50px] mobile:h-[50px] sm:w-[60px] sm:h-[60px]' src={truck}/>
                    </div>
                    <p className='sm:text-xl mobile:hidden sm:block'>Грузовые</p>
                </Link>
                <Link to={`/water/ads?city=${queryParams.city}&page=1`} className='items-center flex flex-col'>
                    <div className='xl:w-24 xl:h-24 mobile:h-[60px] mobile:w-[60px] sm:w-[90px] sm:h-[90px] rounded-full justify-center items-center flex bg-[#D3E2F8] hover:bg-[#90B5EE]'>
                        <img className='xl:w-18 xl:h-18 mobile:w-[50px] mobile:h-[50px] sm:w-[60px] sm:h-[60px]' src={ship}/>
                    </div>
                    <p className='sm:text-xl mobile:hidden sm:block'>Водный</p>
                </Link>
                <Link to={`/tools/ads?city=${queryParams.city}&page=1`} className='items-center flex flex-col'>
                    <div className='xl:w-24 xl:h-24 mobile:h-[60px] mobile:w-[60px] sm:w-[90px] sm:h-[90px] rounded-full justify-center items-center flex bg-[#D3E2F8] hover:bg-[#90B5EE]'>
                        <img className='xl:w-18 xl:h-18 mobile:w-[40px] mobile:h-[40px] sm:w-[50px] sm:h-[50px]' src={settings}/>
                    </div>
                    <p className='sm:text-xl mobile:hidden sm:block'>Оборудование</p>
                </Link>
                <Link to={`/services/ads?city=${queryParams.city}&page=1`} className='items-center flex flex-col'>
                    <div className='xl:w-24 xl:h-24 mobile:h-[60px] mobile:w-[60px] sm:w-[90px] sm:h-[90px] rounded-full justify-center items-center flex bg-[#D3E2F8] hover:bg-[#90B5EE]'>
                        <img className='xl:w-18 xl:h-18 mobile:w-[40px] mobile:h-[40px] sm:w-[50px] sm:h-[50px]' src={services}/>
                    </div>
                    <p className='sm:text-xl mobile:hidden sm:block'>Услуги</p>
                </Link>
            </div>
            <div className='w-screen justify-center items-center flex flex-col mt-4 space-y-1'>
                <div className='flex flex-row space-x-2'>
                    <p className='mobile:text-xl lg:text-2xl font-medium'>Выбрать город</p>
                    <button onClick={() => setIsOpen(!isOpen)} className='hover:opacity-50'>
                        { isOpen ? (
                            <MdKeyboardArrowUp className='text-2xl'/>
                        ) : (
                            <MdKeyboardArrowDown className='text-2xl'/>
                        ) }
                    </button>
                </div>
                { isOpen && (
                    <div className='mobile:w-10/12 lg:w-5/12 p-2 flex flex-row flex-wrap justify-center'>
                        <Link to={`?${queryString.stringify({...queryParams, city: 'Все'})}`} className='m-2'>
                            <p className={  queryParams.city === 'Все' ? 'mobile:text-lg lg:text-xl text-[#90B5EE]' : 'mobile:text-lg lg:text-xl hover:text-[#90B5EE] text-black/50' }>Все</p>
                        </Link>
                        <Link to={`?${queryString.stringify({...queryParams, city: 'Алматы'})}`} className='m-2'>
                            <p className={  queryParams.city === 'Алматы' ? 'mobile:text-lg lg:text-xl text-[#90B5EE]' : 'mobile:text-lg lg:text-xl hover:text-[#90B5EE] text-black/50' }>Алматы</p>
                        </Link>
                        <Link to={`?${queryString.stringify({...queryParams, city: 'Астана'})}`} className='m-2'>
                            <p className={  queryParams.city === 'Астана' ? 'mobile:text-lg lg:text-xl text-[#90B5EE]' : 'mobile:text-lg lg:text-xl hover:text-[#90B5EE] text-black/50' }>Астана</p>
                        </Link>
                        <Link to={`?${queryString.stringify({...queryParams, city: 'Шымкент'})}`} className='m-2'>
                            <p className={  queryParams.city === 'Шымкент' ? 'mobile:text-lg lg:text-xl text-[#90B5EE]' : 'mobile:text-lg lg:text-xl hover:text-[#90B5EE] text-black/50' }>Шымкент</p>
                        </Link>
                        <Link to={`?${queryString.stringify({...queryParams, city: 'Караганда'})}`} className='m-2'>
                            <p className={  queryParams.city === 'Караганда' ? 'mobile:text-lg lg:text-xl text-[#90B5EE]' : 'mobile:text-lg lg:text-xl hover:text-[#90B5EE] text-black/50' }>Караганда</p>
                        </Link>
                        <Link to={`?${queryString.stringify({...queryParams, city: 'Актобе'})}`} className='m-2'>
                            <p className={  queryParams.city === 'Актобе' ? 'mobile:text-lg lg:text-xl text-[#90B5EE]' : 'mobile:text-lg lg:text-xl hover:text-[#90B5EE] text-black/50' }>Актобе</p>
                        </Link>
                        <Link to={`?${queryString.stringify({...queryParams, city: 'Тараз'})}`} className='m-2'>
                            <p className={  queryParams.city === 'Тараз' ? 'mobile:text-lg lg:text-xl text-[#90B5EE]' : 'mobile:text-lg lg:text-xl hover:text-[#90B5EE] text-black/50' }>Тараз</p>
                        </Link>
                        <Link to={`?${queryString.stringify({...queryParams, city: 'Павлодар'})}`} className='m-2'>
                            <p className={  queryParams.city === 'Павлодар' ? 'mobile:text-lg lg:text-xl text-[#90B5EE]' : 'mobile:text-lg lg:text-xl hover:text-[#90B5EE] text-black/50' }>Павлодар</p>
                        </Link>
                        <Link to={`?${queryString.stringify({...queryParams, city: 'Усть-Каменогорск'})}`} className='m-2'>
                            <p className={  queryParams.city === 'Усть-Каменогорск' ? 'mobile:text-lg lg:text-xl text-[#90B5EE]' : 'mobile:text-lg lg:text-xl hover:text-[#90B5EE] text-black/50' }>Усть-Каменогорск</p>
                        </Link>
                        <Link to={`?${queryString.stringify({...queryParams, city: 'Семей'})}`} className='m-2'>
                            <p className={  queryParams.city === 'Семей' ? 'mobile:text-lg lg:text-xl text-[#90B5EE]' : 'mobile:text-lg lg:text-xl hover:text-[#90B5EE] text-black/50' }>Семей</p>
                        </Link>
                    </div>
                ) }
            </div>
        </div>
    )
};

export default Categories;