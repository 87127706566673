import React, { useEffect, useState } from 'react';
import Card from './Card';
import { useParams, useLocation } from 'react-router-dom';
import Pagination from './Pagination';
import queryString from "query-string";

function Cards() {
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const [data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState(1);
    const { item, category } = useParams();

    useEffect(() => {
        const interval = setInterval(fetchData, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [item, queryParams.page, queryParams.city, category]);

    const fetchData = async () => {
        fetch(`https://avto-pro.kz/api-${item}/${category}?page=${queryParams.page}&city=${queryParams.city}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка сети');
                }
                return response.json();
            })
            .then(data => {
                setData(data.results);
                setTotalCount(data.totalCount);
            })
            .catch(error => {
                console.error('Ошибка:', error);
            });
    }
    


    return (
        <div>
            <div className='w-screeen justify-center flex flex-wrap'>
                {data.length > 0 ? (
                    data.map((car, index) => (
                        <Card selectCategories={item} key={index} car={car} page={queryParams.page} category={category}/>
                    ))
                ) : (
                    <div className='w-screen h-96 justify-center items-center flex'>
                        <p className='text-[#4D89E3] text-xl'>Объявлений данного типа не найдено</p>
                    </div>
                )}
            </div>
            {totalCount > 10 && <Pagination totalCount={totalCount}/>}
        </div>
    )
};

export default Cards;