import { IoCloseOutline } from "react-icons/io5";
import { IoIosCheckmark } from "react-icons/io";

function ChoosePayments({ onClose, setBalance, setOnline, openTariff }) {
    return (
        <div className="w-screen h-screen fixed z-[100000] flex justify-center items-center top-0 left-0 right-0 bottom-0">
            <div className="rounded-2xl bg-white mobile:w-64 lg:w-96 p-4 absolute z-[100000] shadow-lg space-y-2">
                <div className="flex flex-row items-center justify-center space-x-2">
                    <button onClick={onClose} className="hover:opacity-50">
                        <IoCloseOutline className="text-3xl"/>
                    </button>
                    <p className="text:md lg:text-xl font-semibold text-[#0D0D0D]">Выберите способ оплаты</p>
                </div>
                <div className="flex flex-col space-y-2">
                    <button onClick={() => (setBalance(), onClose(), openTariff())} className="hover:opacity-50">
                        <p className="mobile:text-md lg:text-xl text-[#4D89E3]">Со своего баланса</p>
                    </button>
                    <button onClick={() => (setOnline(), onClose(), openTariff())} className="hover:opacity-50">
                        <p className="mobile:text-md lg:text-xl text-[#4D89E3]">Онлайн платёж</p>
                    </button>
                </div>
            </div>
        </div>
    )
};

export default ChoosePayments;