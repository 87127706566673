import React, { Component, useState } from 'react';
import VerificationInput from "react-verification-input";
import '../../App.css'

function PasswordConfrim({ onToggleMethod, recoveryMethod, recoveryValue }) {
    const [newPassword, onChangeNewPassword] = useState();
    const [confrimPassword, onChangeConfrimPassword] = useState();
    const [isUncorrectPasswordError, setUncorrectPasswordError] = useState(false);
    const [isSuccessChanged, setSuccessChanged] = useState(false);

    const sendPassword = async () => {
        if (confrimPassword === newPassword) {
            setUncorrectPasswordError(false);
            try {
                const response = await fetch('https://avto-pro.kz/api-changePassword', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ email: recoveryValue, newPassword: newPassword  })
                });
    
                const data = await response.json();
                if (data.success) {
                    setSuccessChanged(true)
                } else {
                    setSuccessChanged(false);
                }
                
            } catch (error) {
                console.error('Error changed passwod:', error);
            }
        } else {
            setUncorrectPasswordError(true);
        }
    }

    return (
        <div>
            <p className='text-2xl font-semibold'>Восстановить пароль</p>
            <p className='text-xl mt-1'>Измените пароль на подходящий для вас</p>
            <div className='w-full flex flex-col justify-center space-y-4 mt-0'>
                <div className='mt-4'>
                    <p className='ml-6 text-lg'>Новый пароль</p>
                    <div className='bg-[#FAFAFA] w-full rounded-lg p-3 px-6'>
                        <input type='password' onChange={(event) => onChangeNewPassword(event.target.value)} className="outline-none bg-transparent text-xl w-full" placeholder='Введите пароль'/>
                    </div>
                </div>
                <div className='mt-4'>
                    <p className='ml-6 text-lg'>Подтвердите пароль</p>
                    <div className='bg-[#FAFAFA] w-full rounded-lg p-3 px-6'>
                        <input type='password' onChange={(event) => onChangeConfrimPassword(event.target.value)} className="outline-none bg-transparent text-xl w-full" placeholder='Подтвердите пароль'/>
                    </div>
                </div>
            </div>
            <button onClick={() => sendPassword()} disabled={!newPassword || !confrimPassword} className={ !newPassword || !confrimPassword ? 'w-full p-2 mt-6 justify-center items-center flex bg-[#4D89E3] rounded-lg group opacity-50' : 'w-full p-2 mt-6 justify-center items-center flex bg-[#4D89E3] rounded-lg group hover:opacity-50' }>
                <p className='text-white text-xl'>Изменить пароль</p>
            </button>
            { isUncorrectPasswordError && <p className='text-xl text-red-400 text-center mt-6'>Пароли не совпадают. Повторите попытку</p> }
            { isSuccessChanged && <p className='text-xl text-center mt-6'>Пароль успешно изменён. Вы можете вернуться к <span onClick={() => onToggleMethod('Authorization')} className='text-[#4D89E3] cursor-pointer hover:opacity-50'>авторизации</span></p> }
        </div>
    )
};

export default PasswordConfrim;