import React, { Component } from 'react';
import copyright from '../../img/copyright.png'
import { Link } from 'react-router-dom';

class Footer extends Component {
    render() {
        return (
            <div className="w-full p-6 flex mobile:flex-col lg:flex-row mobile:space-y-6 lg:space-y-0 mobile:mb-8 lg:mb-0 sm:flex sm:px-12 xl:px-44 lg:justify-between bg-white drop-shadow-2xl items-center mt-12">
                <img className='lg:flex' src={copyright}/>
                <div className='space-y-6 flex flex-col items-center justify-center'>
                    <div className='space-x-6 flex-row flex'>
                        <div className='space-y-2'>
                            <Link to='/privacy-policy'>
                                <p className='text-center text-xl text-black/25 hover:text-[#4D89E3]'>Политика конфидициальности</p>
                            </Link>
                            <Link to='/public-offer'>
                                <p className='text-center text-xl text-black/25 hover:text-[#4D89E3]'>Публичная оферта</p>
                            </Link>
                            <Link to='/payment'>
                                <p className='text-center text-xl text-black/25 hover:text-[#4D89E3]'>Оплата</p>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='space-y-2 lg:items-end lg:justify-end mobile:justify-center mobile:items-center flex flex-col '>
                    <p className='text-xl text-[#4D89E3]'>arsham.mahpirov@mail.ru</p>
                    <p className='text-xl text-[#4D89E3]'>+77006216868</p>
                </div>
            </div>
        )
    }
};

export default Footer