import { IoCloseOutline } from "react-icons/io5";
import { IoIosArrowBack, IoIosArrowForward  } from "react-icons/io";
import { useState, useEffect } from "react";


function FullImagePage({ onClose, images }) {
    const [previewImage, setPreviewImage] = useState();

    useEffect(() => {
        setPreviewImage(images[0])
    }, []);

    return (
        <div className="w-full h-full fixed z-[100000] left-0 right-0 top-0 bottom-0 flex justify-center items-center">
            <button onClick={() => onClose()}>
                <IoCloseOutline className="text-white text-4xl absolute z-[1000000001] right-6 top-6 hover:text-[#4D89E3]"/>
            </button>
            <div className="bg-black/75 w-full h-full absolute z-[1000000]"/>
            <div className="mobile:w-11/12 lg:w-9/12 desktop:w-8/12 flex flex-row absolute z-[100000000001] bottom-12 justify-center flex-wrap">
                <button onClick={() => setPreviewImage(images[0])} className="hover:opacity-50 m-2">
                    <img src={`https://avto-pro.kz/api-carImage/${images[0]}`}  className={ previewImage === images[0] ? "mobile:w-12 lg:w-20 mobile:h-12 lg:h-20 rounded-xl object-center border-2 border-[#4D89E3]" : "mobile:w-12 lg:w-20 mobile:h-12 lg:h-20 rounded-xl object-center" }/>
                </button>
                <button onClick={() => setPreviewImage(images[1])} className="hover:opacity-50 m-2">
                    <img src={`https://avto-pro.kz/api-carImage/${images[1]}`}  className={ previewImage === images[1] ? "mobile:w-12 lg:w-20 mobile:h-12 lg:h-20 rounded-xl object-center border-2 border-[#4D89E3]" : "mobile:w-12 lg:w-20 mobile:h-12 lg:h-20 rounded-xl object-center" }/>
                </button>
                <button onClick={() => setPreviewImage(images[2])} className="hover:opacity-50 m-2">
                    <img src={`https://avto-pro.kz/api-carImage/${images[2]}`}  className={ previewImage === images[2] ? "mobile:w-12 lg:w-20 mobile:h-12 lg:h-20 rounded-xl object-center border-2 border-[#4D89E3]" : "mobile:w-12 lg:w-20 mobile:h-12 lg:h-20 rounded-xl object-center" }/>
                </button>
                <button onClick={() => setPreviewImage(images[3])} className="hover:opacity-50 m-2">
                    <img src={`https://avto-pro.kz/api-carImage/${images[3]}`}  className={ previewImage === images[3] ? "mobile:w-12 lg:w-20 mobile:h-12 lg:h-20 rounded-xl object-center border-2 border-[#4D89E3]" : "mobile:w-12 lg:w-20 mobile:h-12 lg:h-20 rounded-xl object-center" }/>
                </button>
                <button onClick={() => setPreviewImage(images[4])} className="hover:opacity-50 m-2">
                    <img src={`https://avto-pro.kz/api-carImage/${images[4]}`}  className={ previewImage === images[4] ? "mobile:w-12 lg:w-20 mobile:h-12 lg:h-20 rounded-xl object-center border-2 border-[#4D89E3]" : "mobile:w-12 lg:w-20 mobile:h-12 lg:h-20 rounded-xl object-center" }/>
                </button>
            </div>
            <img src={`https://avto-pro.kz/api-carImage/${previewImage}`} className="object-fit mobile:h-2/6 tablet:h-3/6 lg:h-4/6 desktop:h-5/6 desktop2:h-full absolute z-[100000000] "/>
        </div>
    )
};

export default FullImagePage;