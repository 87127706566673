import React, { Component, useEffect, useState } from 'react';
import { IoArrowBack } from "react-icons/io5";
import { Link } from 'react-router-dom';

function MyHistory({ onClose }) {
    const [data, setData] = useState([]);
    const [user, setUser] = useState({});

    useEffect(() => {
        fetchUserInfo();
        const interval = setInterval(fetchData, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const fetchUserInfo = async () => {
        // Получаем значение токена из cookie
        const authToken = document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*=\s*([^;]*).*$)|^.*$/, "$1");
    
        // Отправляем запрос на сервер для получения информации о пользователе
        fetch(`https://avto-pro.kz/api-getUserInfo?token=${authToken}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                setUser(data.user[0]);
            } else {
            }
        })
        .catch(error => {
        });
    }

    const fetchData = async () => {
        fetch(`https://avto-pro.kz/api-getHistory/${user.id}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка сети');
                }
                return response.json();
            })
                .then(data => {
                setData(data);
            })
                .catch(error => {
            });
    }

    return (
        <div className='py-6 px-8 w-10/12'>
            <button onClick={onClose} className='w-12 h-12 rounded-full bg-[#4D89E3]/50 absolute z-50 items-center justify-center flex -ml-14 mt-[12%] group hover:opacity-50'>
                <IoArrowBack className='text-xl text-white'/>
            </button>
            <p className='text-2xl text-nowrap font-medium'>История покупок</p>
            <div className='space-y-3 overflow-x-hidden h-[500px]'> 
                {data.cars && data.cars.length !== 0 ? (
                    data.cars.map((car, index) => (
                        <div key={index} className='w-full shadow-xl bg-white drop-shadow-xl p-3 rounded-xl border-2 mt-4'>
                            <p className='text-xl font-semibold'>{car.date}</p>
                            <div className='mt-2'>
                                <div className='flex flex-row'>
                                    <div className='space-x-2 flex flex-row'>
                                        <img src={`https://avto-pro.kz/api-carImage/${car.photoPreview1}`}  className='bg-[#BDBDBD] w-48 rounded-xl object-center'/>
                                        <div className='space-y-2'>
                                            <img src={`https://avto-pro.kz/api-carImage/${car.photoPreview2}`} className='bg-[#BDBDBD] h-12 w-16 rounded-xl object-center'/>
                                            <img src={`https://avto-pro.kz/api-carImage/${car.photoPreview3}`} className='bg-[#BDBDBD] h-12 w-16 rounded-xl object-center'/>
                                            <img src={`https://avto-pro.kz/api-carImage/${car.photoPreview4}`} className='bg-[#BDBDBD] h-12 w-16 rounded-xl object-center'/>
                                        </div>
                                    </div>
                                    <div className='ml-4'>
                                        <Link to={`/${car.url}`}>
                                            <p className='text-xl hover:opacity-50'>{car.title}</p>
                                        </Link>
                                        <p className='text-lg mt-1'>{car.price}тг/день</p>
                                        <p className='text-md mt-1'>Имя: {car.contactName}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className='w-full h-full justify-center items-center flex'>
                        <p>Нет объявлений в данном списке</p>
                    </div>
                )}
            </div>
        </div>
    )
}

export default MyHistory;