import { useEffect, useState } from "react";
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp, MdDeleteOutline } from "react-icons/md";

function AdminTariffs() {
    const [data, setData] = useState([]);
    const [selectedSale, setSelectedSale] = useState(0);
    const [isAddTarif, setIsAddTarif] = useState(false);
    const [state, setState] = useState({
        title: "",
        price: 0,
        monthValue: 0,
    });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch('https://avto-pro.kz/api-getAllTariffs');
            const responseJson = await response.json();
            setData(responseJson.data);
        } catch (error) {
            console.error('Ошибка получения данных о тарифах:', error);
        }
    };

    const handleInputChange = (id, field, value) => {
        // Обновляем состояние только локально
        setData(prevData => {
            return prevData.map(item => {
                if (item.id === id) {
                    return { ...item, [field]: value };
                }
                return item;
            });
        });
    };

    const handleBlur = async (id, field, value) => {
        try {
            // Отправляем данные на сервер
            const response = await fetch(`https://avto-pro.kz/api-updateTariff/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    field: field,
                    value: value
                })
            });

            if (!response.ok) {
                throw new Error('Failed to update data on the server');
            }

            // Обновляем данные после успешного обновления на сервере
            setData(prevData => {
                return prevData.map(item => {
                    if (item.id === id) {
                        return { ...item, [field]: value };
                    }
                    return item;
                });
            });
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    const changeSale = async (id, status) => {
        try {
            const response = await fetch(`https://avto-pro.kz/api-updateSale/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ status: status })
            });
    
            if (!response.ok) {
                throw new Error('Failed to update isSale status');
            }
    
            console.log(`Status for tariff with id ${id} has been updated successfully.`);
            setSelectedSale(0);
        } catch (error) {
            console.error('Error updating isSale status:', error);
        }
    };
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const addTariff = async () => {
        try {
            const response = await fetch(`https://avto-pro.kz/api-addTariff`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ title: state.title, price: state.price, monthValue: state.monthValue })
            });

            const responseJson = await response.json();

            if (responseJson.success) {
                setIsAddTarif(false);
            }

        } catch {
            
        }
    }

    const remove = async (id) => {
        try {
            const response = await fetch(`https://avto-pro.kz/api-deleteTariff/${id}`, {
                method: 'DELETE'
            });
            if (!response.ok) {
                throw new Error('Ошибка удаления пользователей');
            }
    
            const data = await response.json();
            
        } catch (error) {
            console.error('Ошибка:', error.message);
        }
    }

    return (
        <div className="w-full h-screen flex flex-col space-y-4 p-6 items-center">
            <p className="text-2xl mt-12 font-semibold text-[#6398E7]">Тарифы</p>
            <div className="space-y-8">
                <div className="flex flex-row space-x-6">
                    <div className="flex flex-col space-y-1 items-start">
                        <p className="text-xl text-black/50 font-semibold">ID</p>
                        {data.map((tariff, index) => (
                            <p key={tariff.id} className="text-xl text-black/50 font-regular">{index + 1}</p>
                        ))}
                    </div>
                    <div className="flex flex-col space-y-1 items-start">
                        <p className="text-xl text-black/50 font-semibold">Название</p>
                        {data.map(tariff => (
                            <input
                                type="text"
                                className="text-xl text-black/50 font-regular w-36"
                                value={tariff.title}
                                // onChange={(e) => handleInputChange(tariff.id, 'title', e.target.value)}
                                onChange={(e) => handleInputChange(tariff.id, 'title', e.target.value)}
                                onBlur={(e) => handleBlur(tariff.id, 'title', e.target.value)}
                            />
                        ))}
                    </div>
                    <div className="flex flex-col space-y-1 items-start">
                        <p className="text-xl text-black/50 font-semibold">Кол-во месяцев</p>
                        {data.map(tariff => (
                            <input
                                type="text"
                                className="text-xl text-black/50 font-regular w-36"
                                value={tariff.monthValue}
                                // onChange={(e) => handleInputChange(tariff.id, 'monthValue', e.target.value)}
                                onChange={(e) => handleInputChange(tariff.id, 'monthValue', e.target.value)}
                                onBlur={(e) => handleBlur(tariff.id, 'monthValue', e.target.value)}
                            />
                        ))}
                    </div>
                    <div className="flex flex-col space-y-1 items-start">
                        <p className="text-xl text-black/50 font-semibold">Цена</p>
                        {data.map(tariff => (
                            <input
                                type="text"
                                className="text-xl text-black/50 font-regular w-36"
                                value={tariff.price}
                                // onChange={(e) => handleInputChange(tariff.id, 'price', e.target.value)}
                                onChange={(e) => handleInputChange(tariff.id, 'price', e.target.value)}
                                onBlur={(e) => handleBlur(tariff.id, 'price', e.target.value)}
                            />
                        ))}
                    </div>
                    <div className="flex flex-col space-y-1 items-start">
                        <p className="text-xl text-black/50 font-semibold">Есть скидка</p>
                        {data.map(tariff => (
                            <div>
                                <div className="flex flex-row space-x-2">
                                    <p key={tariff.id} className="text-xl text-black/50 font-regular">{tariff.isSale ? 'Да' : 'Нет'}</p>
                                    { selectedSale === tariff.id ? (
                                        <button onClick={() => setSelectedSale(0)} className="hover:opacity-50">
                                            <MdOutlineKeyboardArrowUp className="text-xl text-black/50"/>
                                        </button>
                                    ) : (
                                        <button onClick={() => setSelectedSale(tariff.id)} className="hover:opacity-50">
                                            <MdOutlineKeyboardArrowDown className="text-xl text-black/50"/>
                                        </button>
                                    ) }
                                </div>
                                { selectedSale === tariff.id && (
                                    <div className="absolute z-10 bg-white p-2 px-4 flex flex-col">
                                        <button onClick={() => changeSale(tariff.id, "Да")} className="hover:opacity-50">
                                            <p className="text-xl ">Да</p>
                                        </button>
                                        <button onClick={() => changeSale(tariff.id, "Нет")} className="hover:opacity-50">
                                            <p className="text-xl ">Нет</p>
                                        </button>
                                    </div>
                                ) }
                            </div>
                        ))}
                    </div>
                    <div className="flex flex-col space-y-1 items-start">
                        <p className="text-xl text-black/50 font-semibold">Сумма скидки %</p>
                        {data.map(tariff => (
                            <>
                                { tariff.isSale ? (
                                    <div className="flex flex-row items-center">
                                        <input
                                            type="text"
                                            className="text-xl text-black/50 font-regular w-12"
                                            value={tariff.saleValue}
                                            onChange={(e) => handleInputChange(tariff.id, 'saleValue', e.target.value)}
                                            onBlur={(e) => handleBlur(tariff.id, 'saleValue', e.target.value)}
                                        />
                                        <button onClick={() => remove(tariff.id)} className="hover:opacity-50">
                                            <MdDeleteOutline className="text-xl text-red-500"/>
                                        </button>
                                    </div>
                                ) : (
                                    <div className="flex flex-row items-center justify-between">
                                        <p key={tariff.id} className="text-xl text-black/50 font-regular">{tariff.saleValue || 'NULL'}</p>
                                        <button onClick={() => remove(tariff.id)} className="hover:opacity-50">
                                            <MdDeleteOutline className="text-xl text-red-500"/>
                                        </button>
                                    </div>
                                ) }
                            </>
                        ))}
                    </div>
                </div>
                { isAddTarif && (
                    <div className="flex flex-row space-x-6 w-full justify-center items-center">
                        <div className="border-2 border-[#6398E7] w-64 p-2 px-4 rounded-xl">
                            <input 
                                placeholder="Название тарифа" 
                                className="text-xl outline-none bg-transparent" 
                                name="title" 
                                onChange={handleChange} 
                            />
                        </div>
                        <div className="border-2 border-[#6398E7] w-64 p-2 px-4 rounded-xl">
                            <input 
                                placeholder="Цена тарифа" 
                                className="text-xl outline-none bg-transparent" 
                                name="price" 
                                onChange={handleChange} 
                            />
                        </div>
                        <div className="border-2 border-[#6398E7] w-64 p-2 px-4 rounded-xl">
                            <input 
                                placeholder="Кол-во месяцев" 
                                className="text-xl outline-none bg-transparent" 
                                name="monthValue" 
                                onChange={handleChange} 
                            />
                        </div>
                    </div>
                ) }
                { isAddTarif ? (
                    <div className="space-y-4">
                        <button disabled={!state.title || !state.price || !state.monthValue} onClick={addTariff} className={ (!state.title || !state.price || !state.monthValue) ? "bg-[#6398E7] p-3 w-full justify-center items-center rounded-xl flex opacity-50" : "bg-[#6398E7] p-3 w-full justify-center items-center rounded-xl flex hover:opacity-50"}>
                            <p className="text-white text-2xl font-semibold">Подтвердить</p>
                        </button>
                        <button onClick={() => setIsAddTarif(false)} className="border-[#6398E7] border-2 p-2 w-full justify-center items-center rounded-xl flex hover:opacity-50">
                            <p className="text-[#6398E7] text-2xl font-semibold">Отменить</p>
                        </button>
                    </div>
                ) : (
                    <button onClick={() => setIsAddTarif(true)} className="bg-[#6398E7] p-2 w-full justify-center items-center rounded-xl flex hover:opacity-50">
                        <p className="text-white text-2xl font-semibold">Добавить тариф</p>
                    </button>
                ) }
            </div>
        </div>
    );
}

export default AdminTariffs;
