import React, { Component, useState } from 'react';
import { FaRegEye, FaRegEyeSlash  } from "react-icons/fa6";
import { IoIosArrowDown, IoMdCheckmark, IoIosArrowUp} from "react-icons/io";
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.module.css'

function Reg({ onToggleMethod, onClose }) {
    const [step, setStep] = useState(1);
    const [name, onChangeName] = useState();
    const [lastname, onChangeLastname] = useState();
    const [email, onChangeEmail] = useState();
    const [isCheckBox, setIsCheckBox] = useState(false);
    const [isError, setError] = useState();
    const [phone, onChangePhone] = useState();

    const [isOpenSex, setIsOpenSex] = useState(false); 
    const [sex, setSex] = useState();

    const [birthday, setBirthday] = useState(null);

    const [isOpenCity, setIsOpenCity] = useState(false);
    const [city, setCity] = useState();

    const [password, onChangePassword] = useState();
    const [confirmPassword, onChangeConfirmPassword] = useState();

    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
    const [isSecondCheckBox, setIsSecondCheckBox] = useState(false);

    const [isPassordError, setIsPasswordError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    const nextStep = () => {
        if (!name || !lastname || !email || !isCheckBox) {
            setError(true);
        } else {
            setError(false);
            setStep(2);
        }
    }

    const handleDateChange = (date) => {
        setBirthday(date);
    };

    const setCookie = (name, value, days) => {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        const expires = "expires=" + date.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
    };

    const reg = async () => {
        if (!phone || !sex || !birthday || !city || !password || !confirmPassword || !isSecondCheckBox) {
            setError(true);
        } else {
            setError(false);
            if ( password !== confirmPassword ) {
                setIsPasswordError(true);
            } else {
                setIsPasswordError(false);
                const data = {
                    fullname: name,
                    lastname: lastname,
                    email: email,
                    sex: sex,
                    birthday: birthday,
                    city: city,
                    password: password,
                    phone: phone
                }
                
                try {   
                    const response = await fetch('https://avto-pro.kz/api-register', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json' // Установка заголовка Content-Type на application/json
                        },
                        body: JSON.stringify(data) // Преобразование объекта в JSON-строку
                    });
                    if (response.ok) {
                        const responseJson = await response.json();
                        if (responseJson.success) {
                            setErrorMessage();
                            setCookie('auth_token', responseJson.token, 7);
                            onClose();
                            window.location.reload();
                        } else {
                            setErrorMessage(responseJson.message);
                        }
                    }
                    
                } catch (error) {
                }
            }
        }
    }

    return (
        <div>
            <p className='text-2xl font-semibold'>Регистрация</p>
            <p className='text-xl mt-1'>У вас есть учетная запись? <button onClick={() => onToggleMethod('Authorization')}><p className='text-[#6398E7]'>Войдите в систему</p></button></p>
            { step === 1 ? (
                <>
                    <div className='flex mobile:flex-col lg:flex-row justify-between items-center mt-10'>
                        <div className=' w-full'>
                            <p className='ml-6 text-lg'>Имя</p>
                            <div className='bg-[#FAFAFA] mobile:w-full lg:w-60 rounded-lg p-3 px-6'>
                                <input value={name} onChange={e => onChangeName((e.target.value))} className="outline-none bg-transparent text-xl w-full" placeholder='Введите имя'/>
                            </div>
                        </div>
                        <div className='mobile:mt-6 lg:mt-0 w-full'>
                            <p className='ml-6 text-lg'>Фамилия</p>
                                <div className='bg-[#FAFAFA] mobile:w-full lg:w-auto rounded-lg p-3 px-6'>
                                    <input value={lastname} onChange={e => onChangeLastname(e.target.value)} className="outline-none bg-transparent text-xl w-full" placeholder='Введите фамилию'/>
                                </div>
                        </div>
                    </div>
                    <div className='mt-6'>
                        <p className='ml-6 text-lg'>Email</p>
                        <div className='bg-[#FAFAFA] w-full rounded-lg p-3 px-6'>
                            <input value={email} onChange={e => onChangeEmail((e.target.value))} className="outline-none bg-transparent text-xl w-full" placeholder='Введите email'/>
                        </div>
                    </div>
                    <div className='flex flex-row items-center space-x-4 mt-4'>
                        <button onClick={() => setIsCheckBox(!isCheckBox)} className={ isCheckBox ? 'flex-shrink-0 h-5 w-5 rounded-sm bg-[#4D89E3] hover:opacity-50 justify-center items-center flex' : 'flex-shrink-0 h-5 w-5 border-2 rounded-sm border-black/25 hover:opacity-50 justify-center items-center flex' }>
                            { isCheckBox && <IoMdCheckmark className='text-xl text-white'/> }
                        </button>
                        <p className='text-md'>Я соглашаюсь с передачей и обработкой моих данных</p>
                    </div>
                    <div className='mt-4'>
                        { isError && <p className='mobile:text-lg lg:text-xl text-center text-red-500'>Все поля обязательны к заполнению</p> }
                        <button onClick={nextStep} className='w-full p-2 mt-6 justify-center items-center flex bg-[#4D89E3] rounded-lg group hover:opacity-50'>
                            <p className='text-white text-xl group-hover:opacity-50'>Далее</p>
                        </button>
                    </div>
                    
                </>
            ) : (
                <div className='mobile:h-screen lg:h-[400px] overflow-y-auto'>
                    <div className='mt-6'>
                        <p className='ml-6 text-lg'>Телефон</p>
                        <div className='bg-[#FAFAFA] w-full rounded-lg p-3 px-6'>
                            <input value={phone} onChange={e => onChangePhone(e.target.value)} className="outline-none bg-transparent text-xl w-full" placeholder='Введите телефон'/>
                        </div>
                    </div>
                    <div className='mt-6 flex mobile:flex-col mobile:w-full mobile:space-y-6 lg:space-y-0 lg:flex-row justify-around'>
                        <div className='mobile:w-full lg:w-40'>
                            <p className='ml-6 text-lg'>Пол</p>
                            <div onClick={() => setIsOpenSex(!isOpenSex)} className='bg-[#FAFAFA] lg:w-40 space-x-2 rounded-lg p-3 px-6 flex flex-row items-center justify-between'>
                                <p className="text-black/35 text-xl w-full">{ sex ? sex : 'Пол' }</p>
                                <button onClick={() => setIsOpenSex(!isOpenSex)}>
                                    { isOpenSex ? <IoIosArrowUp size={32} className='text-black/35 hover:opacity-50'/> : <IoIosArrowDown size={32} className='text-black/35 hover:opacity-50'/> }
                                </button>
                            </div>
                            { isOpenSex && (
                                <div className='bg-[#FAFAFA] lg:w-40 mobile:w-full flex-col flex items-start rounded-b-lg p-3 px-6'>
                                    <button onClick={() => (setSex('Мужской'), setIsOpenSex(false))}>
                                        <p className='text-xl text-black/25 hover:opacity-50'>Мужской</p>
                                    </button>
                                    <button onClick={() => (setSex('Женский'), setIsOpenSex(false))}>
                                        <p className='text-xl text-black/25 hover:opacity-50'>Женский</p>
                                    </button>
                                    <button onClick={() => (setSex('Не указано'), setIsOpenSex(false))}>
                                        <p className='text-xl text-black/25 hover:opacity-50'>Не указано</p>
                                    </button>
                                </div>
                            ) }
                        </div>
                        <div className='mobile:w-full lg:w-40'>
                        <p className='ml-6 text-lg'>Дата рождения</p>
                            <div className='bg-[#FAFAFA] lg:w-40 mobile:w-full rounded-lg p-3 px-6 flex flex-row items-center justify-between'>
                                <ReactDatePicker
                                    selected={birthday}
                                    onChange={handleDateChange}
                                    dateFormat="dd.MM.yyyy" // Установка формата даты
                                    placeholderText="дд.мм.гггг"
                                    className='text-black/35 text-xl bg-[#FAFAFA] w-full outline-none justify-center items-center flex'
                                    type="text"
                                />
                            </div>
                        </div>
                        <div className='mobile:w-full lg:w-40'>
                            <p className='ml-6 text-lg'>Город</p>
                            <div onClick={() => setIsOpenCity(!isOpenCity)} className='bg-[#FAFAFA] lg:w-40 space-x-2 mobile:w-full rounded-lg p-3 px-6 flex flex-row items-center justify-between'>
                                <p className="text-black/35 text-xl w-full">{ city ? city : 'Город' }</p>
                                <button onClick={() => setIsOpenCity(!isOpenCity)}>
                                    { isOpenCity ? <IoIosArrowUp size={32} className='text-black/35 hover:opacity-50'/> : <IoIosArrowDown size={32} className='text-black/35 hover:opacity-50'/> }
                                </button>
                            </div>
                            { isOpenCity && (
                                <div className='bg-[#FAFAFA] lg:w-40 mobile:w-full flex-col flex items-start rounded-b-lg p-3 px-6'>
                                    <button onClick={() => (setCity('Алматы'), setIsOpenCity(false))}>
                                        <p className='text-xl text-black/25 hover:opacity-50'>Алматы</p>
                                    </button>
                                    <button onClick={() => (setCity('Астана'), setIsOpenCity(false))}>
                                        <p className='text-xl text-black/25 hover:opacity-50'>Астана</p>
                                    </button>
                                    <button onClick={() => (setCity('Шымкент'), setIsOpenCity(false))}>
                                        <p className='text-xl text-black/25 hover:opacity-50'>Шымкент</p>
                                    </button>
                                    <button onClick={() => (setCity('Костанай'), setIsOpenCity(false))}>
                                        <p className='text-xl text-black/25 hover:opacity-50'>Костанай</p>
                                    </button>
                                    <button onClick={() => (setCity('Атырау'), setIsOpenCity(false))}>
                                        <p className='text-xl text-black/25 hover:opacity-50'>Атырау</p>
                                    </button>
                                    <button onClick={() => (setCity('Семей'), setIsOpenCity(false))}>
                                        <p className='text-xl text-black/25 hover:opacity-50'>Семей</p>
                                    </button>
                                </div>
                            ) }
                        </div>
                    </div>
                    <div className='mt-6'>
                        <p className='ml-6 text-lg'>Пароль</p>
                        <div className='bg-[#FAFAFA] w-full rounded-lg p-3 px-6 flex flex-row items-center justify-between'>
                            <input value={password} onChange={e => onChangePassword(e.target.value)} type={ isShowPassword ? '' : 'password' } className="outline-none bg-transparent text-xl w-full" placeholder='Введите пароль'/>
                            <button onClick={() => setIsShowPassword(!isShowPassword)}>
                                { isShowPassword ? (
                                    <FaRegEye className='text-black/25 hover:opacity-50' size={24}/>
                                ) : (
                                    <FaRegEyeSlash className='text-black/25 hover:opacity-50' size={24}/>
                                ) }
                            </button>
                        </div>
                    </div>
                    <div className='mt-6'>
                        <p className='ml-6 text-lg'>Подтвердить пароль</p>
                        <div className='bg-[#FAFAFA] w-full rounded-lg p-3 px-6 flex flex-row items-center justify-between'>
                            <input value={confirmPassword} onChange={e => onChangeConfirmPassword(e.target.value)} type={ isShowConfirmPassword ? '' : 'password' } className="outline-none bg-transparent text-xl w-full" placeholder='Введите пароль снова'/>
                            <button onClick={() => setIsShowConfirmPassword(!isShowConfirmPassword)}>
                                { isShowConfirmPassword ? (
                                    <FaRegEye className='text-black/25 hover:opacity-50' size={24}/>
                                ) : (
                                    <FaRegEyeSlash className='text-black/25 hover:opacity-50' size={24}/>
                                ) }
                            </button>
                        </div>
                    </div>
                    <div className='flex flex-row items-center space-x-4 mt-4'>
                        <button onClick={() => setIsSecondCheckBox(!isSecondCheckBox)} className={ isSecondCheckBox ? 'flex-shrink-0 h-5 w-5 rounded-sm bg-[#4D89E3] hover:opacity-50 justify-center items-center flex' : 'flex-shrink-0 h-5 w-5 border-2 rounded-sm border-black/25 hover:opacity-50 justify-center items-center flex' }>
                            { isSecondCheckBox && <IoMdCheckmark className='text-xl text-white'/> }
                        </button>
                        <p className='text-lg '>Я соглашаюсь с передачей и обработкой моих данных</p>
                    </div>
                    { isError && <p className='text-xl text-center text-red-500 mt-4'>Все поля обязательны к заполнению</p> }
                    { isPassordError && <p className='text-xl text-center text-red-500 mt-4'>Пароли не совпадают</p> }
                    { errorMessage && <p className='text-xl text-center text-red-500 mt-4'>{errorMessage}</p> }
                    <button onClick={reg} className='w-full mobile:mb-48 lg:mb-0 p-2 mt-6 justify-center items-center flex bg-[#4D89E3] rounded-lg group hover:opacity-50'>
                        <p className='text-white text-xl group-hover:opacity-50'>Зарегистрироваться</p>
                    </button>
                </div>
            ) }
        </div>
    )
};

export default Reg