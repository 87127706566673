import React, { Component, useState } from 'react';
import Navbar from '../ux/Navbar';
import { IoImageOutline } from "react-icons/io5";
import { FiSend } from "react-icons/fi";
import { Link } from 'react-router-dom';
import ChatsList from '../ux/ChatsList';

class Chats extends Component {
    

    render() {
        return (
            <div className="mobile:px-6 sm:px-12 xl:px-52 mt-20">
                <p className="text-2xl font-medium ">Чаты</p>
                <div className='flex flex-row  mt-2 mobile:h-[75vh] laptop:h-[80vh] rounded-2xl border-[1px] border-black/25'>
                    <ChatsList/>
                    <div className='dialog w-full h-full mobile:hidden laptop:flex flex-col rounded-tr-2xl border-l-[1px] border-black/25 justify-center items-center'>
                        <p className='text-xl text-[#0D0D0D]/80 font-semibold'>Выберите, кому бы хотели написать</p>
                    </div>
                </div>
            </div>
        )
    }
};

export default Chats;