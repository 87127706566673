import React,{ Component, useState, useEffect } from 'react';
import Navbar from '../ux/Navbar';
import Categories from '../ux/Categories';
import Selector from '../ux/Selector';
import Cards from '../ux/Cards';
import Pagination from '../ux/Pagination';
import Footer from '../ux/Footer';
import { useParams } from 'react-router-dom';

function MainPage() {
    const { item } = useParams();

    return (
        <div className='overflow-x-hidden'>
            <div className='mt-24'>
                <Categories/>
                { item !== 'services' && item !== 'tools' && <Selector/> }
                <Cards/>
            </div>
            <Footer/>
        </div>
    ) 
};

export default MainPage;