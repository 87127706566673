import { useState, useEffect } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { IoIosCheckmark } from "react-icons/io";

function ToUpModal({ onClose }) {
    const [isCheckbox, setIsCheckbox] = useState(false);
    const [sum, onChangeSum] = useState();
    const [user, setUser] = useState({});

    useEffect(() => {
        // Получаем значение токена из cookie
        const authToken = document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*=\s*([^;]*).*$)|^.*$/, "$1");
    
        // Отправляем запрос на сервер для получения информации о пользователе
        fetch(`https://avto-pro.kz/api-getUserInfo?token=${authToken}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                setUser(data.user[0]);
            } else {
            }
        })
        .catch(error => {
        });
    }, [user]);

    const fetchUpToTop = async () => {
        try {
            const response = await fetch('https://avto-pro.kz/api-topUpBalance', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ sum: sum, userId: user.id })
            });
    
            const data = await response.json();
            if (data.success) {
                window.location.href = data.url; // Редирект на полученный URL
            } else {
                console.error('Ошибка при получении URL для оплаты');
            }
        } catch (error) {
            console.error('Произошла ошибка при отправке запроса к серверу:', error);
        }
    }

    return (
        <div className="w-screen h-screen fixed z-[100000] flex justify-center items-center top-0 left-0 right-0 bottom-0">
            <div className="rounded-2xl bg-white w-96 p-4 absolute z-[100000] shadow-lg space-y-4">
                <div className="flex flex-row items-center space-x-2">
                    <button onClick={onClose} className="hover:opacity-50">
                        <IoCloseOutline className="text-3xl"/>
                    </button>
                    <p className="text-xl font-semibold text-[#0D0D0D]">Пополнить баланс</p>
                </div>
                <div className="border-2 border-black/10 rounded-xl w-full p-2 px-4">
                    <p className="text-md text-black/10 bg-white p-0.5 absolute z-[100001] -mt-6">Сумма пополнения</p>
                    <input value={sum} onChange={(e) => onChangeSum(e.target.value)} className="text-xl text-black/10 outline-none bg-transparent"/>
                </div>
                <div className="w-full flex flex-row justify-center items-center space-x-2">
                    <button onClick={() => setIsCheckbox(!isCheckbox)} className={ isCheckbox ? "w-6 h-6 border-2 border-[#4D89E3] bg-[#4D89E3] flex justify-center items-center rounded-lg hover:opacity-50" : "w-6 h-6 border-2 border-[#4D89E3] rounded-lg hover:opacity-50"}>
                        { isCheckbox && <IoIosCheckmark className="text-white text-2xl"/> }
                    </button>
                    <p className="text-lg text-black/25 ">Я согласен с публичной офертой</p>
                </div>
                <button onClick={() => fetchUpToTop()} disabled={!isCheckbox || !sum} className={ (!isCheckbox || !sum) ? "w-full rounded-xl p-2 flex justify-center items-center bg-[#4D89E3] opacity-50" : "w-full rounded-xl p-2 flex justify-center items-center bg-[#4D89E3] hover:opacity-50"}>
                    <p className="text-white text-xl">Подтвердить</p>
                </button>
            </div>
            
        </div>
    )
};

export default ToUpModal;