import React, { Component, useEffect, useState } from 'react';
import { FaHeart } from "react-icons/fa";
import { IoArrowBack } from "react-icons/io5";
import { Link } from 'react-router-dom';

function Favourite({ onClose, onCloseProfile }) {
    const [data, setData] = useState([]);
    const [user, setUser] = useState({});

    useEffect(() => {
        const interval = setInterval(fetchUserInfo, 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    const fetchUserInfo = async () => {
        try {
            const authToken = document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*=\s*([^;]*).*$)|^.*$/, "$1");
    
            // Отправляем запрос на сервер для получения информации о пользователе
            const response = await fetch(`https://avto-pro.kz/api-getUserInfo?token=${authToken}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
    
            if (!response.ok) {
                throw new Error('Ошибка при получении данных о пользователе');
            }
    
            const data = await response.json();
            setUser(data.user[0]);
            fetchData(data.user[0].id);
            // Обработка полученных данных
        } catch (error) {
            console.error('Произошла ошибка:', error);
        }
    };

    const fetchData = async (id) => {
        fetch(`https://avto-pro.kz/api-getFavourite/${id}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка сети');
                }
                return response.json();
            })
                .then(data => {
                setData(data);
            })
                .catch(error => {
            });
    }

    const remove = async (id) => {
        try {
            const response = await fetch(`https://avto-pro.kz/api-removeFavourite/${id}/${user.id}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                const data = await response.json();
            } else {
                const errorMessage = await response.text();
            }
            } catch (error) {
        }
    }

    return (
        <div className='py-6 px-8 xl:w-8/12 bg-white'>
            <button onClick={() => onClose()} className='w-12 h-12 rounded-full bg-[#4D89E3]/50 absolute z-50 items-center justify-center flex -ml-14 mt-[12%] group hover:opacity-50'>
                <IoArrowBack className='text-xl text-white'/>
            </button>
            <p className='text-2xl text-nowrap font-medium'>Избранное</p>
            {data.cars && data.cars.length !== 0 ? (
                <div className='space-y-3 overflow-x-hidden h-full'>
                    {data.cars.map((car, index) => (
                        <div key={index} className='w-full shadow-xl bg-white drop-shadow-xl p-3 rounded-xl'>
                            <div className='flex flex-row space-x-2'>
                                <div className='space-x-2 flex flex-row'>
                                    <img src={`https://avto-pro.kz/api-carImage/${car.imagePreview1}`} className='bg-[#BDBDBD] w-48 h-32 rounded-xl object-cover'/>
                                </div>
                                <div className='mr-4'>
                                    <div className='flex flex-row items-center space-x-2 w-full'>
                                        <Link to={car.url} onClick={() => onCloseProfile()} className='hover:opacity-55'>
                                            <p className='text-xl'>{car.title}</p>
                                        </Link>
                                        <button onClick={() => remove(car.id)}>
                                            <FaHeart className='text-xl text-[#4D89E3] hover:opacity-50'/>
                                        </button>
                                    </div>
                                    <p className='text-lg mt-1'>{car.price}тг/день</p>
                                    <p className='text-md mt-1'>Номер: {car.contactPhone}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                
            ) : (
                <div className='w-full h-full justify-center items-center flex'>
                    <p>Нет объявлений в данном списке</p>
                </div>
            )}
        </div>
    )
}

export default Favourite;