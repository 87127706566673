import React, { useEffect, useState } from "react";
import Navbar from "../../ux/Navbar";
import { IoIosArrowBack, IoIosArrowForward  } from "react-icons/io";
import { AiOutlineHeart, AiFillHeart  } from "react-icons/ai"
import { Link } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import Footer from "../../ux/Footer";
import '../../../CusstomScrollBar.css'
import queryString from "query-string";
import FullImagePage from "../FullImagePage";

function AdScreen() {
    const location = useLocation();
    const { item } = useParams();
    const queryParams = queryString.parse(location.search);
    const [data, setData] = useState([]);
    const [user, setUser] = useState({});
    const [isShowPhone, setIsShowPhone] = useState(false);
    const [isFavourite, setIsFavourite] = useState();
    const [favouriteId, setFavouriteId] = useState();
    const [previewImage, setPreviewImage] = useState(); 
    const [isOpenFullMode, setIsOpenFullMode] = useState(false); 

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const response = await fetch(`https://avto-pro.kz/api-${item}/${queryParams.sub}/${queryParams.id}`)

        if (!response.ok) {
            throw new Error('Ошибка при получении данных об объявлении');
        } else {
            const data = await response.json();
            setData(data.car[0]);
            setPreviewImage(`${data.car[0].photoPreview1}`);
            fetchUserInfo(data.car[0].title, data.car[0].price, data.car[0].contactPhone);
        }
    }
    

    const fetchUserInfo = async (title, price, contactPhone) => {
        try {
            const authToken = document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*=\s*([^;]*).*$)|^.*$/, "$1");
    
            // Отправляем запрос на сервер для получения информации о пользователе
            const response = await fetch(`https://avto-pro.kz/api-getUserInfo?token=${authToken}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
    
            if (!response.ok) {
                throw new Error('Ошибка при получении данных о пользователе');
            } else {
                const data = await response.json();
                setUser(data.user[0]);
                await fetchIsFavourite(title, price, contactPhone, data.user[0].id);
            }
            // Обработка полученных данных
        } catch (error) {
            console.error('Произошла ошибка:', error);
        }
    };

    const fetchIsFavourite = async (title, price, contactPhone, id) => {
        try {
            const response = await fetch(`https://avto-pro.kz/api-isFavourite`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ title: title, price: price, contactPhone: contactPhone, userId: id })
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            } else {
                const jsonResponse = await response.json();
                setIsFavourite(jsonResponse.exists);
                setFavouriteId(jsonResponse.id);
            }
        } catch {
            
        }
    };

    const addFavourite = async () => {
        if (user) {
            const carData = {
                'title': data.title,
                'contactPhone': data.contactPhone,
                'price': data.price,
                'imagePreview1': data.photoPreview1,
                'imagePreview2': data.photoPreview2,
                'imagePreview3': data.photoPreview3,
                'imagePreview4': data.photoPreview4,
                'imagePreview5': data.photoPreview5,
                'userId': user.id,
                'url': `/${item}/?id=${queryParams.id}&sub=${queryParams.sub}&page=${queryParams.page}`,
            };
        
            const response = await fetch('https://avto-pro.kz/api-addFavoutite', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(carData)
            });
    
            if (response.ok) {
                window.location.reload();
            }
        } else {
            alert('Только зарегистрированный пользователь может добавить в избранное')
        }
    };

    const removeFavourite = async (id) => {
        try {
            const response = await fetch(`https://avto-pro.kz/api-removeFavourite/${id}/${user.id}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                window.location.reload();
            }
            } catch (error) {
                
        }
    };

    const toggleSetPreviewImage = (image) => {
        setPreviewImage(image);
    }

    const images = [
        data?.photoPreview1,
        data?.photoPreview2,
        data?.photoPreview3,
        data?.photoPreview4,
        data?.photoPreview5,
    ]
    
    return (
        <div className='overflow-x-hidden App' style={{ overflowY: 'scroll' }}>
            <Navbar/>
            <div className="w-screen mobile:px-4 sm:px-12 2xl:px-44 mt-24">
                <Link to={`/${item}/${queryParams.sub}?city=Все&page=${queryParams.page}`} className="flex flex-row items-center group">
                    <IoIosArrowBack size={24} className="mt-1 text-black/25 group-hover:opacity-50"/>
                    <p className="text-black/25 text-2xl ml-2 group-hover:opacity-50">Назад</p>
                </Link>
                { data && (
                    <div className="w-full flex mobile:flex-col 2xl:flex-row justify-between mt-4">
                        <div className="2xl:w-7/12 mobile:w-full">
                            <div className="mobile:h-[300px] xl:h-[600px] flex items-center justify-center w-full">
                                
                                <img
                                    src={`https://avto-pro.kz/api-carImage/${previewImage}`}
                                    className="mobile:h-[300px] xl:h-[600px] bg-[#BDBDBD] w-full rounded-xl object-cover cursor-pointer"
                                    loading='eager'
                                    onClick={() => setIsOpenFullMode(true)}
                                />
                            </div>
                            <div className="flex flex-wrap flex-row w-full mobile:justify-start xl:justify-center">
                                <button className={ data.photoPreview1 === previewImage ? "opacity-50 hover:opacity-100 m-2" : "hover:opacity-50 m-2" } onClick={() => data && toggleSetPreviewImage(`${data.photoPreview1}`)}>
                                    <img loading='eager' src={`https://avto-pro.kz/api-carImage/${data.photoPreview1}`} className="w-20 h-20 rounded-lg bg-[#BDBDBD]"/>
                                </button>
                                <button className={ data.photoPreview2 === previewImage ? "opacity-50 hover:opacity-100 m-2" : "hover:opacity-50 m-2" } onClick={() => data && toggleSetPreviewImage(`${data.photoPreview2}`)}>
                                    <img loading='eager' src={`https://avto-pro.kz/api-carImage/${data.photoPreview2}`} className="w-20 h-20 rounded-lg bg-[#BDBDBD]"/>
                                </button>
                                <button className={ data.photoPreview3 === previewImage ? "opacity-50 hover:opacity-100 m-2" : "hover:opacity-50 m-2" } onClick={() => data && toggleSetPreviewImage(`${data.photoPreview3}`)}>
                                    <img loading='eager' src={`https://avto-pro.kz/api-carImage/${data.photoPreview3}`} className="w-20 h-20 rounded-lg bg-[#BDBDBD]"/>
                                </button>
                                <button className={ data.photoPreview4 === previewImage ? "opacity-50 hover:opacity-100 m-2" : "hover:opacity-50 m-2" } onClick={() => data && toggleSetPreviewImage(`${data.photoPreview4}`)}>
                                    <img loading='eager' src={`https://avto-pro.kz/api-carImage/${data.photoPreview4}`} className="w-20 h-20 rounded-lg bg-[#BDBDBD]"/>
                                </button>
                                <button className={ data.photoPreview5 === previewImage ? "opacity-50 hover:opacity-100 m-2" : "hover:opacity-50 m-2" } onClick={() => data && toggleSetPreviewImage(`${data.photoPreview5}`)}>
                                    <img loading='eager' src={`https://avto-pro.kz/api-carImage/${data.photoPreview5}`} className="w-20 h-20 rounded-lg bg-[#BDBDBD]"/>
                                </button>
                            </div>
                            <p className="mt-6 text-lg font-medium 2xl:block mobile:hidden">{data.description}</p>
                        </div>
                        <div className="2xl:w-4/12 mobile:w-full mobile:mt-6 2xl:mt-0">
                            <div className="w-full space-x-2 flex flex-row items-center border-b-2 border-b-black/25 pb-4">
                                <p className="text-2xl open-sans">{data.title} { data.yearOfIssue !== 0 && `${data.yearOfIssue} г.` }</p>
                                {  isFavourite ? (
                                    <button onClick={() => removeFavourite(favouriteId)}>
                                        <AiFillHeart className="text-red-500 hover:opacity-50 text-2xl"/>
                                    </button>
                                ) : (
                                    <button onClick={addFavourite}>
                                        <AiOutlineHeart className="text-black/50 hover:text-[#4D89E3] text-2xl"/>
                                    </button>
                                )}
                            </div>
                            <p className="text-2xl mt-5 font-medium">{data.price} тг/день</p>
                            { queryParams.sub === 'ads' ? (
                                <div className="mt-4 flex flex-row items-center">
                                    { item === 'services' || item === 'tools' ? (
                                        <>
                                            { item === 'tools' && (
                                                <>
                                                    <div className="space-y-2 mobile:block sm:hidden 2xl:block">
                                                        <p className="text-xl text-black/50">Город</p>
                                                        <p className="text-xl text-black/50">Область применения</p>
                                                        <p className="text-xl text-black/50">Вес</p>
                                                        <p className="text-xl text-black/50">Компактность</p>
                                                    </div>
                                                    <div className="space-y-2 ml-8 mobile:block sm:hidden 2xl:block">
                                                        <p className="text-xl text-black text-nowrap">{data.city}</p>
                                                        <p className="text-xl text-black text-nowrap">{data.applicationArea}</p>
                                                        <p className="text-xl text-black text-nowrap">{data.weight}</p>
                                                        <p className="text-xl text-black text-nowrap">{data.compactness ? 'Да' : 'Нет' }</p>
                                                    </div>
                                                    <div className="flex-row mobile:hidden sm:flex 2xl:hidden">
                                                        <div className="space-y-2">
                                                            <p className="text-xl text-black/50">Город</p>
                                                            <p className="text-xl text-black/50">Область применения</p>
                                                            <p className="text-xl text-black/50">Вес</p>
                                                            <p className="text-xl text-black/50">Компактность</p>
                                                        </div>
                                                        <div className="space-y-2 ml-8">
                                                            <p className="text-xl text-black text-nowrap">{data.city}</p>
                                                            <p className="text-xl text-black text-nowrap">{data.applicationArea}</p>
                                                            <p className="text-xl text-black text-nowrap">{data.weight}</p>
                                                            <p className="text-xl text-black text-nowrap">{data.compactness ? 'Да' : 'Нет' }</p>
                                                        </div>
                                                        <p className="text-black text-xl w-full ml-10 2xl:hidden mobile:hidden xl:block"><span className="text-black/50">Допонительная информация:</span>  {data.additionalInfo}</p>
                                                    </div>
                                                </>
                                            )}
                                            { item === 'services' && (
                                                <>
                                                    <div className="space-y-2 mobile:block sm:hidden 2xl:block">
                                                        <p className="text-xl text-black/50">Город</p>
                                                        <p className="text-xl text-black/50">Область применения</p>
                                                        <p className="text-xl text-black/50">Квалификация</p>
                                                    </div>
                                                    <div className="space-y-2 ml-8 mobile:block sm:hidden 2xl:block">
                                                        <p className="text-xl text-black text-nowrap">{data.city}</p>
                                                        <p className="text-xl text-black text-nowrap">{data.applicationArea}</p>
                                                        <p className="text-xl text-black text-nowrap">{data.qualification}</p>
                                                    </div>
                                                </>
                                            ) }
                                        </>
                                    ) : (
                                        <div className="flex flex-row">
                                            <div className="space-y-2 mobile:block sm:hidden 2xl:block">
                                                <p className="text-xl text-black/50">Город</p>
                                                <p className="text-xl text-black/50">Год выпусука</p>
                                                <p className="text-xl text-black/50">Кузов</p>
                                                <p className="text-xl text-black/50">Пробег</p>
                                                <p className="text-xl text-black/50">Состояние</p>
                                                <p className="text-xl text-black/50">Цвет</p>
                                                <p className="text-xl text-black/50">Двигатель</p>
                                                <p className="text-xl text-black/50">Руль</p>
                                                <p className="text-xl text-black/50">Коробка</p>
                                                <p className="text-xl text-black/50">Растаможен</p>
                                            </div>
                                            <div className="space-y-2 ml-8 mobile:block sm:hidden 2xl:block">
                                                <p className="text-xl text-black">{data.city}</p>
                                                <p className="text-xl text-black">{data.yearOfIssue !== 0 ? data.yearOfIssue : 'Не указано'}</p>
                                                <p className="text-xl text-black">{data.body}</p>
                                                <p className="text-xl text-black">{data.mileage}</p>
                                                <p className="text-xl text-black">{data.state}</p>
                                                <p className="text-xl text-black">{data.color}</p>
                                                <p className="text-xl text-black">{data.engine}</p>
                                                <p className="text-xl text-black">{data.wheel}</p>
                                                <p className="text-xl text-black">{data.transmission}</p>
                                                <p className="text-xl text-black">{data.isCleared ? 'Да' : 'Нет' }</p>
                                            </div>
                                        </div>
                                    ) }
                                    
                                    { (item === 'services' || item === 'tools') ? (
                                        <>
                                            { item === 'services' && (
                                                <div className="flex-row mobile:hidden sm:flex 2xl:hidden">
                                                    <div className="space-y-2">
                                                        <p className="text-xl text-black/50">Город</p>
                                                        <p className="text-xl text-black/50">Область применения</p>
                                                        <p className="text-xl text-black/50">Квалификация</p>
                                                    </div>
                                                    <div className="space-y-2 ml-8">
                                                        <p className="text-xl text-black text-nowrap">{data.city}</p>
                                                        <p className="text-xl text-black text-nowrap">{data.applicationArea}</p>
                                                        <p className="text-xl text-black text-nowrap">{data.qualification}</p>
                                                    </div>
                                                    <p className="text-black text-xl w-full ml-10 2xl:hidden mobile:hidden xl:block"><span className="text-black/50">Допонительная информация:</span>  {data.additionalInfo}</p>
                                                </div>
                                            ) }
                                            { item === 'tools' && (
                                                <div className="flex-row mobile:hidden sm:flex 2xl:hidden">
                                                    <div className="space-y-2">
                                                        <p className="text-xl text-black/50">Город</p>
                                                        <p className="text-xl text-black/50">Область применения</p>
                                                        <p className="text-xl text-black/50">Вес</p>
                                                        <p className="text-xl text-black/50">Компактность</p>
                                                    </div>
                                                    <div className="space-y-2 ml-8">
                                                        <p className="text-xl text-black text-nowrap">{data.city}</p>
                                                        <p className="text-xl text-black text-nowrap">{data.applicationArea}</p>
                                                        <p className="text-xl text-black text-nowrap">{data.weight}</p>
                                                        <p className="text-xl text-black text-nowrap">{data.compactness ? 'Да' : 'Нет' }</p>
                                                    </div>
                                                    <p className="text-black text-xl w-full ml-10 2xl:hidden mobile:hidden xl:block"><span className="text-black/50">Допонительная информация:</span>  {data.additionalInfo}</p>
                                                </div>
                                            ) }
                                        </>
                                    ) : (
                                        <div className="flex-row mobile:hidden sm:flex 2xl:hidden">
                                            <div className="space-y-2">
                                                <p className="text-xl text-black/50">Город</p>
                                                <p className="text-xl text-black/50">Год выпусука</p>
                                                <p className="text-xl text-black/50">Кузов</p>
                                                <p className="text-xl text-black/50">Пробег</p>
                                                <p className="text-xl text-black/50">Состояние</p>
                                            </div>
                                            <div className="space-y-2 ml-8">
                                                <p className="text-xl text-black text-nowrap">{data.city}</p>
                                                <p className="text-xl text-black text-nowrap">{data.yearOfIssue}</p>
                                                <p className="text-xl text-black text-nowrap">{data.body}</p>
                                                <p className="text-xl text-black text-nowrap">{data.mileage}</p>
                                                <p className="text-xl text-black text-nowrap">{data.state}</p>
                                            </div>
                                            <div className="space-y-2 ml-10">
                                                <p className="text-xl text-black/50">Цвет</p>
                                                <p className="text-xl text-black/50">Двигатель</p>
                                                <p className="text-xl text-black/50">Руль</p>
                                                <p className="text-xl text-black/50">Коробка</p>
                                                <p className="text-xl text-black/50">Растаможен</p>
                                            </div>
                                            <div className="space-y-2 ml-8">
                                                <p className="text-xl text-black text-nowrap">{data.color}</p>
                                                <p className="text-xl text-black text-nowrap">{data.engine} (бензин)</p>
                                                <p className="text-xl text-black text-nowrap">{data.wheel}</p>
                                                <p className="text-xl text-black text-nowrap">{data.transmission}</p>
                                                <p className="text-xl text-black text-nowrap">{data.isCleared ? 'Да' : 'Нет' }</p>
                                            </div>
                                            <p className="text-black text-xl w-full ml-10 2xl:hidden mobile:hidden xl:block"><span className="text-black/50">Допонительная информация:</span>  {data.additionalInfo}</p>
                                        </div>
                                    ) }
                                </div>
                            ) : (
                                <div className="mt-4 flex flex-row items-center">
                                    { queryParams.sub === 'tools' && (
                                        <>
                                            <div className="space-y-2 mobile:block sm:hidden 2xl:block">
                                                <p className="text-xl text-black/50">Город</p>
                                                <p className="text-xl text-black/50">Область применения</p>
                                                <p className="text-xl text-black/50">Вес</p>
                                                <p className="text-xl text-black/50">Компактность</p>
                                            </div>
                                            <div className="space-y-2 ml-8 mobile:block sm:hidden 2xl:block">
                                                <p className="text-xl text-black text-nowrap">{data.city}</p>
                                                <p className="text-xl text-black text-nowrap">{data.applicationArea}</p>
                                                <p className="text-xl text-black text-nowrap">{data.weight}</p>
                                                <p className="text-xl text-black text-nowrap">{data.compactness ? 'Да' : 'Нет' }</p>
                                            </div>
                                            <div className="flex-row mobile:hidden sm:flex 2xl:hidden">
                                                <div className="space-y-2">
                                                    <p className="text-xl text-black/50">Город</p>
                                                    <p className="text-xl text-black/50">Область применения</p>
                                                    <p className="text-xl text-black/50">Вес</p>
                                                    <p className="text-xl text-black/50">Компактность</p>
                                                </div>
                                                <div className="space-y-2 ml-8">
                                                    <p className="text-xl text-black text-nowrap">{data.city}</p>
                                                    <p className="text-xl text-black text-nowrap">{data.applicationArea}</p>
                                                    <p className="text-xl text-black text-nowrap">{data.weight}</p>
                                                    <p className="text-xl text-black text-nowrap">{data.compactness ? 'Да' : 'Нет' }</p>
                                                </div>
                                                <p className="text-black text-xl w-full ml-10 2xl:hidden mobile:hidden xl:block"><span className="text-black/50">Допонительная информация:</span>  {data.additionalInfo}</p>
                                            </div>
                                        </>
                                    )}
                                    { queryParams.sub === 'services' && (
                                        <>
                                            <div className="space-y-2 mobile:block sm:hidden 2xl:block">
                                                <p className="text-xl text-black/50">Город</p>
                                                <p className="text-xl text-black/50">Область применения</p>
                                                <p className="text-xl text-black/50">Квалификация</p>
                                            </div>
                                            <div className="space-y-2 ml-8 mobile:block sm:hidden 2xl:block">
                                                <p className="text-xl text-black text-nowrap">{data.city}</p>
                                                <p className="text-xl text-black text-nowrap">{data.applicationArea}</p>
                                                <p className="text-xl text-black text-nowrap">{data.qualification}</p>
                                            </div>
                                            <div className="flex-row mobile:hidden sm:flex 2xl:hidden">
                                                <div className="space-y-2">
                                                    <p className="text-xl text-black/50">Город</p>
                                                    <p className="text-xl text-black/50">Область применения</p>
                                                    <p className="text-xl text-black/50">Квалификация</p>
                                                </div>
                                                <div className="space-y-2 ml-8">
                                                    <p className="text-xl text-black text-nowrap">{data.city}</p>
                                                    <p className="text-xl text-black text-nowrap">{data.applicationArea}</p>
                                                    <p className="text-xl text-black text-nowrap">{data.qualification}</p>
                                                </div>
                                                <p className="text-black text-xl w-full ml-10 2xl:hidden mobile:hidden xl:block"><span className="text-black/50">Допонительная информация:</span>  {data.additionalInfo}</p>
                                            </div>
                                        </>
                                    ) }
                                </div>
                            )}
                            
                            
                            <p className="text-black text-xl w-80 mt-6 mobile:hidden 2xl:block"><span className="text-black/50">Допонительная информация</span>  {data.additionalInfo}</p>
                            <div className="w-full rounded-xl mt-6 bg-[#D3E2F8] p-4 space-y-3">
                                { user && (
                                    <Link to={`/dialog/${data.userId}`} className="w-full flex justify-center items-center rounded-xl bg-white p-2 group hover:opacity-50">
                                        <p className="text-[#4D89E3] text-xl group-hover:opacity-50">Написать сообщение</p>
                                    </Link>
                                ) }
                                <button onClick={() => setIsShowPhone(!isShowPhone)} className="w-full flex justify-center items-center rounded-xl bg-[#4D89E3] p-2 group hover:opacity-50">
                                    <p className="text-white text-xl group-hover:opacity-50">{ !isShowPhone ? 'Показать телефон' : `${data.contactPhone}`}</p>
                                </button>
                            </div>
                            <p className="mt-6 text-lg font-medium xl:hidden mobile:block">{data.description}</p>
                        </div>
                    </div>
                ) }
                { !data && (
                    <div className="w-full h-screen flex flex-col justify-center items-center">
                        <p className="text-9xl font-mono font-bold text-[#4D89E3]">404</p>
                        <p className="text-2xl w-4/12 text-center text-black/25">К сожалению, объявление не было найдено. Возможно оно было удалено или заблокировано</p>
                    </div>
                ) }
            </div>
            { isOpenFullMode && <FullImagePage images={images} onClose={() => setIsOpenFullMode(false)}/> }
            <Footer/>
        </div>
    )
};

export default AdScreen;