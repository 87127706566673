import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function AuthAdmin() {
    const [login, onChangeLogin] = useState();
    const [password, onChangePassword] = useState();
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const auth = async () => {
        try {
            const response = await fetch('https://avto-pro.kz/api-admin-login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ login, password })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error);
            }

            const { token } = await response.json();
            document.cookie = `auth_token_admin=${token}; path=/`;
            navigate('/admin-panel?tab=ads');
            window.location.reload();
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div className="w-screen h-screen justify-center items-center flex flex-col">
            <div className="space-y-6">
                <div className='w-full flex justify-center items-center'>
                    <img loading='eager' src={require('../../img/logotype.png')}/>
                </div>
                <div className="w-96 border-2 border-[#4D89E3] p-2 rounded-xl">
                    <p className="bg-white p-1 py-0 absolute z-1 -mt-6 text-[#4D89E3] ml-2 text-lg">Логин</p>
                    <input value={login} onChange={e => onChangeLogin(e.target.value)} className="w-full text-xl text-black/25 ml-2 outline-none" placeholder="Введите логин"/>
                </div>
                <div className="w-96 border-2 border-[#4D89E3] p-2 rounded-xl">
                    <p className="bg-white p-1 py-0 absolute z-1 -mt-6 text-[#4D89E3] ml-2 text-lg">Пароль</p>
                    <input value={password} onChange={e => onChangePassword(e.target.value)} className="w-full text-xl text-black/25 ml-2 outline-none" placeholder="Введите пароль" type="password"/>
                </div>
                {error && <p className="text-red-500 text-center">{error}</p>}
                <button onClick={auth} className="w-full bg-[#4D89E3] p-2 rounded-xl hover:opacity-50">
                    <p className="text-white text-xl">Войти</p>
                </button>
            </div>
        </div>
    )
};

export default AuthAdmin;