import { render } from '@testing-library/react';
import React, { Component } from 'react';
import { IoIosClose, IoIosArrowDown } from "react-icons/io";
import { LuPlus } from "react-icons/lu";
import MyHistory from './MyHistory';
import MyAds from './MyAds';
import Settings from './Settings';
import Favourite from './Favourite';
import { CiCamera } from "react-icons/ci";
import { IoMdCheckmark } from "react-icons/io";
import ToUpModal from './ToUpModal';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: '',
            user: {},
            newImage: null,
            isOpenTopUp: false
        };
        this.updatePhotoProfile = this.updatePhotoProfile.bind(this);
    }

    setTab = (tabName) => {
        this.setState({ tab: tabName });
    }

    componentDidMount() {
        this.setState({ tab: this.props.activeTab });
        // Получаем значение токена из cookie
        const authToken = document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*=\s*([^;]*).*$)|^.*$/, "$1");
        
        // Отправляем запрос на сервер для получения информации о пользователе
        fetch(`https://avto-pro.kz/api-getUserInfo?token=${authToken}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                this.setState({ user: data.user[0] });
            } else {
            }
        })
        .catch(error => {
        });
    }

    formatBirthday = (date) => {
        const d = new Date(date);
        const day = d.getDate().toString().padStart(2, '0');
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const year = d.getFullYear();
        return `${day}.${month}.${year}`;
    };    

    handleFileChange = (event) => {
        const file = event.target.files[0];
        const name = event.target.name;
        this.setState({
            [name]: file,
        });
    }

    updatePhotoProfile = async () => {
        const formData = new FormData();
        formData.append('user_id', this.state.user.id);
        formData.append('newImage', this.state.newImage);

        try {
            const response = await fetch('https://avto-pro.kz/api-updatePhotoProfile', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Ошибка при отправке файла на сервер');
            } else {
                this.setState({ newImage: null })
                window.location.reload();
            }

        } catch (error) {
        }
    }

    render() {
        return (
            <div className='overflow-y-hidden overflow-x-hidden fixed top-0 left-0 right-0 bottom-0 bg-black/50 z-[10000] justify-center items-center flex'>
                <div className={`bg-white mobile:rounded-none xl:rounded-lg mobile:p-0 xl:p-8 mobile:w-screen mobile:h-screen xl:h-[600px] flex flex-row ${this.state.tab != '' ? 'xl:w-8/12' : 'xl:w-6/12'}`}>
                    <div className={`${this.state.tab != '' ? 'mobile:w-full w-8/12' : 'w-full'}`}>
                        <div className='w-full justify-end flex mobile:p-4 xl:p-0'>
                            <button onClick={() => this.props.onClose()} className='w-7 h-7 justify-center items-center bg-black/80 rounded-full flex group hover:opacity-50'>
                                <IoIosClose className='group-hover:opacity-50' size={24} color='#FFF'/>
                            </button>
                        </div>
                        <div className='px-8 overflow-x-auto h-full'>
                            <p className='text-2xl font-medium'>Профиль</p>
                            <div className='mobile:hidden xl:flex flex-row flex-wrap items-center flex space-x-4 w-full justify-center mt-4'>
                                <button className={this.state.tab === 'История' && 'bg-[#4D89E3] p-1 px-2 rounded-lg'} onClick={() => this.setTab('История')}>
                                    <p className={this.state.tab === 'История' ? 'text-white text-xl':  'text-black/50 text-xl hover:text-[#4D89E3]'}>История</p>
                                </button>
                                <button className={this.state.tab === 'Объявления' && 'bg-[#4D89E3] p-1 px-2 rounded-lg'} onClick={() => this.setTab('Объявления')}>
                                    <p className={this.state.tab === 'Объявления' ? 'text-white text-xl':  'text-black/50 text-xl hover:text-[#4D89E3]'}>Объявления</p>
                                </button>
                                <button className={this.state.tab === 'Настройки' && 'bg-[#4D89E3] p-1 px-2 rounded-lg'} onClick={() => this.setTab('Настройки')}>
                                    <p className={this.state.tab === 'Настройки' ? 'text-white text-xl':  'text-black/50 text-xl hover:text-[#4D89E3]'}>Настройки</p>
                                </button>
                                <button className={this.state.tab === 'Избранное' && 'bg-[#4D89E3] p-1 px-2 rounded-lg'} onClick={() => this.setTab('Избранное')}>
                                    <p className={this.state.tab === 'Избранное' ? 'text-white text-xl':  'text-black/50 text-xl hover:text-[#4D89E3]'}>Избранное</p>
                                </button>
                            </div>
                            <div className={ this.state.tab !== '' ? 'flex justify-center mobile:flex-col items-center xl:px-0 xl:flex-col mt-6 space-y-3' : 'flex mobile:flex-col justify-between lg:px-32 xl:px-0 mobile:space-y-4 xl:space-y-0 xl:flex-row mt-6' }>
                                <div className={ this.state.tab !== '' ? 'flex mobile:flex-col xl:flex-col items-center mobile:space-x-0 space-y-3' : 'flex mobile:flex-col lg:flex-row xl:flex-row items-center mobile:space-x-0 lg:space-x-8 xl:space-x-8' }>
                                    <div className='w-32 h-32 relative flex justify-center items-center'>
                                        { this.state.newImage === null ? (
                                            <div>
                                                <img src={`https://avto-pro.kz/api-userImage/${this.state.user.photo}`} className='w-full h-full rounded-full'/>
                                                <label className='bg-[#4D89E3] border-4 border-white absolute z-10 h-12 w-12 rounded-full right-0 bottom-0 flex justify-center items-center hover:opacity-50' htmlFor={`newImage`}>
                                                    <CiCamera className='text-white text-xl' size={32}/>
                                                    <input
                                                        type='file'
                                                        id={`newImage`}
                                                        name={`newImage`}
                                                        accept='image/*'
                                                        style={{ display: 'none' }}
                                                        onChange={this.handleFileChange}
                                                    />
                                                </label>
                                            </div>
                                        ) : (
                                            <>
                                                <img src={URL.createObjectURL(this.state.newImage)} className='w-full h-full rounded-full'/>
                                                <div className='absolute z-10 flex flex-row w-full justify-between bottom-0'>
                                                    <button onClick={() => this.setState({ newImage: null })} className='bg-[#FF0000] border-2 border-white h-8 w-8 rounded-full flex justify-center items-center hover:opacity-50'>
                                                        <IoIosClose className='text-2xl text-white'/>
                                                    </button>
                                                    <button onClick={this.updatePhotoProfile} className='bg-[#32a852] border-2 border-white h-8 w-8 rounded-full flex justify-center items-center hover:opacity-50'>
                                                        <IoMdCheckmark className='text-xl text-white'/>
                                                    </button>
                                                </div>
                                            </>
                                        ) }
                                    </div>
                                    <div className={ this.state.tab !== '' ? 'flex mobile:flex-row xl:flex-row space-x-6' : 'flex mobile:flex-row lg:flex-col xl:flex-col mobile:space-x-6 lg:space-x-0' }>
                                        <div className={this.state.tab !== '' ? 'items-center justify-center flex flex-col' : ''}>
                                            <p className='text-xl text-black/50'>Имя</p>
                                            <p className='text-xl text-black'>{this.state.user.fullname}</p>
                                        </div>
                                        <div className={this.state.tab !== '' ? 'items-center justify-center flex flex-col' : ''}>
                                            <p className='text-xl text-black/50'>Фамилия</p>
                                            <p className='text-xl text-black'>{this.state.user.lastname}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-col justify-center items-center'>
                                    <div className='flex flex-row items-center space-x-2'>
                                        <p className='text-2xl text-black/50'>Ваш счет</p>
                                        <p className='text-2xl'>{this.state.user.balance} тг</p>
                                    </div>
                                    <button onClick={() => this.setState({ isOpenTopUp: true })} className='bg-[#4D89E3] p-2 mt-2 rounded-lg justify-center items-center flex px-8 group hover:opacity-50'>
                                        <p className='text-xl text-white group-hover:opacity-50'>Пополнить счёт</p>
                                    </button>
                                </div>
                            </div>
                            <div className='flex mobile:flex-col lg:flex-row items-start lg:px-32 xl:px-0 justify-between lg:space-x-12 lg:space-y-0 mobile:space-y-4 xl:space-y-0 xl:flex-row xl:justify-between mt-4 mb-12'>
                                <div className='space-y-6'>
                                    <div>
                                        <p className='text-2xl text-black/50'>Email</p>
                                        <p className='text-2xl text-black'>{this.state.user.email}</p>
                                    </div>
                                    <div>
                                        <p className='text-2xl text-black/50'>Номер телефона</p>
                                        <p className='text-2xl text-black'>{this.state.user.phone}</p>
                                    </div>
                                </div>
                                <div className='space-y-6'>
                                    <div>
                                        <p className='text-2xl text-black/50'>Пол</p>
                                        <p className='text-2xl text-black'>{this.state.user.sex}</p>
                                    </div>
                                    <div>
                                        <p className='text-2xl text-black/50'>Дата рождения</p>
                                        <p className='text-2xl text-black'>{this.formatBirthday(this.state.user.birthday)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    { this.state.tab === 'История' && <MyHistory onClose={() => this.setTab('')}/> }
                    { this.state.tab === 'Объявления' && <MyAds onOpenWriteAd={() => this.props.onOpenWriteAd()} onClose={() => this.setTab('')}/> }
                    { this.state.tab === 'Настройки' && <Settings onClose={() => this.setTab('')}/> }
                    { this.state.tab === 'Избранное' && <Favourite onCloseProfile={() => this.props.onClose()} onClose={() => this.setTab('')}/> }
                    { this.state.isOpenTopUp && <ToUpModal onClose={() => this.setState({ isOpenTopUp: false })}/> }
                </div>
            </div>
        )
    }
};

export default Profile;
