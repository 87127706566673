import React,{ Component, useState, useEffect } from 'react';
import Navbar from '../ux/Navbar';
import Categories from '../ux/Categories';
import Selector from '../ux/Selector';
import Cards from '../ux/Cards';
import Pagination from '../ux/Pagination';
import Footer from '../ux/Footer';


function PublicOfferPage() {

    return (
        <div className='overflow-x-hidden'>
            <div className='mt-24 mobile:px-12 lg:px-28 space-y-4 mb-32'>
                <p className='text-2xl font-medium text-black/80'>ПУБЛИЧНЫЙ ДОГОВОР-ОФЕРТА</p>
                <p className='text-xl font-medium text-black/80'>1. ОБЩИЕ ПОЛОЖЕНИЯ</p>
                <p className='text-xl font-medium text-black/25'>
                    1.1. ИП Шакаева публикует настоящий договор купли-продажи, являющийся публичным договором-офертой (предложением) в адрес физических и юридических лиц в соответствии со ст. 447 Гражданского Кодекса Республики Казахстан (далее – ГК РК).<br/><br/>

                    1.2. Настоящая публичная оферта (именуемая в дальнейшем «Оферта») определяет все существенные условия договора между ИП Шакаева и лицом, акцептовавшим Оферту.<br/><br/>

                    1.3. Настоящий договор заключается между Покупателем и AutoPro в момент оформления аренды.<br/><br/>

                    1.4. Оферта может быть принята любым физическим или юридическим лицом на территории Республики Казахстан, имеющим намерение приобрести товар и/или услуги, реализуемые/предоставляемые AutoProом расположенные на сайте AutoPro avto-pro.kz.<br/><br/>

                    1.5. Покупатель безоговорочно принимает все условия, содержащиеся в оферте в целом (т.е. в полном объеме и без исключений).<br/><br/>

                    1.6. В случае принятия условий настоящего договора (т.е. публичной оферты AutoPro), физическое или юридическое лицо, производящее акцепт оферты, становится Покупателем.<br/><br/>

                    1.7. Акцептом является получение AutoPro сообщения о намерении физического или юридического лица приобрести товар на условиях, предложенных AutoProом<br/><br/>

                    1.8. Оферта и все приложения к ней, а также вся дополнительная информация о товарах/услугах, опубликованы на сайте AutoPro avto-pro.kz.<br/><br/>

                    1.9. Покупатель принимает и согласен, что лицом, фактически производящим реализацию товаров через AutoPro (Поставщик) может являться третье лицо, имеющее соответствующие договорные отношения с AutoProом и осуществляющее соответствующий лицензируемый вид деятельности.<br/><br/>
                </p>
                <p className='text-xl font-medium text-black/80'>2. СТАТУС CЕРВИСА</p>
                <p className='text-xl font-medium text-black/25'>
                    2.1. Сервис является собственностью ИП Шакаева и предназначен для организации дистанционного способа продажи товаров через сеть интернет.<br/><br/>

                    2.2. Сделки AutoPro, регулируются договором купли-продажи (см.ниже) на условиях публичной оферты, размещенным по адресу AutoPro avto-pro.kz. Произведя акцепт оферты (т.е. оплату оформленного в AutoProе заказа), Покупатель получает в собственность товар на условиях Договора купли-продажи.<br/><br/>

                    2.3. AutoPro не несет ответственности за содержание и достоверность информации, предоставленной Покупателем при оформлении заказа.<br/><br/>
                </p>
                <p className='text-xl font-medium text-black/80'>3. СТАТУС ПОКУПАТЕЛЯ</p>
                <p className='text-xl font-medium text-black/25'>
                    3.1. Покупатель несет ответственность за достоверность предоставленной при оформлении заказа информации, и ее чистоту от претензий третьих лиц.<br/><br/>

                    3.2. Покупатель подтверждает свое согласие с условиями, установленными настоящим Договором, путем проставления отметки в графе «Условия Договора мною прочитаны полностью, все условия Договора мне понятны, со всеми условиями Договора я согласен» при оформлении заказа.<br/><br/>

                    3.3. Информация, предоставленная Покупателем, является конфиденциальной. Покупатель, предоставляя свои персональные данные, зарегистрировавшись на сайте или заполнении заявки дает своими действиями согласие на обработку его персональных данных в целях исполнения пользовательского соглашения. AutoPro использует информацию о Покупателе исключительно в целях указанных в настоящем Договоре-оферте.<br/><br/>

                    3.4. Товар приобретается Покупателем исключительно для личных, семейных, домашних нужд, не связанных с осуществлением предпринимательской деятельности. Использование ресурса AutoProа для просмотра и выбора товара, а также для оформления заказа является для Покупателя безвозмездным.<br/><br/>
                </p>
                <p className='text-xl font-medium text-black/80'>4. ПРЕДМЕТ ОФЕРТЫ</p>
                <p className='text-xl font-medium text-black/25'>
                    4.1. Предметом настоящего Договора оферты является предоставление возможности Покупателю приобретать для личных, семейных, домашних и иных нужд, не связанных с осуществлением предпринимательской деятельности Товары, размещенные на сайте AutoPro avto-pro.kz. AutoPro сервис на основании заказов Покупателя, продаёт Покупателю товар в соответствии с условиями и по ценам, установленным Продавцом в оферте и приложениях к ней.<br/><br/>

                    4.2. Договор распространяется на все виды Товаров и услуг, представленных на сайте AutoPro avto-pro.kz, пока такие предложения с описанием присутствуют в каталоге на сайте AutoPro avto-pro.kz.<br/><br/>

                    4.3. Доставка товаров, заказанных и оплаченных Покупателем, осуществляется Арендодатель или Перевозчиком<br/><br/>

                    4.3. К отношениям между Покупателем и Арендодателем применяются положения ГК РК, Закон Республики Казахстан от 04.05.2010 N 274-IV "О защите прав потребителей", а также иные нормативные правовые акты, принятые в соответствии с ними.<br/><br/>

                    4.4. Физическое или юридическое лицо считается принявшим все условия оферты (акцепт оферты) и приложений к ней в полном объеме и без исключений с момента получения Продавцом сообщения о намерении Покупателя приобрести товар на условиях, предложенных Продавцом. В случае акцепта оферты физическое или юридическое лицо считается заключившим с Продавцом договор купли-продажи заказанных товаров и приобретает статус Покупателя.<br/><br/>
                </p>
                <p className='text-xl font-medium text-black/80'>5. ПОРЯДОК ЗАКЛЮЧЕНИЯ ДОГОВОРА КУПЛИ-ПРОДАЖИ</p>
                <p className='text-xl font-medium text-black/25'>
                    5.1. Покупатель может оформить заказ самостоятельно на сайте AutoPro (интернет-сервис).<br/><br/>

                    5.2. При оформлении заказа на AutoPro сервис, Покупатель обязан предоставить информацию о себе:<br/><br/>

                    Ф.И.О. (для физических лиц)<br/>
                    Контактный телефон и электронную почту Покупателя Товара. (при регистрации аккаунта)<br/><br/>
                    
                    5.3. Волеизъявление Покупателя осуществляется посредством внесения последним соответствующих данных в форму заказа в сервисе либо подачей заявки через менеджера сервиса или по электронной почте номеру телефона.<br/><br/>

                    5.4. AutoPro не редактирует информацию о Покупателе.<br/><br/>

                    5.5. Для получения бумажного экземпляра Договора купли-продажи, Покупатель отправляет заявку по электронной почте или телефонам, указанным на сайте.<br/><br/>
                </p>
                <p className='text-xl font-medium text-black/80'>6. ИНФОРМАЦИЯ О ТОВАРЕ</p>
                <p className='text-xl font-medium text-black/25'>
                    6.1. Товар представлен на сайте через графические изображения-образцы, являющиеся собственностью Арендодателя.<br/><br/>

                    6.2. Каждое графическое изображение-образец сопровождается текстовой информацией: наименованием, ценой и описанием товара.<br/><br/>
                </p>
                <p className='text-xl font-medium text-black/80'>7. ПОРЯДОК ПРИОБРЕТЕНИЯ ТОВАРА</p>
                <p className='text-xl font-medium text-black/25'>
                    7.1. Покупатель вправе оформить заказ на любой товар, представленный на сайте AutoPro avto-pro.kz.<br/><br/>

                    7.2. Заказ может быть оформлен Покупателем по телефонам, указанным на сайте, или оформлен самостоятельно на сайте AutoPro avto-pro.kz в переписке с Арендодалем в онайлн-мессенджере AutoPro<br/><br/>
                </p>
                <p className='text-xl font-medium text-black/80'>8. ЦЕНА ТОВАРА</p>
                <p className='text-xl font-medium text-black/25'>
                    8.1. Цена товара на AutoPro (онлайн-сервис) указана в тенге Республики Казахстан за единицу товара.<br/><br/>
                </p>
                <p className='text-xl font-medium text-black/80'>9. ОПЛАТА ТОВАРА</p>
                <p className='text-xl font-medium text-black/25'>
                    9.1. Способы и порядок оплаты товара указаны на сайте в разделе «Оплата» сайта AutoPro avto-pro.kz.<br/><br/>

                    9.3. Оплата безналичным расчетом производится при помощи платежной организации или банка. При безналичной форме оплаты обязанность Покупателя по уплате цены товара считается исполненной с момента зачисления соответствующих денежных средств на расчетный счет Арендодателя.<br/><br/>

                    9.4. Покупатель оплачивает заказ любым способом, выбранным им самим<br/><br/>

                    9.5. Расчеты Сторон при оплате заказа осуществляются в тенге Республики Казахстан.<br/><br/>
                </p>
                <p className='text-xl font-medium text-black/80'>10. ОТВЕТСТВЕННОСТЬ СТОРОН</p>
                <p className='text-xl font-medium text-black/25'>
                    10.1. Стороны несут ответственность за исполнение обязательств по настоящему Договору оферты в соответствии с Законом Республики Казахстан «О Защите прав потребителей», Гражданским кодексом Республики Казахстан другими нормативно-правовыми актами Республики Казахстан.<br/><br/>

                    10.2. AutoPro не несет ответственности за ущерб, причиненный Покупателю вследствие ненадлежащего использования им товаров, заказанных в AutoPro.<br/><br/>

                    10.3. Покупатель несет ответственность за достоверность и достаточность предоставленной при оформлении Заказа информации.<br/><br/>

                    10.4. AutoPro не несет ответственности в случае неправильного выбора Покупателем характеристик Товара.<br/><br/>

                    10.5. Стороны освобождаются от ответственности за полное или частичное неисполнение своих обязательств, если неисполнение является следствием таких непреодолимых обстоятельств как: война или военные действия, землетрясение, наводнение, пожар и другие стихийные бедствия, акты или действия органов государственной власти, изменение таможенных правил, ограничения импорта и экспорта, возникших независимо от воли Сторон после заключения настоящего Договора. Сторона, которая не может исполнить свои обязательства, незамедлительно извещает об этом другую Сторону и предоставляет документы, подтверждающие наличие таких обстоятельств, выданные уполномоченными на то органами<br/><br/>
                </p>
                <p className='text-xl font-medium text-black/80'>11. ПРОЧИЕ УСЛОВИЯ</p>
                <p className='text-xl font-medium text-black/25'>
                    11.1. К отношениям между Покупателем и AutoPro применяется законодательство Республики Казахстан.<br/><br/>

                    11.2. При необходимости Стороны вправе в любое время оформить договор купли-продажи товара в форме письменного двухстороннего соглашения, не противоречащего положениям настоящей оферты.<br/><br/>

                    11.3. В случае возникновения вопросов и претензий со стороны Покупателя, он должен обратиться в отдел продаж ТОО «Rocket Pharm» попо телефону: +7 700 621 6868 или написав на почтовый адрес arsham.mahpirov@mail.ru.<br/><br/>

                    11.4. Настоящий договор вступает в силу с даты акцепта Покупателем настоящей оферты и действует до полного исполнения обязательств Сторонами.<br/><br/>

                    11.5. Все споры и разногласия, возникающие при исполнении Сторонами обязательств по настоящему договору, решаются путем переговоров. В случае невозможности их устранения, Стороны имеют право обратиться за судебной защитой своих интересов.<br/><br/>

                    11.6. AutoPro оставляет за собой право расширять и сокращать товарное предложение на сайте, регулировать доступ к покупке любых товаров, а также приостанавливать или прекращать продажу любых товаров по своему собственному усмотрению.<br/><br/>
                </p>
                <p className='text-xl font-medium text-black/80'>12. АДРЕС И РЕКВИЗИТЫ AutoPro:</p>
                <p className='text-xl font-medium text-black/25'>
                    ИП Шакаева<br/>
                    БИН 710704402286<br/>
                    Актобе, ул. Т.РЫСКУЛОВА, дом 192<br/>
                    KZ92722S000019489366 в АО "Kaspi Bank", БИК CASPKZKA<br/>
                    arsham.mahpirov@mail.ru,+7 700 621 6868<br/>
                </p>
            </div>
            <Footer/>
        </div>
    ) 
};

export default PublicOfferPage;