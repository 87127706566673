import React, { Component, useEffect, useState } from 'react';
import { IoIosClose } from "react-icons/io";
import { IoArrowBack } from 'react-icons/io5';
import { IoCloseOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';


function RightMenu({ onClose, profile, ad, user }) {
    const exit = async () => {
        document.cookie.split(";").forEach(cookie => {
            const [name] = cookie.split("=");
            if (name.trim() === "auth_token") {
                document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            }
        });
        
        window.location.reload();
        onClose();
    };

    return (
        <div className='fixed top-0 bottom-0 bg-black/50 z-[10000] justify-center flex'>
            <div className='bg-white rounded-lg p-4 h-screen w-80 fixed left-0 shadow-2xl drop-shadow-2xl'>
                <div className='flex flex-row space-x-2 items-center'>
                    <button onClick={() => onClose()}>
                        <IoCloseOutline className='text-4xl text-black hover:opacity-50'/>
                    </button>
                    <p className='text-2xl font-semibold'>Меню</p>
                </div>
                <div className='flex flex-col mt-4'>
                    <div className='flex flex-row items-center space-x-4'>
                        <img src={`https://avto-pro.kz/api-userImage/${user.photo}`} className='rounded-full w-12 h-12'/>
                        <p className='text-xl font-medium'>{user.fullname} {user.lastname}</p>
                    </div>
                    <Link onClick={() => profile()} className='items-start flex'>
                        <p className='text-lg font-semibold hover:opacity-50 py-1'>Профиль</p>
                    </Link>
                    <Link to='/history' onClick={() => onClose()} className='items-start flex'>
                        <p className='text-lg font-semibold hover:opacity-50 py-1'>История</p>
                    </Link>
                    <Link to='/myAds' onClick={() => onClose()} className='items-start flex'>
                        <p className='text-lg font-semibold hover:opacity-50 py-1'>Мои объявление</p>
                    </Link>
                    <Link to='/settings' onClick={() => onClose()} className='items-start flex'>
                        <p className='text-lg font-semibold hover:opacity-50 py-1'>Настройки</p>
                    </Link>
                    <Link to='/chats' onClick={() => onClose()} className='items-start flex'>
                        <p className='text-lg font-semibold hover:opacity-50 py-1'>Чаты</p>
                    </Link>
                    <Link to='/news' onClick={() => onClose()} className='items-start flex'>
                        <p className='text-lg font-semibold hover:opacity-50 py-1'>Акции и Объявления</p>
                    </Link>
                    <button onClick={exit} className='items-start flex'>
                        <p className='text-lg text-red-500 font-semibold hover:opacity-50 py-1'>Выйти</p>
                    </button>
                    <button onClick={() => ad()} className="py-1.5 px-3.5 group bg-[#4D89E3] rounded-xl mt-4 hover:bg-[#90B5EE]">
                        <p className='text-white opacity-100 group-hover:opacity-50 text-xl'>Создать объявление</p>
                    </button>
                </div>
            </div>
        </div>
    )

}

export default RightMenu;