import React, { Component, useState, useEffect } from 'react';
import { IoArrowBack } from "react-icons/io5";
import Navbar from './Navbar';
import TariffModal from './TariffModal';
import ChoosePayments from './ChoosePayments';
import { FaGripfire } from 'react-icons/fa6';
import { GoKebabHorizontal } from "react-icons/go";

function MyAdsMobile() {
    const [data, setData] = useState([]);
    const [user, setUser] = useState({});
    const [selectedData, setSelectedData] = useState({});
    const [isOpenChoosePayments, setIsOpenChoosePayments] = useState(false);
    const [isOpenTariffModal, setIsOpenTariffModal] = useState(false);
    const [typePayments, setTypePayments] = useState('');

    useEffect(() => {
        fetchUserInfo();
    }, []);

    const moveToTop = async (adId, userId) => {
        try {
            const response = await fetch('https://avto-pro.kz/api-payToTop', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ tariff: 1, price: 1000, userId: userId, adId: adId })
            });

            const data = await response.json();
            if (data.success) {
                window.location.href = data.url; // Редирект на полученный URL
            } else {
                console.error('Ошибка при получении URL для оплаты');
            }
        } catch (error) {
            console.error('Произошла ошибка при отправке запроса к серверу:', error);
        }
    };

    const fetchUserInfo = async () => {
        try {
            const authToken = document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*=\s*([^;]*).*$)|^.*$/, "$1");
    
            // Отправляем запрос на сервер для получения информации о пользователе
            const response = await fetch(`https://avto-pro.kz/api-getUserInfo?token=${authToken}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
    
            if (!response.ok) {
                throw new Error('Ошибка при получении данных о пользователе');
            }
    
            const data = await response.json();
            setUser(data.user[0]);
            await fetchData(data.user[0].id)
            
            // Обработка полученных данных
        } catch (error) {
            console.error('Произошла ошибка:', error);
        }
    }

    const fetchData = async (id) => {
        fetch(`https://avto-pro.kz/api-myAds/${id}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка сети');
                }
                return response.json();
            })
                .then(data => {
                setData(data);
            })
                .catch(error => {
            });
    }
    
    return (
        <div className='p-4 w-screen mt-20'>
            <p className='text-2xl text-nowrap font-medium'>Мои объявления</p>
            <div className='space-y-3 overflow-x-hidden h-screen'>
                {data.ads && data.ads.length !== 0 ? (
                    data.ads.map((car, index) => (
                        <div key={index} className='w-full shadow-xl bg-white drop-shadow-xl p-3 rounded-xl'>
                            <div className='mt-2'>
                                <div className='flex flex-row justify-between'>
                                    <div className='space-x-2 relative flex flex-row'>
                                        <img src={`https://avto-pro.kz/api-carImage/${car.photoPreview1}`} className='bg-[#BDBDBD] w-48 rounded-xl'/>
                                        { car.isTop && (
                                            <div className="flex flex-row absolute z-[1000] right-0 p-4">             
                                                <div className='w-8 h-8 rounded-full bg-white justify-center items-center flex'>
                                                    <FaGripfire color='#FF3F3F' size={24}/>
                                                </div>
                                            </div>
                                        ) }
                                    </div>
                                    <div className='ml-4 text-right'>
                                        <p className='text-xl'>{car.title.length > 10 ? car.title.slice(0, 10) + '...' : car.title}</p>
                                        <p className='mobile:text-lg lg:text-xl mt-1'>{car.price} тг/день</p>
                                        { car.status === 'Waiting' && (
                                            <button onClick={() => (setIsOpenChoosePayments(true), setSelectedData(car))} className='bg-[#4D89E3] w-32 justify-center items-center flex p-2 rounded-lg mt-6 hover:opacity-50'>
                                                <p className='text-white mobile:text-lg xl:text-xl'>Оплатить</p>
                                            </button>
                                        ) }
                                        { car.status === 'Payed' && (
                                            <div className='border-[#4D89E3] border-2 w-32 justify-center items-center flex p-2 rounded-lg mt-6'>
                                                <p className='text-[#4D89E3] mobile:text-lg xl:text-xl'>Оплачено</p>
                                            </div>
                                        ) }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className='w-full h-full justify-center items-center flex'>
                        <p>Нет объявлений в данном списке</p>
                    </div>
                )}
            </div>
            { isOpenTariffModal && <TariffModal typePayments={typePayments} selectedData={selectedData} onClose={() => setIsOpenTariffModal(false)}/> }
            { isOpenChoosePayments && <ChoosePayments setBalance={() => setTypePayments('Balance')} setOnline={() => setTypePayments('Online')} openTariff={() => setIsOpenTariffModal(true)} onClose={() => setIsOpenChoosePayments(false)}/> }
        </div>
    )
}

export default MyAdsMobile;