import { AiOutlineClose } from "react-icons/ai";
import { useState, useEffect } from "react";

function TariffModal({ onClose, selectedData, typePayments }) {
    const [selectedTariff, setSelecedTariff] = useState(0);
    const [selectedPrice, setSelecedPrice] = useState();
    const [tariffs, setTariffs] = useState([]);
    const [message, setMessage] = useState('');

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch('https://avto-pro.kz/api-getAllTariffs');
            const responseJson = await response.json();
            setTariffs(responseJson.data);
        } catch (error) {
            console.error('Ошибка получения данных о тарифах:', error);
        }
    };

    const selectTariff = (tariff, price) => {
        setSelecedTariff(tariff);
        setSelecedPrice(price);
    }

    const payTariff = async () => {
        try {
            const response = await fetch('https://avto-pro.kz/api-payTariff', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ tariff: selectedTariff, price: selectedPrice, userId: selectedData.userId, adId: selectedData.id })
            });
    
            const data = await response.json();
            if (data.success) {
                window.location.href = data.url; // Редирект на полученный URL
            } else {
                console.error('Ошибка при получении URL для оплаты');
            }
        } catch (error) {
            console.error('Произошла ошибка при отправке запроса к серверу:', error);
        }
    };

    const payTariffBalance = async () => {
        try {
            const response = await fetch('https://avto-pro.kz/api-payTariffBalance', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ tariff: selectedTariff, price: selectedPrice, userId: selectedData.userId, adId: selectedData.id })
            });
    
            const data = await response.json();
            if (data.success) {
                if (data.message === 'У вас не хватает средств') {
                    setMessage(data.message);
                    console.log(data.message);
                } else {
                    onClose();
                }
            } else {
                
            }
        } catch (error) {
            console.error('Произошла ошибка при отправке запроса к серверу:', error);
        }
    }
    
    return (
        <div className="w-full h-full absolute top-0 left-0 right-0 bottom-0 z-[100000] justify-center items-center flex">
            <div className="bg-white mobile:w-64 lg:w-96 p-6 rounded-xl border-[1px] border-[#4D89E3] ">
                <div className="space-x-2 items-center w-full justify-center flex flex-col">
                    <p className="text-2xl font-light">Выберите тариф</p>
                    <p>Способ оплаты {typePayments === 'Online' ? 'Онлайн платёж' : 'Баланс'}</p>
                </div>
                <div className="mt-4">
                    {tariffs.map((tariff, index) => (
                        <button key={index} onClick={() => selectTariff(tariff.monthValue, (tariff.saleValue !== null ? (tariff.price - (tariff.price * (tariff.saleValue / 100))) : tariff.price))} className="w-full py-2 flex flex-row items-center space-x-2 group hover:opacity-75">
                            <div className="w-5 h-5 border-2 border-[#4D89E3] rounded-full justify-center items-center flex">
                                <div className={selectedTariff === tariff.monthValue ? "w-2 h-2 bg-[#4D89E3] rounded-full block" : "w-2 h-2 bg-[#4D89E3] rounded-full hidden group-hover:block"} />
                            </div>
                            <p className="mobile:text-lg lg:text-2xl font-regular text-[#4D89E3]">{tariff.title}</p>
                            <p className="mobile:text-xs lg:text-xl font-light text-[#4D89E3]">
                                {tariff.saleValue !== null ? (
                                    // Если есть скидка, рассчитываем новую цену с учетом скидки
                                    `${(tariff.price - (tariff.price * (tariff.saleValue / 100))).toFixed(0)} тг`
                                ) : (
                                    // Если скидки нет, отображаем стандартную цену
                                    `${tariff.price} тг`
                                )}
                            </p>
                            {tariff.saleValue !== null && (
                                <p className="lg:text-md mobile:text-xs">Скидка {tariff.saleValue}%</p>
                            )}
                        </button>
                    ))}
                </div>
                { message !== '' && <p className="text-red-500 text-center">{message}</p>}
                { typePayments === 'Online' ? (
                    <button onClick={() => payTariff()} disabled={selectedTariff === 0} className={ selectedTariff === 0 ? "w-full mt-6 p-2 bg-[#4D89E3] justify-center items-center flex rounded-xl opacity-50" : "w-full mt-6 p-2 bg-[#4D89E3] justify-center items-center flex rounded-xl hover:opacity-50" }>
                        <p className="text-xl text-white">Подтвердить</p>
                    </button>
                ) : (
                    <button onClick={() => payTariffBalance()} disabled={selectedTariff === 0} className={ selectedTariff === 0 ? "w-full mt-6 p-2 bg-[#4D89E3] justify-center items-center flex rounded-xl opacity-50" : "w-full mt-6 p-2 bg-[#4D89E3] justify-center items-center flex rounded-xl hover:opacity-50" }>
                        <p className="text-xl text-white">Подтвердить</p>
                    </button>
                ) }
                <button onClick={onClose} className="w-full mt-2 p-2 border-[#4D89E3] border-2 justify-center items-center flex rounded-xl hover:opacity-50">
                    <p className="text-xl text-[#4D89E3]">Отменить</p>
                </button>
            </div>
        </div>
    )
};

export default TariffModal;