import React, { Component, useState, useEffect } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { IoArrowBack, IoArrowUp } from "react-icons/io5";
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.module.css'

function Settings({ onClose }) {
    const [isShowSex, setIsShowSex] = useState(false);
    const [user, setUserData] = useState({});

    const [fullname, onChangeFullname] = useState();
    const [lastname, onChangeLastname] = useState();
    const [email, onChangeEmail] = useState();
    const [phone, onChangePhone] = useState();
    const [sex, setSex] = useState();
    const [birthday, setBirthday] = useState();
    
    const [isShowCity, setIsShowCity] = useState(false);
    const [city, setCity] = useState();

    const handleDateChange = (date) => {
        setBirthday(date);
    };
    
    useEffect(() => {
        fetchUserInfo();
    }, [user]);

    const fetchUserInfo = async () => {
        try {
            const authToken = document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*=\s*([^;]*).*$)|^.*$/, "$1");
    
            // Отправляем запрос на сервер для получения информации о пользователе
            const response = await fetch(`https://avto-pro.kz/api-getUserInfo?token=${authToken}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
    
            if (!response.ok) {
                throw new Error('Ошибка при получении данных о пользователе');
            }
    
            const data = await response.json();
            setUserData(data.user[0]);
            
            // Обработка полученных данных
        } catch (error) {
            console.error('Произошла ошибка:', error);
        }
    }

    const formatBirthday = (date) => {
        const d = new Date(date);
        const day = d.getDate().toString().padStart(2, '0');
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const year = d.getFullYear();
        return `${day}.${month}.${year}`;
    };  

    const handleSaveChanges = () => {
        // Подготовим объект для хранения измененных данных
        const updatedData = {};

        updatedData.user_id = user.id
    
        // Сравним значения и заполним объект измененными данными
        if (fullname !== user.fullname) {
            updatedData.fullname = fullname;
        }
        if (lastname !== user.lastname) {
            updatedData.lastname = lastname;
        }
        if (email !== user.email) {
            updatedData.email = email;
        }
        if (phone !== user.phone) {
            updatedData.phone = phone;
        }
        if (sex !== user.sex) {
            updatedData.sex = sex;
        }
        if (formatBirthday(birthday) !== formatBirthday(user.birthday)) {
            updatedData.birthday = birthday;
        }
        if (city !== user.city) {
            updatedData.city = city;
        }
    
        // Отправим измененные данные на сервер, если они есть
        if (Object.keys(updatedData).length > 0) {
            fetch('https://avto-pro.kz/api-updateUserData', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    updatedData: updatedData,
                }),
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    window.location.reload();
                } else {
                    // В случае ошибки обработаем её здесь
                    console.error('Ошибка при сохранении данных:', data.error);
                }
            })
            .catch(error => {
                console.error('Ошибка при отправке запроса на сервер:', error);
            });
        } else {
        }
    };
    

    return (
        <div className='py-6 px-8 w-6/12'>
            <button onClick={() => onClose()} className='w-12 h-12 rounded-full bg-[#4D89E3]/50 absolute z-50 items-center justify-center flex -ml-14 mt-[12%] group hover:opacity-50'>
                <IoArrowBack className='text-xl text-white'/>
            </button>
            <p className='text-2xl text-nowrap font-medium'>Настройки профиля</p>
            <div className='mt-4  mobile:px-6 xl:px-0 overflow-y-scroll  mobile:h-[630px] xl:h-[400px] 2xl:h-[400px]'>
                <div className='w-full flex flex-col items-center justify-between'>
                    <div className='w-full'>
                        <p className='text-xl text-semibold'>Имя</p>
                        <div className='w-full p-2 px-4 mt-2 rounded-lg bg-[#E9E1E1]'>
                            <input value={fullname} onChange={e => onChangeFullname(e.target.value)} className='bg-transparent outline-none text-lg' placeholder={user.fullname}/>
                        </div>
                    </div>
                    <div className='w-full xl:mt-4 2xl:mt-0 mobile:mt-4'>
                        <p className='text-xl text-semibold'>Фамилия</p>
                        <div className='w-full p-2 px-4 mt-2 rounded-lg bg-[#E9E1E1]'>
                            <input value={lastname} onChange={e => onChangeLastname(e.target.value)} className='bg-transparent outline-none text-lg' placeholder={user.lastname}/>
                        </div>
                    </div>
                </div>
                <div className='mt-2'>
                    <p className='text-xl text-semibold'>Email</p>
                    <div className='w-full p-2 px-4 mt-2 rounded-lg bg-[#E9E1E1]'>
                        <input value={email} onChange={e => onChangeEmail(e.target.value)} className='bg-transparent outline-none text-lg' placeholder={user.email}/>
                    </div>
                </div>
                <div className='mt-2'>
                    <p className='text-xl text-semibold'>Номер телефона</p>
                    <div className='w-full p-2 px-4 mt-2 rounded-lg bg-[#E9E1E1]'>
                        <input value={phone} onChange={e => onChangePhone(e.target.value)} className='bg-transparent outline-none text-lg' placeholder={user.phone}/>
                    </div>
                </div>
                <div className='w-full flex space-y-4 flex-col items-center mt-4 justify-between'>
                    <div className='w-full'>
                        <p className='text-xl text-semibold'>Пол</p>
                        <div className={ isShowSex ? 'w-full p-2 px-4 mt-2 rounded-t-lg bg-[#E9E1E1] flex flex-row items-center justify-between': 'w-full p-2 px-4 mt-2 rounded-lg bg-[#E9E1E1] flex flex-row items-center justify-between' }>
                            <p className='2xl:w-24 lg:w-[400px] mobile:w-[400px] outline-none text-black/35 text-lg'>{ sex ? sex : user.sex }</p>
                            <button onClick={() => setIsShowSex(!isShowSex)}>
                                { isShowSex ? <IoIosArrowUp className='text-black/50 text-xl hover:opacity-50'/> : <IoIosArrowDown className='text-black/50 text-xl hover:opacity-50'/> }
                            </button>
                        </div>
                        { isShowSex && (
                            <div className='w-full flex flex-col items-start bg-[#E9E1E1] rounded-b-lg p-3'>
                                <button onClick={() => (setSex('Женский'), setIsShowSex(false))}>
                                    <p className='text-black/35 hover:opacity-50 text-xl'>Женский</p>
                                </button>
                                <button onClick={() => (setSex('Мужской'), setIsShowSex(false))}>
                                    <p className='text-black/35 hover:opacity-50 text-xl'>Мужской</p>
                                </button>
                            </div>
                        ) }
                    </div>
                    <div className='w-full'>
                        <p className='text-xl text-semibold'>Дата рождения</p>
                        <div className='w-full p-2 px-4 mt-2 rounded-lg bg-[#E9E1E1] flex flex-row items-center justify-between'>
                            <ReactDatePicker
                                selected={birthday}
                                onChange={handleDateChange}
                                dateFormat="dd.MM.yyyy" // Установка формата даты
                                placeholderText={formatBirthday(user.birthday)}
                                className='text-black/35 text-lg w-full outline-none bg-transparent'
                            />
                        </div>
                    </div>
                    <div className='w-full'>
                        <p className='text-xl text-semibold'>Город</p>
                        <div className={ isShowCity ? 'w-full p-2 px-4 mt-2 rounded-t-lg bg-[#E9E1E1] flex flex-row items-center justify-between': 'w-full p-2 px-4 mt-2 rounded-lg bg-[#E9E1E1] flex flex-row items-center justify-between' }>
                            <p className='2xl:w-24 lg:w-[400px] mobile:w-[400px] outline-none text-black/35 text-lg'>{ city ? city : user.city }</p>
                            <button onClick={() => setIsShowCity(!isShowCity)}>
                                { isShowCity ? <IoIosArrowUp className='text-black/50 text-xl hover:opacity-50'/> : <IoIosArrowDown className='text-black/50 text-xl hover:opacity-50'/> }
                            </button>
                        </div>
                        { isShowCity && (
                            <div className='w-full flex flex-col items-start bg-[#E9E1E1] rounded-b-lg p-3'>
                                <button onClick={() => (setCity('Алматы'), setIsShowCity(false))}>
                                    <p className='text-xl text-black/25 hover:opacity-50'>Алматы</p>
                                </button>
                                <button onClick={() => (setCity('Астана'), setIsShowCity(false))}>
                                    <p className='text-xl text-black/25 hover:opacity-50'>Астана</p>
                                </button>
                                <button onClick={() => (setCity('Шымкент'), setIsShowCity(false))}>
                                    <p className='text-xl text-black/25 hover:opacity-50'>Шымкент</p>
                                </button>
                                <button onClick={() => (setCity('Костанай'), setIsShowCity(false))}>
                                    <p className='text-xl text-black/25 hover:opacity-50'>Костанай</p>
                                </button>
                                <button onClick={() => (setCity('Атырау'), setIsShowCity(false))}>
                                    <p className='text-xl text-black/25 hover:opacity-50'>Атырау</p>
                                </button>
                                <button onClick={() =>(setCity('Семей'), setIsShowCity(false))}>
                                    <p className='text-xl text-black/25 hover:opacity-50'>Семей</p>
                                </button>
                            </div>
                        ) }
                    </div>
                </div>
            </div>
            <button onClick={() => handleSaveChanges()} className='w-full items-center justify-center flex bg-[#4D89E3] p-2 rounded-xl mt-4 group hover:opacity-50'>
                <p className='text-white text-xl group-hover:opacity-50'>Сохранить изменения</p>
            </button>
        </div>
    )
}

export default Settings;