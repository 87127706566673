import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';

function Selector() {
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const { item, page, category } = useParams();
    
    console.log(queryParams);
    return (
        <div className='w-screen justify-center items-center flex flex-col mt-2'>
            <p className='text-4xl font-semibold font-sans'>Топ объявления</p>
            <div className='flex flex-row space-x-6 mt-4'>
                <Link to={`/${item}/ads?city=${queryParams.city}&page=1`} className={ category === 'ads' ? 'px-3.5 py-1 bg-[#6398E7] rounded-lg' : ''}>
                    <p className={ category === 'ads' ? 'sm:text-2xl text-white font-medium' : 'sm:text-2xl font-medium opacity-25 hover:opacity-100' }>Объявления</p>
                </Link>
                <Link to={`/${item}/tools?city=${queryParams.city}&page=1`} className={ category === 'tools' ? 'px-3.5 py-1 bg-[#6398E7] rounded-lg' : ''}>
                    <p className={ category === 'tools' ? 'sm:text-2xl text-white font-medium' : 'sm:text-2xl font-medium opacity-25 hover:opacity-100' }>Оборудование</p>
                </Link>
                <Link to={`/${item}/services?city=${queryParams.city}&page=1`} className={ category === 'services' ? 'px-3.5 py-1 bg-[#6398E7] rounded-lg' : ''}>
                    <p className={ category === 'services' ? 'sm:text-2xl text-white font-medium' : 'sm:text-2xl font-medium opacity-25 hover:opacity-100' }>Услуги</p>
                </Link>
            </div>
        </div>
    )
};

export default Selector;