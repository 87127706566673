import { render } from '@testing-library/react';
import React, { Component } from 'react';
import { IoIosClose, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { LuPlus } from "react-icons/lu";

class WriteAdModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            isOpenCategory: false,
            title: '',
            cost: '',
            category: '',
            photoPreview1: null,
            photoPreview2: null,
            photoPreview3: null,
            photoPreview4: null,
            photoPreview5: null,
            description: '',
            isErrorStepOne: false,
            user: {},

            isOpenCity: false,
            city: '',
            
            color: '',

            yearOfIssue: '',

            isOpenEngine: false,
            engine: '',

            isOpenWheel: false,
            wheel: '',

            isOpenBody: false,
            body: '',

            isOpenTransmission: false,
            transmission: '',

            mileage: '',

            isStateOpen: '',
            stateCar: '',

            isClearedOpen: false,
            isCleared: '',

            additionalInfo: '',

            isOpenApplicationArea: false,
            applicationArea: '',

            isOpenQualification: false,
            qualification: '',

            weight: '',

            isOpenCompactness: false,
            compactness: '',

            isTwoStepError: false,
        };
        this.nextStep = this.nextStep.bind(this);
        this.setCategory = this.setCategory.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleCostChange = this.handleCostChange.bind(this);
        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleColorChange = this.handleColorChange.bind(this);
        this.handleYearOfIssueChange = this.handleYearOfIssueChange.bind(this);
        this.setEngine = this.setEngine.bind(this);
        this.setWheel = this.setWheel.bind(this);
        this.setBody = this.setBody.bind(this);
        this.setStateCar = this.setStateCar.bind(this);
        this.setCleared = this.setCleared.bind(this);
        this.createAd = this.createAd.bind(this);
        this.setApplicationArea = this.setApplicationArea.bind(this);
        this.setQualification = this.setQualification.bind(this);
        this.handleWeightChange = this.handleWeightChange.bind(this);
        this.setCompactness = this.setCompactness.bind(this);
        this.createAdToolService = this.createAdToolService.bind(this);
    }
    
    nextStep = (stepCount) => {
        if ( this.state.category === '' || this.state.сost === '' || this.state.title === '' || this.state.photoPreview1 === null || this.state.photoPreview2 === null || this.state.photoPreview3 === null || this.state.photoPreview4 === null || this.state.photoPreview5 === null || this.state.description === '' ) {
            this.setState({ isErrorStepOne: true });
        } else {
            this.setState({ isErrorStepOne: false });
            this.setState({ step: stepCount });
        }
    }

    setCategory = (category) => {
        this.setState({ category: category, isOpenCategory: false });
    }

    setCity = (city) => {
        this.setState({ city: city, isOpenCity: false });
    }

    setApplicationArea = (applicationArea) => {
        this.setState({ applicationArea: applicationArea, isOpenApplicationArea: false });
    }

    setQualification = (qualification) => {
        this.setState({ qualification: qualification, isOpenQualification: false });
    }

    handleFileChange = (event) => {
        const file = event.target.files[0];
        const name = event.target.name;
        this.setState({
            [name]: file,
        });
    }

    handleCostChange = (event) => {
        this.setState({ cost: event.target.value });
    }

    handleTitleChange = (event) => {
        this.setState({ title: event.target.value });
    }

    handleDescriptionChange = (event) => {
        this.setState({ description: event.target.value });
    }

    handleColorChange = (event) => {
        this.setState({ color: event.target.value });
    }

    handleYearOfIssueChange = (event) => {
        this.setState({ yearOfIssue: event.target.value });
    }

    setEngine = (engine) => {
        this.setState({ engine: engine, isOpenEngine: false });
    }

    setWheel = (wheel) => {
        this.setState({ wheel: wheel, isOpenWheel: false });
    }

    setBody = (body) => {
        this.setState({ body: body, isOpenBody: false });
    }

    setCompactness = (compactness) => {
        this.setState({ compactness: compactness, isOpenCompactness: false });
    }

    setTransmission = (transmission) => {
        this.setState({ transmission: transmission, isOpenTransmission: false });
    }

    setStateCar = (stateCar) => {
        this.setState({ stateCar: stateCar, isStateOpen: false });
    }

    setCleared = (isCleared) => {
        this.setState({ isCleared: isCleared, isClearedOpen: false });
    }

    handleMileageChange = (event) => {
        this.setState({ mileage: event.target.value });
    }

    handleAdditionalInfoChange = (event) => {
        this.setState({ additionalInfo: event.target.value });
    }

    handleWeightChange = (event) => {
        this.setState({ weight: event.target.value });
    }

    componentDidMount() {
        // Получаем значение токена из cookie
        const authToken = document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*=\s*([^;]*).*$)|^.*$/, "$1");
    
        // Отправляем запрос на сервер для получения информации о пользователе
        fetch(`https://avto-pro.kz/api-getUserInfo?token=${authToken}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                this.setState({ user: data.user[0] });
            } else {
            }
        })
        .catch(error => {
        });
    }

    createAd = async () => {
        if ( this.state.city === '') {
            this.setState({ isTwoStepError: true });
        } else {
            this.setState({ isTwoStepError: false });
            const formData = new FormData();
            formData.append('category', this.state.category);
            formData.append('title', this.state.title);
            formData.append('description', this.state.description);
            formData.append('price', this.state.cost);
            formData.append('city', this.state.city);
            formData.append('yearOfIssue', this.state.yearOfIssue || 0);
            formData.append('body', this.state.body || 'Не указано');
            formData.append('state', this.state.stateCar || 'Не указано');
            formData.append('color', this.state.color || 'Не указано');
            formData.append('engine', this.state.engine || 'Не указано');
            formData.append('wheel', this.state.wheel || 'Не указано');
            formData.append('transmission', this.state.transmission || 'Не указано');
            formData.append('isCleared', this.state.isCleared || 0);
            formData.append('contactPhone', this.state.user.phone);
            formData.append('userId', this.state.user.id || 'Не указано');
            formData.append('additionalInfo', this.state.additionalInfo || 'Не указано');
            formData.append('photoPreviews', this.state.photoPreview1);
            formData.append('photoPreviews', this.state.photoPreview2);
            formData.append('photoPreviews', this.state.photoPreview3);
            formData.append('photoPreviews', this.state.photoPreview4);
            formData.append('photoPreviews', this.state.photoPreview5);

            try {
                const response = await fetch('https://avto-pro.kz/api-createAd', {
                    method: 'POST',
                    body: formData,
                });

                if (!response.ok) {
                    throw new Error('Ошибка при отправке файла на сервер');
                }
                if (response.ok) {
                    this.props.onClose()
                }

            } catch (error) {
            }
        }
    }

    createAdToolService = async () => {
        if (this.state.category === 'Услуги') {
            if ( this.state.city === '') {
                this.setState({ isTwoStepError: true });
            } else {
                this.setState({ isTwoStepError: false });
                const formData = new FormData();
                formData.append('category', this.state.category);
                formData.append('title', this.state.title);
                formData.append('description', this.state.description);
                formData.append('price', this.state.cost);
                formData.append('city', this.state.city);
                formData.append('applicationArea', this.state.applicationArea || 'Не указано');
                formData.append('qualification', this.state.qualification || 'Не указано');
                formData.append('contactPhone', this.state.user.phone);
                formData.append('userId', this.state.user.id);
                formData.append('additionalInfo', this.state.additionalInfo || 'Не указано');
                formData.append('photoPreviews', this.state.photoPreview1);
                formData.append('photoPreviews', this.state.photoPreview2);
                formData.append('photoPreviews', this.state.photoPreview3);
                formData.append('photoPreviews', this.state.photoPreview4);
                formData.append('photoPreviews', this.state.photoPreview5);
    
                try {
                    const response = await fetch('https://avto-pro.kz/api-createAdServicesTools', {
                        method: 'POST',
                        body: formData,
                    });
    
                    if (!response.ok) {
                        throw new Error('Ошибка при отправке файла на сервер');
                    } else {
                        this.props.onClose()
                    }
    
                } catch (error) {
                }
            }
        } else {
            if ( this.state.city === '') {
                this.setState({ isTwoStepError: true });
            } else {
                this.setState({ isTwoStepError: false });
                const formData = new FormData();
                formData.append('category', this.state.category);
                formData.append('title', this.state.title);
                formData.append('description', this.state.description);
                formData.append('price', this.state.cost);
                formData.append('city', this.state.city);
                formData.append('applicationArea', this.state.applicationArea || 'Не указано');
                formData.append('weight', this.state.weight || 0);
                formData.append('compactness', this.state.compactness || 'Не указано');
                formData.append('contactPhone', this.state.user.phone);
                formData.append('userId', this.state.user.id);
                formData.append('additionalInfo', this.state.additionalInfo || 'Не указано');
                formData.append('photoPreviews', this.state.photoPreview1);
                formData.append('photoPreviews', this.state.photoPreview2);
                formData.append('photoPreviews', this.state.photoPreview3);
                formData.append('photoPreviews', this.state.photoPreview4);
                formData.append('photoPreviews', this.state.photoPreview5);
    
                try {
                    const response = await fetch('https://avto-pro.kz/api-createAdServicesTools', {
                        method: 'POST',
                        body: formData,
                    });
    
                    if (!response.ok) {
                        throw new Error('Ошибка при отправке файла на сервер');
                    } else {
                        this.props.onClose()
                    }
    
                } catch (error) {
                }
            }
        }
    }

    render() {
        return (
            <div className='fixed top-0 left-0 right-0 bottom-0 bg-black/50 z-[1000000000] justify-center items-center flex'>
                <div className='bg-white mobile:rounded-none mobile:h-screen xl:h-[700px] xl:rounded-lg p-8 mobile:w-screen xl:w-5/12 2xl:w-4/12'>
                    <div className='w-full justify-end flex'>
                        <button onClick={() => this.props.onClose()} className='w-7 h-7 justify-center items-center bg-black/80 rounded-full flex group hover:opacity-50'>
                            <IoIosClose className='group-hover:opacity-50' size={24} color='#FFF'/>
                        </button>
                    </div>
                    <div className='px-6 py-6 h-[600px] mt-4 overflow-y-scroll'>
                        <div className='w-full justify-center items-center flex'> 
                            <div className='flex flex-row items-center'>
                                <button onClick={() => this.nextStep(1)} className={ this.state.step === 1 ? 'w-24 h-24 rounded-full border-2 border-[#6398E7] items-center justify-center flex hover:opacity-50' : 'w-24 h-24 rounded-full border-2 border-[#6398E7] items-center justify-center flex opacity-50 hover:opacity-100'}>
                                    <p className='text-4xl text-[#6398E7]'>1</p>
                                </button>
                                <div className='mobile:w-24 xl:w-32 border-2 border-[#6398E7] border-dashed rounded-full'/>
                                <button onClick={() => this.nextStep(2)} className={ this.state.step === 2 ? 'w-24 h-24 rounded-full border-2 border-[#6398E7] items-center justify-center flex hover:opacity-50' : 'w-24 h-24 rounded-full border-2 border-[#6398E7] items-center justify-center flex opacity-50 hover:opacity-100'}>
                                    <p className='text-4xl text-[#6398E7]'>2</p>
                                </button>
                            </div>
                        </div>
                        <p className='text-2xl font-semibold mt-4'>Подать объявление</p>
                        { this.state.step === 1 ? (
                            <>
                                <div className='flex mobile:flex-col mobile:w-full xl:flex-row items-center xl:justify-between'>
                                    <div className='mt-3 mobile:w-full'>
                                        <p className='ml-6 text-lg'>Заголовок</p>
                                        <div className='bg-[#FAFAFA] xl:w-[90%] rounded-lg p-3 px-6'>
                                            <input
                                                className="outline-none bg-transparent text-xl w-full"
                                                placeholder='Введите заголовок'
                                                value={this.state.title} // Привязываем значение поля к состоянию
                                                onChange={this.handleTitleChange} // Добавляем обработчик изменения
                                            />
                                        </div>
                                    </div>
                                    <div className='mt-3 xl:ml-0 mobile:w-full'>
                                        <p className='ml-6 text-lg'>Цена</p>
                                        <div className='bg-[#FAFAFA] xl:w-[100%] rounded-lg p-3 px-6'>
                                            <input
                                                className="outline-none bg-transparent text-xl w-full"
                                                placeholder='Введите цену'
                                                value={this.state.cost} // Привязываем значение поля к состоянию
                                                onChange={this.handleCostChange} // Добавляем обработчик изменения
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-3'>
                                    <p className='ml-6 text-lg'>Категория</p>
                                    <div className={ this.state.isOpenCategory ? 'bg-[#FAFAFA] w-auto rounded-t-lg p-3 px-6 flex flex-row items-center justify-between border-b-[1px] border-b-black/35' : 'bg-[#FAFAFA] w-auto rounded-lg p-3 px-6 flex flex-row items-center justify-between' }>
                                        <p className="text-black/35 text-xl w-full">{ this.state.category ? this.state.category : 'Выберите категорию' }</p>
                                        <button onClick={() => this.setState({ isOpenCategory: !this.state.isOpenCategory })}>
                                            { this.state.isOpenCategory ? (
                                                <IoIosArrowUp size={32} className='text-black/35 hover:opacity-50'/>
                                            ) : (
                                                <IoIosArrowDown size={32} className='text-black/35 hover:opacity-50'/>
                                            ) }
                                        </button>
                                    </div>
                                    { this.state.isOpenCategory && (
                                        <div className='w-full rounded-b-lg bg-[#FAFAFA] p-3 space-y-3 flex flex-col items-start'>
                                            <button onClick={() => {this.setCategory('Легковые'); this.setState({ isOpenCategory: !this.state.isOpenCategory })}}>
                                                <p className="text-black/35 text-xl w-full hover:opacity-50">Легковые</p>
                                            </button>
                                            <button onClick={() => this.setCategory('Грузовые')}>
                                                <p className="text-black/35 text-xl w-full hover:opacity-50">Грузовые</p>
                                            </button>
                                            <button onClick={() => this.setCategory('Водный')}>
                                                <p className="text-black/35 text-xl w-full hover:opacity-50">Водный</p>
                                            </button>
                                            <button onClick={() => this.setCategory('Оборудование')}>
                                                <p className="text-black/35 text-xl w-full hover:opacity-50">Оборудование</p>
                                            </button>
                                            <button onClick={() => this.setCategory('Услуги')}>
                                                <p className="text-black/35 text-xl w-full hover:opacity-50">Услуги</p>
                                            </button>
                                        </div>
                                    ) }
                                </div>
                                <div className='mt-3'>
                                    <p className='ml-6 text-lg'>Фото</p>
                                    <div className='flex flex-col space-y-6 w-full overflow-y-auto'>
                                        {[1, 2, 3, 4, 5].map(index => (
                                            <div key={index} className='w-full h-32 rounded-lg bg-[#FAFAFA] justify-center items-center flex'>
                                                {this.state[`photoPreview${index}`] ? (
                                                    <img src={URL.createObjectURL(this.state[`photoPreview${index}`])} alt={`Photo ${index}`} className='w-full h-full object-cover'/>
                                                ) : (
                                                    <label htmlFor={`photoPreview${index}`}>
                                                        <LuPlus className='text-black/50' size={32}/>
                                                        <input
                                                            type='file'
                                                            id={`photoPreview${index}`}
                                                            name={`photoPreview${index}`}
                                                            accept='image/*'
                                                            style={{ display: 'none' }}
                                                            onChange={this.handleFileChange}
                                                        />
                                                    </label>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className='mt-3'>
                                    <p className='ml-6 text-lg'>Описание</p>
                                    <div className='bg-[#FAFAFA] w-full rounded-lg p-3 px-6 h-64'>
                                        <textarea value={this.state.description} onChange={this.handleDescriptionChange} multiple={true} className="outline-none h-56 bg-transparent text-xl w-full" placeholder='Введите описание'/>
                                    </div>
                                </div>
                                { this.state.isErrorStepOne && <p className='text-red-500 text-xl text-center mt-4'>Все поля обязательны к заполнению</p> }
                                <button onClick={() => this.nextStep(2)} className='w-full p-2 justify-center items-center bg-[#4D89E3] rounded-lg mt-6 group hover:opacity-50'>
                                    <p className='text-xl text-white group-hover:opacity-50'>Далее</p>
                                </button>
                            </>
                        ) : (
                            <>
                                { ( this.state.category === 'Легковые' || this.state.category === 'Грузовые' || this.state.category === 'Водный' ) && (
                                    <>
                                        <div className='flex mobile:flex-col lg:flex-row justify-between w-sreen'>
                                            <div className='mt-3 mobile:w-full lg:w-auto'>
                                                <div className='w-full'>
                                                    <p className='ml-6 text-lg'>Город</p>
                                                    <div className={ this.state.isOpenCity ? 'bg-[#FAFAFA] rounded-t-lg mobile:w-full lg:w-60 p-3 px-6 flex flex-row items-center justify-between border-b-[1px] border-b-black/35' : 'bg-[#FAFAFA] rounded-lg mobile:w-full lg:w-60 p-3 px-6 flex flex-row items-center justify-between' }>
                                                        <p className="text-black/35 text-xl w-full">{ this.state.city ? this.state.city : 'Город' }</p>
                                                        <button onClick={() => this.setState({ isOpenCity: !this.state.isOpenCity })}>
                                                            { this.state.isOpenCity ? (
                                                                <IoIosArrowUp size={32} className='text-black/35 hover:opacity-50'/>
                                                            ) : (
                                                                <IoIosArrowDown size={32} className='text-black/35 hover:opacity-50'/>
                                                            ) }
                                                        </button>
                                                    </div>
                                                </div>
                                                { this.state.isOpenCity && (
                                                    <div className='w-full rounded-b-lg bg-[#FAFAFA] p-3 space-y-3 flex flex-col items-start'>
                                                        <button onClick={() => this.setCity('Алматы')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Алматы</p>
                                                        </button>
                                                        <button onClick={() => this.setCity('Астана')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Астана</p>
                                                        </button>
                                                        <button onClick={() => this.setCity('Шымкент')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Шымкент</p>
                                                        </button>
                                                        <button onClick={() => this.setCity('Караганда')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Караганда</p>
                                                        </button>
                                                        <button onClick={() => this.setCity('Актобе')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Актобе</p>
                                                        </button>
                                                        <button onClick={() => this.setCity('Тараз')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Тараз</p>
                                                        </button>
                                                        <button onClick={() => this.setCity('Павлодар')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Павлодар</p>
                                                        </button>
                                                        <button onClick={() => this.setCity('Усть-Каменогорск')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Усть-Каменогорск</p>
                                                        </button>
                                                        <button onClick={() => this.setCity('Семей')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Семей</p>
                                                        </button>
                                                    </div>
                                                ) }
                                            </div>
                                            <div className='mt-3 mobile:w-full lg:w-auto'>
                                                <p className='ml-6 text-lg'>Цвет</p>
                                                <div className='bg-[#FAFAFA] mobile:w-full lg:w-60 rounded-lg p-3 px-6'>
                                                    <input value={this.state.color} onChange={this.handleColorChange} className="outline-none bg-transparent text-xl w-full" placeholder='Введите цвет'/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex mobile:flex-col lg:flex-row justify-between'>
                                            <div className='mt-3 mobile:w-full lg:w-auto'>
                                                <p className='ml-6 text-lg'>Год выпуска</p>
                                                <div className='bg-[#FAFAFA] mobile:w-full lg:w-60 rounded-lg p-3 px-6'>
                                                    <input value={this.state.yearOfIssue} onChange={this.handleYearOfIssueChange} className="outline-none bg-transparent text-xl w-full" placeholder='Введите год'/>
                                                </div>
                                            </div>
                                            <div className='mt-3 mobile:w-full lg:w-auto'>
                                                <p className='ml-6 text-lg'>Двигатель</p>
                                                <div className={ this.state.isOpenEngine ? 'bg-[#FAFAFA] rounded-t-lg mobile:w-full lg:w-60 p-3 px-6 flex flex-row items-center justify-between border-b-[1px] border-b-black/35' : 'bg-[#FAFAFA] rounded-lg mobile:w-full lg:w-60 p-3 px-6 flex flex-row items-center justify-between' }>
                                                    <p className="text-black/35 text-xl w-full">{ this.state.engine ? this.state.engine : 'Выберите двигатель' }</p>
                                                    <button onClick={() => this.setState({ isOpenEngine: !this.state.isOpenEngine })}>
                                                        { this.state.isOpenEngine ? (
                                                            <IoIosArrowUp size={32} className='text-black/35 hover:opacity-50'/>
                                                        ) : (
                                                            <IoIosArrowDown size={32} className='text-black/35 hover:opacity-50'/>
                                                        ) }
                                                    </button>
                                                </div>
                                                { this.state.isOpenEngine && (
                                                    <div className='w-full rounded-b-lg bg-[#FAFAFA] p-3 space-y-3 flex flex-col items-start'>
                                                        <button onClick={() => this.setEngine('Бензиновый')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Бензиновый</p>
                                                        </button>
                                                        <button onClick={() => this.setEngine('Дизельный')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Дизельный</p>
                                                        </button>
                                                    </div>
                                                ) }
                                            </div>
                                        </div>
                                        <div className='flex mobile:flex-col lg:flex-row justify-between'>
                                            <div className='mt-3 mobile:w-full lg:w-auto'>
                                                <p className='ml-6 text-lg'>Кузов</p>
                                                <div className={ this.state.isOpenBody ? 'bg-[#FAFAFA] rounded-t-lg mobile:w-full lg:w-60 p-3 px-6 flex flex-row items-center justify-between border-b-[1px] border-b-black/35' : 'bg-[#FAFAFA] rounded-lg mobile:w-full lg:w-60 p-3 px-6 flex flex-row items-center justify-between' }>
                                                    <p className="text-black/35 text-xl w-full">{ this.state.body ? this.state.body : 'Выберите ответ' }</p>
                                                    <button onClick={() => this.setState({ isOpenBody: !this.state.isOpenBody })}>
                                                        { this.state.isOpenBody ? (
                                                            <IoIosArrowUp size={32} className='text-black/35 hover:opacity-50'/>
                                                        ) : (
                                                            <IoIosArrowDown size={32} className='text-black/35 hover:opacity-50'/>
                                                        ) }
                                                    </button>
                                                </div>
                                                { this.state.isOpenBody && (
                                                    <div className='w-full rounded-b-lg bg-[#FAFAFA] p-3 space-y-3 flex flex-col items-start'>
                                                        <button onClick={() => this.setBody('Седан')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Седан</p>
                                                        </button>
                                                        <button onClick={() => this.setBody('Хэтчбек')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Хэтчбек</p>
                                                        </button>
                                                        <button onClick={() => this.setBody('Универсал')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Универсал</p>
                                                        </button>
                                                        <button onClick={() => this.setBody('Купе')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Купе</p>
                                                        </button>
                                                        <button onClick={() => this.setBody('Кабриолет')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Кабриолет</p>
                                                        </button>
                                                        <button onClick={() => this.setBody('Кроссовер')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Кроссовер</p>
                                                        </button>
                                                    </div>
                                                ) }
                                            </div>
                                            <div className='mt-3 mobile:w-full lg:w-auto'>
                                                <p className='ml-6 text-lg'>Руль</p>
                                                <div className={ this.state.isOpenWheel ? 'bg-[#FAFAFA] rounded-t-lg mobile:w-full lg:w-60 p-3 px-6 flex flex-row items-center justify-between border-b-[1px] border-b-black/35' : 'bg-[#FAFAFA] rounded-lg mobile:w-full lg:w-60 p-3 px-6 flex flex-row items-center justify-between' }>
                                                    <p className="text-black/35 text-xl w-full">{ this.state.wheel ? this.state.wheel : 'Выберите руль' }</p>
                                                    <button onClick={() => this.setState({ isOpenWheel: !this.state.isOpenWheel })}>
                                                        { this.state.isOpenWheel ? (
                                                            <IoIosArrowUp size={32} className='text-black/35 hover:opacity-50'/>
                                                        ) : (
                                                            <IoIosArrowDown size={32} className='text-black/35 hover:opacity-50'/>
                                                        ) }
                                                    </button>
                                                </div>
                                                { this.state.isOpenWheel && (
                                                    <div className='w-full rounded-b-lg bg-[#FAFAFA] p-3 space-y-3 flex flex-col items-start'>
                                                        <button onClick={() => this.setWheel('Левый')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Левый</p>
                                                        </button>
                                                        <button onClick={() => this.setWheel('Правый')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Правый</p>
                                                        </button>
                                                    </div>
                                                ) }
                                            </div>
                                        </div>
                                        <div className='flex mobile:flex-col lg:flex-row justify-between'>
                                            <div className='mt-3 mobile:w-full lg:w-auto'>
                                                <p className='ml-6 text-lg'>Пробег</p>
                                                <div className='bg-[#FAFAFA] mobile:w-full lg:w-60 rounded-lg p-3 px-6'>
                                                    <input value={this.state.mileage} onChange={this.handleMileageChange} className="outline-none bg-transparent text-xl w-full" placeholder='Введите пробег'/>
                                                </div>
                                            </div>
                                            <div className='mt-3 mobile:w-full lg:w-auto'>
                                                <p className='ml-6 text-lg'>Коробка</p>
                                                <div className={ this.state.isOpenTransmission ? 'bg-[#FAFAFA] rounded-t-lg mobile:w-full lg:w-60 p-3 px-6 flex flex-row items-center justify-between border-b-[1px] border-b-black/35' : 'bg-[#FAFAFA] rounded-lg mobile:w-full lg:w-60 p-3 px-6 flex flex-row items-center justify-between' }>
                                                    <p className="text-black/35 text-xl w-full">{ this.state.transmission ? this.state.transmission : 'Выберите ответ' }</p>
                                                    <button onClick={() => this.setState({ isOpenTransmission: !this.state.isOpenTransmission })}>
                                                        { this.state.isOpenTransmission ? (
                                                            <IoIosArrowUp size={32} className='text-black/35 hover:opacity-50'/>
                                                        ) : (
                                                            <IoIosArrowDown size={32} className='text-black/35 hover:opacity-50'/>
                                                        ) }
                                                    </button>
                                                </div>
                                                { this.state.isOpenTransmission && (
                                                    <div className='w-full rounded-b-lg bg-[#FAFAFA] p-3 space-y-3 flex flex-col items-start'>
                                                        <button onClick={() => this.setTransmission('Автомат')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Автомат</p>
                                                        </button>
                                                        <button onClick={() => this.setTransmission('Гибрид')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Гибрид</p>
                                                        </button>
                                                        <button onClick={() => this.setTransmission('Робот')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Робот</p>
                                                        </button>
                                                        <button onClick={() => this.setTransmission('Механика')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Механика</p>
                                                        </button>
                                                        <button onClick={() => this.setTransmission('Вариатор')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Вариатор</p>
                                                        </button>
                                                    </div>
                                                ) }
                                            </div>
                                        </div>
                                        <div className='flex mobile:flex-col lg:flex-row justify-between'>
                                            <div className='mt-3 mobile:w-full lg:w-auto'>
                                                <p className='ml-6 text-lg'>Состояние</p>
                                                <div className={ this.state.isStateOpen ? 'bg-[#FAFAFA] rounded-t-lg mobile:w-full lg:w-60 p-3 px-6 flex flex-row items-center justify-between border-b-[1px] border-b-black/35' : 'bg-[#FAFAFA] rounded-lg mobile:w-full lg:w-60 p-3 px-6 flex flex-row items-center justify-between' }>
                                                    <p className="text-black/35 text-xl w-full">{ this.state.stateCar ? this.state.stateCar : 'Выберите ответ' }</p>
                                                    <button onClick={() => this.setState({ isStateOpen: !this.state.isStateOpen })}>
                                                        { this.state.isStateOpen ? (
                                                            <IoIosArrowUp size={32} className='text-black/35 hover:opacity-50'/>
                                                        ) : (
                                                            <IoIosArrowDown size={32} className='text-black/35 hover:opacity-50'/>
                                                        ) }
                                                    </button>
                                                </div>
                                                { this.state.isStateOpen && (
                                                    <div className='w-full rounded-b-lg bg-[#FAFAFA] p-3 space-y-3 flex flex-col items-start'>
                                                        <button onClick={() => this.setStateCar('Отличное')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Отличное</p>
                                                        </button>
                                                        <button onClick={() => this.setStateCar('Б/У')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Б/У</p>
                                                        </button>
                                                        <button onClick={() => this.setStateCar('Требует ремонт')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Требует ремонт</p>
                                                        </button>
                                                        <button onClick={() => this.setStateCar('Побитое')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Побитое</p>
                                                        </button>
                                                    </div>
                                                ) }
                                            </div>
                                            <div className='mt-3 mobile:w-full lg:w-auto'>
                                                <p className='ml-6 text-lg'>Растаможен</p>
                                                <div className={ this.state.isClearedOpen ? 'bg-[#FAFAFA] rounded-t-lg mobile:w-full lg:w-60 p-3 px-6 flex flex-row items-center justify-between border-b-[1px] border-b-black/35' : 'bg-[#FAFAFA] rounded-lg mobile:w-full lg:w-60 p-3 px-6 flex flex-row items-center justify-between' }>
                                                    <p className="text-black/35 text-xl w-full">{ this.state.isCleared ? this.state.isCleared : 'Да | Нет' }</p>
                                                    <button onClick={() => this.setState({ isClearedOpen: !this.state.isClearedOpen })}>
                                                        { this.state.isClearedOpen ? (
                                                            <IoIosArrowUp size={32} className='text-black/35 hover:opacity-50'/>
                                                        ) : (
                                                            <IoIosArrowDown size={32} className='text-black/35 hover:opacity-50'/>
                                                        ) }
                                                    </button>
                                                </div>
                                                { this.state.isClearedOpen && (
                                                    <div className='w-full rounded-b-lg bg-[#FAFAFA] p-3 space-y-3 flex flex-col items-start'>
                                                        <button onClick={() => this.setCleared('Да')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Да</p>
                                                        </button>
                                                        <button onClick={() => this.setCleared('Нет')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Нет</p>
                                                        </button>
                                                    </div>
                                                ) }
                                            </div>
                                        </div>
                                        <div className='mt-3'>
                                            <p className='ml-6 text-lg'>Дополнительно</p>
                                            <div className='bg-[#FAFAFA] w-full rounded-lg p-3 px-6 h-64'>
                                                <textarea value={this.state.additionalInfo} onChange={this.handleAdditionalInfoChange} multiple={true} className="outline-none h-56 bg-transparent text-xl w-full" placeholder='Введите дополнительные сведения'/>
                                            </div>
                                        </div>
                                    </>
                                ) }
                                { (this.state.category === 'Услуги' || this.state.category === 'Оборудование') && (
                                    <>
                                        <div className='flex mobile:flex-col lg:flex-row items-center justify-between w-sreen'>
                                            <div className='mt-3 mobile:w-full lg:w-auto'>
                                                <div className='w-full'>
                                                    <p className='ml-6 text-lg'>Город</p>
                                                    <div className={ this.state.isOpenCategory ? 'bg-[#FAFAFA] rounded-t-lg mobile:w-full lg:w-60 p-3 px-6 flex flex-row items-center justify-between border-b-[1px] border-b-black/35' : 'bg-[#FAFAFA] rounded-lg mobile:w-full lg:w-60 p-3 px-6 flex flex-row items-center justify-between' }>
                                                        <p className="text-black/35 text-xl w-full">{ this.state.city ? this.state.city : 'Город' }</p>
                                                        <button onClick={() => this.setState({ isOpenCategory: !this.state.isOpenCategory })}>
                                                            { this.state.isOpenCategory ? (
                                                                <IoIosArrowUp size={32} className='text-black/35 hover:opacity-50'/>
                                                            ) : (
                                                                <IoIosArrowDown size={32} className='text-black/35 hover:opacity-50'/>
                                                            ) }
                                                        </button>
                                                    </div>
                                                </div>
                                                { this.state.isOpenCategory && (
                                                    <div className='w-full rounded-b-lg bg-[#FAFAFA] p-3 space-y-3 flex flex-col items-start'>
                                                        <button onClick={() => this.setCity('Алматы')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Алматы</p>
                                                        </button>
                                                        <button onClick={() => this.setCity('Астана')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Астана</p>
                                                        </button>
                                                        <button onClick={() => this.setCity('Шымкент')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Шымкент</p>
                                                        </button>
                                                        <button onClick={() => this.setCity('Караганда')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Караганда</p>
                                                        </button>
                                                        <button onClick={() => this.setCity('Актобе')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Актобе</p>
                                                        </button>
                                                        <button onClick={() => this.setCity('Тараз')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Тараз</p>
                                                        </button>
                                                        <button onClick={() => this.setCity('Павлодар')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Павлодар</p>
                                                        </button>
                                                        <button onClick={() => this.setCity('Усть-Каменогорск')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Усть-Каменогорск</p>
                                                        </button>
                                                        <button onClick={() => this.setCity('Семей')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Семей</p>
                                                        </button>
                                                    </div>
                                                ) }
                                            </div>
                                            <div className='mt-3 mobile:w-full lg:w-auto'>
                                                <div className='w-full'>
                                                    <p className='ml-6 text-lg'>Область применения</p>
                                                    <div className={ this.state.isOpenApplicationArea ? 'bg-[#FAFAFA] rounded-t-lg mobile:w-full lg:w-60 p-3 px-6 flex flex-row items-center justify-between border-b-[1px] border-b-black/35' : 'bg-[#FAFAFA] rounded-lg mobile:w-full lg:w-60 p-3 px-6 flex flex-row items-center justify-between' }>
                                                        <p className="text-black/35 text-xl w-full">{ this.state.applicationArea ? this.state.applicationArea : 'Область' }</p>
                                                        <button onClick={() => this.setState({ isOpenApplicationArea: !this.state.isOpenApplicationArea })}>
                                                            { this.state.isOpenApplicationArea ? (
                                                                <IoIosArrowUp size={32} className='text-black/35 hover:opacity-50'/>
                                                            ) : (
                                                                <IoIosArrowDown size={32} className='text-black/35 hover:opacity-50'/>
                                                            ) }
                                                        </button>
                                                    </div>
                                                </div>
                                                { this.state.isOpenApplicationArea && (
                                                    <div className='w-full rounded-b-lg bg-[#FAFAFA] p-3 space-y-3 flex flex-col items-start'>
                                                        <button onClick={() => {this.setApplicationArea('Машины'); this.setState({ isOpenApplicationArea: !this.state.isOpenApplicationArea })}}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Машины</p>
                                                        </button>
                                                        <button onClick={() => {this.setApplicationArea('Грузовые'); this.setState({ isOpenApplicationArea: !this.state.isOpenApplicationArea })}}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Грузовые</p>
                                                        </button>
                                                        <button onClick={() => {this.setApplicationArea('Водный'); this.setState({ isOpenApplicationArea: !this.state.isOpenApplicationArea })}}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Водный</p>
                                                        </button>
                                                    </div>
                                                ) }
                                            </div>
                                        </div>
                                        { this.state.category === 'Услуги' && (
                                            <div className='mt-3 w-full'>
                                                <div className='w-full'>
                                                    <p className='ml-6 text-lg'>Квалификация</p>
                                                    <div className={ this.state.isOpenQualification ? 'bg-[#FAFAFA] rounded-t-lg w-full p-3 px-6 flex flex-row items-center justify-between border-b-[1px] border-b-black/35' : 'bg-[#FAFAFA] rounded-lg w-full p-3 px-6 flex flex-row items-center justify-between' }>
                                                        <p className="text-black/35 text-xl w-full">{ this.state.qualification ? this.state.qualification : 'Квалификация' }</p>
                                                        <button onClick={() => this.setState({ isOpenQualification: !this.state.isOpenQualification })}>
                                                            { this.state.isOpenQualification ? (
                                                                <IoIosArrowUp size={32} className='text-black/35 hover:opacity-50'/>
                                                            ) : (
                                                                <IoIosArrowDown size={32} className='text-black/35 hover:opacity-50'/>
                                                            ) }
                                                        </button>
                                                    </div>
                                                </div>
                                                { this.state.isOpenQualification && (
                                                    <div className='w-full rounded-b-lg bg-[#FAFAFA] p-3 space-y-3 flex flex-col items-start'>
                                                        <button onClick={() => this.setQualification('Низкая квалификация')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Низкая квалификация</p>
                                                        </button>
                                                        <button onClick={() => this.setQualification('Средняя квалификация')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Средняя квалификация</p>
                                                        </button>
                                                        <button onClick={() => this.setQualification('Высокая квалификация')}>
                                                            <p className="text-black/35 text-xl w-full hover:opacity-50">Высокая квалификация</p>
                                                        </button>
                                                    </div>
                                                ) }
                                            </div>
                                        ) }
                                        { this.state.category === 'Оборудование' && (
                                            <>
                                                <div className='mt-3 w-full'>
                                                    <p className='ml-6 text-lg'>Вес</p>
                                                    <div className='bg-[#FAFAFA] w-full rounded-lg p-3 px-6'>
                                                        <input value={this.state.weight} onChange={this.handleWeightChange} className="outline-none bg-transparent text-xl w-full" placeholder='Введите вес'/>
                                                    </div>
                                                </div>
                                                <div className='mt-3 w-full'>
                                                    <div className='w-full'>
                                                        <p className='ml-6 text-lg'>Компактность</p>
                                                        <div className={ this.state.isOpenCompactness ? 'bg-[#FAFAFA] rounded-t-lg w-full p-3 px-6 flex flex-row items-center justify-between border-b-[1px] border-b-black/35' : 'bg-[#FAFAFA] rounded-lg w-full p-3 px-6 flex flex-row items-center justify-between' }>
                                                            <p className="text-black/35 text-xl w-full">{ this.state.compactness ? this.state.compactness : 'Компактность' }</p>
                                                            <button onClick={() => this.setState({ isOpenCompactness: !this.state.isOpenCompactness })}>
                                                                { this.state.isOpenCompactness ? (
                                                                    <IoIosArrowUp size={32} className='text-black/35 hover:opacity-50'/>
                                                                ) : (
                                                                    <IoIosArrowDown size={32} className='text-black/35 hover:opacity-50'/>
                                                                ) }
                                                            </button>
                                                        </div>
                                                    </div>
                                                    { this.state.isOpenCompactness && (
                                                        <div className='w-full rounded-b-lg bg-[#FAFAFA] p-3 space-y-3 flex flex-col items-start'>
                                                            <button onClick={() => this.setCompactness('Да')}>
                                                                <p className="text-black/35 text-xl w-full hover:opacity-50">Да</p>
                                                            </button>
                                                            <button onClick={() => this.setCompactness('Нет')}>
                                                                <p className="text-black/35 text-xl w-full hover:opacity-50">Нет</p>
                                                            </button>
                                                        </div>
                                                    ) }
                                                </div>
                                            </>
                                        ) }
                                        <div className='mt-3'>
                                            <p className='ml-6 text-lg'>Дополнительно</p>
                                            <div className='bg-[#FAFAFA] w-full rounded-lg p-3 px-6 h-64'>
                                                <textarea value={this.state.additionalInfo} onChange={this.handleAdditionalInfoChange} multiple={true} className="outline-none h-56 bg-transparent text-xl w-full" placeholder='Введите дополнительные сведения'/>
                                            </div>
                                        </div>
                                    </>
                                ) }
                                { this.state.isTwoStepError && <p className='text-xl text-red-500 text-center mt-4'>Поле Город обязательно к заполнению</p> }
                                <button onClick={(this.state.category === 'Услуги' || this.state.category === 'Оборудование') ? this.createAdToolService : this.createAd} className='w-full p-2 justify-center items-center bg-[#4D89E3] rounded-lg mt-6 group hover:opacity-50'>
                                    <p className='text-xl text-white group-hover:opacity-50'>Опубликовать</p>
                                </button>
                            </>
                        ) }
                    </div>
                </div>
            </div>
        )
    }
};

export default WriteAdModal;
