import React, { Component } from 'react';
import logotype from '../../img/logotype.png'
import AutorizationModal from './AutorizationModal';
import { Link } from 'react-router-dom';
import { FaRegHeart } from "react-icons/fa";
import { FiSend } from "react-icons/fi";
import { LuUser2 } from "react-icons/lu";
import WriteAdModal from './WriteAdModal';
import Profile from './Profile';
import { IoMdMenu } from "react-icons/io";
import RightMenu from './RightMenu';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuthModalOpen: false,
            isWriteAdModalOpen: false,
            isShowProfilePopup: false,
            isShowProfileModal: false,
            isShowRightMenu: false,
            isAuthenticated: false,
            selectedProfileTab: '',
            user: {}
        }
        this.toggleOpenAuthModal = this.toggleOpenAuthModal.bind(this);
        this.toggleOpenWriteAdModal = this.toggleOpenWriteAdModal.bind(this);
        this.toggleShowProfile = this.toggleShowProfile.bind(this);
        this.toggleShowProfileModal = this.toggleShowProfileModal.bind(this);
        this.toggleOpenRightMenu = this.toggleOpenRightMenu.bind(this);
        this.exit = this.exit.bind(this);
    }

    toggleShowProfile() {
        this.setState({ isShowProfilePopup: !this.state.isShowProfilePopup });
    }

    toggleShowProfileModal = () => {
        this.setState({ isShowProfileModal: !this.state.isShowProfileModal, isShowProfilePopup: false, isShowRightMenu: false });
    }

    toggleOpenAuthModal = () => {
        this.setState({ isAuthModalOpen: !this.state.isAuthModalOpen });
    }

    toggleOpenWriteAdModal = () => {
        this.setState({ isWriteAdModalOpen: !this.state.isWriteAdModalOpen, isShowRightMenu: false, isShowProfileModal: false });
    }

    toggleOpenRightMenu = () => {
        this.setState({ isShowRightMenu: !this.state.isShowRightMenu });
    }

    openTabProfile = (tab) => {
        this.toggleShowProfileModal();
        this.setState({ selectedProfileTab: tab });
    }

    componentDidMount() {
        // Получаем значение токена из cookie
        const authToken = document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*=\s*([^;]*).*$)|^.*$/, "$1");
        
        // Проверяем наличие токена
        if (authToken) {
            this.setState({ isAuthenticated: true }); // Устанавливаем флаг аутентификации в true
        }

        // Отправляем запрос на сервер для получения информации о пользователе
        fetch(`https://avto-pro.kz/api-getUserInfo?token=${authToken}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                this.setState({ user: data.user[0] });
            } else {

            }
        })
        .catch(error => {
        });
    }

    exit = async () => {
        document.cookie.split(";").forEach(cookie => {
            const [name] = cookie.split("=");
            if (name.trim() === "auth_token") {
                document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            }
        });
        
        window.location.reload();
    };

    render() {
        return (
            <div className="w-screen p-6 z-50 flex flex-row fixed bg-white mobile:px-10 lg:px-44 justify-between">
                <div className='flex flex-row'>
                    <img src={logotype}/>
                    <div className='flex flex-row space-x-6 mobile:hidden xl:flex mobile:ml-10 2xl:ml-16'>
                        <Link to='/cars/ads?city=Все&page=1'>
                            <p className='text-xl hover:opacity-50'>Главная</p>
                        </Link>
                        <Link to='/news'>
                            <p className='text-xl hover:opacity-50'>Акции и Объявления</p>
                        </Link>
                    </div>
                </div>
                {this.state.isAuthenticated ? ( // Если пользователь аутентифицирован
                    <div className='flex flex-row items-center'>
                        <button onClick={() => this.toggleOpenWriteAdModal()} className="py-1.5 px-3.5 group bg-[#4D89E3] rounded-xl  hover:bg-[#90B5EE] mobile:hidden 2xl:block">
                            <p className='text-white opacity-100 group-hover:opacity-50 text-xl'>Создать объявление</p>
                        </button>
                        <div className='mobile:hidden lg:flex flex-row space-x-4 items-center ml-6'>
                            <button onClick={() => this.openTabProfile('Избранное')}>
                                <FaRegHeart size={28} className='text-black/50 hover:opacity-50'/>
                            </button>
                            <Link to='/chats'>
                                <FiSend size={28} className='text-black/50 hover:opacity-50'/>
                            </Link>
                            <button onClick={() => this.toggleShowProfile()}>
                                <LuUser2 size={28} className={ this.state.isShowProfilePopup ? 'text-[#6398E7] hover:opacity-50' : 'text-black/50 hover:opacity-50'}/>
                            </button>
                            { this.state.isShowProfilePopup && (
                                <div className='absolute z-50 bg-white rounded-lg drop-shadow-2xl border-2 p-4 top-16 right-36 flex flex-col items-start'>
                                    <div className='flex flex-row items-center space-x-2'>
                                        <img src={`https://avto-pro.kz/api-userImage/${this.state.user.photo}`} className='rounded-full w-10 h-10'/>
                                        <p className='text-xl font-medium'>{this.state.user.fullname} {this.state.user.lastname}</p>
                                    </div>
                                    <button className='hover:opacity-50' onClick={() => this.openTabProfile('')}>
                                        <p className='text-lg font-semibold hover:opacity-50 py-1'>Профиль</p>
                                    </button>
                                    <button className='hover:opacity-50' onClick={() => this.openTabProfile('История')}>
                                        <p className='text-lg font-semibold hover:opacity-50 py-1'>История</p>
                                    </button>
                                    <button className='hover:opacity-50' onClick={() => this.openTabProfile('Объявления')}>
                                        <p className='text-lg font-semibold hover:opacity-50 py-1'>Мои объявление</p>
                                    </button>
                                    <button className='hover:opacity-50' onClick={() => this.openTabProfile('Настройки')}>
                                        <p className='text-lg font-semibold hover:opacity-50 py-1'>Настройки</p>
                                    </button>
                                    <button onClick={this.exit} className='hover:opacity-50'>
                                        <p className='text-lg text-red-500 font-semibold hover:opacity-50 py-1'>Выйти</p>
                                    </button>
                                </div>
                            ) }
                        </div>
                        <button className='mobile:visible lg:hidden' onClick={() => this.toggleOpenRightMenu()}>
                            <IoMdMenu className='text-4xl text-black/50 hover:text-[#4D89E3]'/>
                        </button>
                    </div>
                ) : ( // Если пользователь не аутентифицирован
                    <div>
                        <button onClick={() => this.toggleOpenAuthModal()} className="py-1.5 px-3.5 group hover:bg-[#4D89E3] rounded-xl  bg-[#90B5EE]">
                            <p className='text-white group-hover:opacity-100 opacity-75 text-xl'>Войти</p>
                        </button>
                    </div>
                )}
                { this.state.isAuthModalOpen && <AutorizationModal onClose={this.toggleOpenAuthModal}/> }
                { this.state.isWriteAdModalOpen && <WriteAdModal onClose={this.toggleOpenWriteAdModal}/> }
                { this.state.isShowProfileModal && <Profile activeTab={this.state.selectedProfileTab} onOpenWriteAd={this.toggleOpenWriteAdModal} onClose={this.toggleShowProfileModal}/> }
                { this.state.isShowRightMenu && <RightMenu onClose={this.toggleOpenRightMenu} user={this.state.user} profile={this.toggleShowProfileModal} ad={this.toggleOpenWriteAdModal}/> }
            </div>
        )
    }
};

export default Navbar;