import React, { Component, useState } from 'react';
import VerificationInput from "react-verification-input";
import '../../App.css'

function CodeConfrim({ onToggleMethod, recoveryMethod, recoveryValue }) {
    const [code, onChangeCode] = useState();
    const [isError, setIsError] = useState(false);

    const sendCode = async () => {
        try {
            const response = await fetch('https://avto-pro.kz/api-verifyRecoveryCode', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ recoveryCode: code,  })
            });
            const data = await response.json();
            if (data.success) {
                setIsError(false);
                onToggleMethod('PasswordConfrim')
            } else {
                if (data.message === 'Неверный код восстановления') {
                    setIsError(true);
                } else {
                    setIsError(false)
                }
            }
        } catch (error) {
            console.error('Error sending code:', error);
        }
    }

    return (
        <div>
            <p className='text-2xl font-semibold'>Восстановить пароль</p>
            <p className='text-xl mt-1'>Ваш код выслан на { recoveryMethod === 'email' ? 'почту' : 'номер телефона' }: <span className='font-semibold'>{recoveryValue}</span></p>
            <div className='w-full flex flex-row justify-center space-x-4 mt-4'>
            <VerificationInput
                validChars="A-Za-z0-9А-Яа-я"
                length={6}
                onChange={(text) => onChangeCode(text)}
            />
            </div>
            <button onClick={() => sendCode()} className='w-full p-2 mt-6 justify-center items-center flex bg-[#4D89E3] rounded-lg group hover:opacity-50'>
                <p className='text-white text-xl group-hover:opacity-50'>Отправить код</p>
            </button>
            <button onClick={() => onToggleMethod('ChangePassword')}>
                <p className="text-xl mt-4 text-[#4D89E3] hover:opacity-50">Изменить способ</p>
            </button>
            { isError && <p className='text-xl text-red-400 text-center mt-6'>Неправильный код. Повторите попытку</p> }
        </div>
    )
};

export default CodeConfrim;