import React, { Component, useEffect, useState } from 'react';
import { IoArrowBack } from "react-icons/io5";
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import { FaHeart } from "react-icons/fa";

function MyFavourite() {
    const [data, setData] = useState([]);
    const [user, setUser] = useState({});

    useEffect(() => {
        fetchUserInfo();
    }, []);

    const fetchUserInfo = async () => {
        try {
            const authToken = document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*=\s*([^;]*).*$)|^.*$/, "$1");
    
            // Отправляем запрос на сервер для получения информации о пользователе
            const response = await fetch(`https://avto-pro.kz/api-getUserInfo?token=${authToken}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
    
            if (!response.ok) {
                throw new Error('Ошибка при получении данных о пользователе');
            }
    
            const data = await response.json();
            setUser(data.user[0]);
            await fetchData(data.user[0].id)
            
            // Обработка полученных данных
        } catch (error) {
            console.error('Произошла ошибка:', error);
        }
    }

    const fetchData = async (id) => {
        fetch(`https://avto-pro.kz/api-getFavourite/${id}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка сети');
                }
                return response.json();
            })
                .then(data => {
                setData(data);
            })
                .catch(error => {
            });
    }

    const remove = async (id) => {
        try {
            const response = await fetch(`https://avto-pro.kz/api-removeFavourite/${id}/${user.id}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                const data = await response.json();
                window.location.reload();
            } else {
                const errorMessage = await response.text();
            }
        } catch (error) {
        }
    }
    
    return (
        <div className='p-4 w-screen mt-20'>
            <p className='text-2xl text-nowrap font-medium'>Избранное</p>
            <div className='space-y-3 overflow-x-hidden h-screen'>
                {data.cars && data.cars.length !== 0 ? (
                    data.cars.map((car, index) => (
                        <div className='space-y-3 overflow-x-hidden h-[400px]'>
                            <div className='w-full shadow-xl bg-white drop-shadow-xl p-3 rounded-xl'>
                                <div className='mt-2'>
                                    <div className='flex flex-row'>
                                        <div className='space-x-2 flex flex-row'>
                                            <img src={`https://avto-pro.kz/api-carImage/${car.imagePreview1}`} className='bg-[#BDBDBD] w-48 h-40 rounded-xl object-cover'/>
                                        </div>
                                        <div className='ml-4'>
                                            <div className='flex flex-row items-center justify-between'>
                                                <Link to={car.url}>
                                                    <p className='text-xl hover:opacity-50'>{car.title}</p>
                                                </Link>
                                                <button onClick={() => remove(car.id)}>
                                                    <FaHeart className='text-xl text-[#4D89E3] hover:opacity-50'/>
                                                </button>
                                            </div>
                                            <p className='text-lg mt-1'>{car.price}тг/день</p>
                                            <p className='text-md mt-1'>Номер: {car.contactPhone}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className='w-full h-full justify-center items-center flex'>
                        <p>Нет объявлений в данном списке</p>
                    </div>
                )}
            </div>
        </div>
    )
    
}

export default MyFavourite;