import { IoMenuOutline } from "react-icons/io5";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Link } from "react-router-dom";

function NavbarAdmin({ setTab, tab }) {
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const location = useLocation();
    const queryParams = queryString.parse(location.search);

    return (
        <div>
            <div className="w-screen fixed flex flex-row items-center justify-between p-4">
                <p className="text-xl font-bold text-[#6398E7]">Admin</p>
                <button onClick={() => setIsOpenMenu(!isOpenMenu)}>
                    <IoMenuOutline className="text-4xl text-[#6398E7] hover:opacity-50"/>
                </button>
            </div>
            { isOpenMenu && (
                <div className="fixed inset-0 flex z-50">
                    <div
                        className="fixed inset-0 opacity-50"
                        onClick={() => setIsOpenMenu(false)}
                    />
                    <div className={`fixed top-0 right-0 h-full w-64 bg-white z-50 transition-transform duration-300 shadow-2xl transform ${isOpenMenu ? "translate-x-0" : "translate-x-full"}`}>
                        <div className="p-4 flex flex-col items-start">
                            <Link to='/admin-panel?tab=ads' className={ queryParams.tab === 'ads' ? "text-xl m-2 text-[#6398E7] font-semibold" : "text-xl m-2 text-[#6398E7] font-semibold opacity-50 hover:opacity-100"}>
                                <p>Объявления</p>
                            </Link>
                            <Link to='/admin-panel?tab=users' className={ queryParams.tab === 'users' ? "text-xl m-2 text-[#6398E7] font-semibold" : "text-xl m-2 text-[#6398E7] font-semibold opacity-50 hover:opacity-100"}>
                                <p>Пользователи</p>
                            </Link>
                            <Link to='/admin-panel?tab=writeAd' className={ queryParams.tab === 'writeAd' ? "text-xl m-2 text-[#6398E7] font-semibold" : "text-xl m-2 text-[#6398E7] font-semibold opacity-50 hover:opacity-100"}>
                                <p>Написать объявление</p>
                            </Link>
                            <Link to='/admin-panel?tab=tariffs' className={ queryParams.tab === 'tariffs' ? "text-xl m-2 text-[#6398E7] font-semibold" : "text-xl m-2 text-[#6398E7] font-semibold opacity-50 hover:opacity-100"}>
                                <p>Тарифы</p>
                            </Link>
                        </div>
                    </div>
                </div>
            ) }
        </div>
    )
};

export default NavbarAdmin;