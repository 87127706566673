import React, { useState, useEffect } from "react";
import { FaGripfire } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Card({ car, selectCategories, page, category }) {
    const [user, setUser] = useState([]);
    const navigation = useNavigate();

    useEffect(() => {
        fetchUserInfo();
    }, []);

    const fetchUserInfo = async () => {
        try {
            const authToken = document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*=\s*([^;]*).*$)|^.*$/, "$1");
            const response = await fetch(`https://avto-pro.kz/api-getUserInfo?token=${authToken}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (!response.ok) {
                throw new Error('Ошибка при получении данных о пользователе');
            } else {
                const data = await response.json();
                setUser(data.user[0]);
            }
        } catch (error) {
            console.error('Произошла ошибка:', error);
        }
    };

    const buy = async () => {
        if (user) {
            navigation(`/dialog/${car.userId}`)
        } else {
            alert('Только зарегистрированный пользователь может написать');
        }
    }

    return (
        <div className="shadow-xl bg-white rounded-xl h-80 w-80 mobile:ml-0 sm:ml-6 mt-6">
            <div className="w-full h-44">
                <img loading='eager' src={`https://avto-pro.kz/api-carImage/${car.photoPreview1}`} className="bg-[#BDBDBD] w-full h-full rounded-xl object-cover"/>
                { car.isTop ? (
                <div className="flex flex-row justify-end items-end -mt-44 p-4">             
                    <div className='w-8 h-8 rounded-full bg-white justify-center items-center flex'>
                        <FaGripfire color='#FF3F3F' size={24}/>
                    </div>
                </div>
                ) : <></> }
            </div>
            <div className='p-4 px-6 mt-[px]'>
                <div>
                    <div>
                        <div className='flex flex-row justify-between'>
                            <p className='text-xl font-medium'>
                                {car.title.length > 7 ? `${car.title.substring(0, 7)}...` : car.title}
                            </p>
                            <p className='text-xl font-medium'>{car.price} тг/день</p>
                        </div>
                        { (selectCategories === 'services' || selectCategories === 'tools') && (
                            <div className='flex flex-row space-x-3'>
                                <p className='text-lg opacity-50'>{car.description.length > 25 ? `${car.description.substring(0, 25)}...` : car.description}</p>
                            </div>
                        )}
                        { (category === 'services' || category === 'tools') && (
                            <div className='flex flex-row space-x-3'>
                                <p className='text-lg opacity-50'>{car.description.length > 25 ? `${car.description.substring(0, 25)}...` : car.description}</p>
                            </div>
                        )}
                        { (selectCategories === 'cars' || selectCategories === 'trucks' || selectCategories === 'water') && (
                            <>
                                {(category !== 'tools' && category !== 'services') && (
                                    <div className='flex flex-row space-x-3'>
                                        <p className='text-lg opacity-50'>{car.yearOfIssue}г.</p>
                                        <p className='text-lg opacity-50'>{car.engine}</p>
                                        <p className='text-lg opacity-50'>{car.transmission}</p>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div className='flex flex-row justify-between mt-2'>
                    <Link to={`/${selectCategories}/?id=${car.id}/&sub=${category}&page=${page}`} className='border-2 px-4 py-2.5 border-black/50 justify-center items-center flex rounded-xl group hover:border-black'>
                        <p className='text-black/50 group-hover:text-black text-xl'>Подробнее</p>
                    </Link>
                    <button onClick={buy} className='px-3.5 py-2.5 justify-center items-center flex bg-[#4D89E3] rounded-xl group hover:opacity-50'>
                        <p className='text-white text-xl group-hover:opacity-50'>Аренда</p>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Card;


// to={`/dialog/${car.userId}`}
// state={{ category: category, page: page }}
// to={`/ad/${selectCategories}/${car.id}`} 