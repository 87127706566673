import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { IoIosArrowBack } from "react-icons/io";

function ChatsList() {
    const [chats, setChats] = useState([]);
    const [deletePopUpState, setDeletePopUpState] = useState();
    const navigate = useNavigate();
    const [userData, setUserData] = useState({});
    

    useEffect(() => {
        fetchUserInfo();
    });

    const fetchUserInfo = async () => {
        try {
            const authToken = document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*=\s*([^;]*).*$)|^.*$/, "$1");
    
            // Отправляем запрос на сервер для получения информации о пользователе
            const response = await fetch(`https://avto-pro.kz/api-getUserInfo?token=${authToken}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
    
            if (!response.ok) {
                throw new Error('Ошибка при получении данных о пользователе');
            }
    
            const data = await response.json();
            await fetchChats(data.user[0].id);
            // Обработка полученных данных
        } catch (error) {
            console.error('Произошла ошибка:', error);
        }
    };
    

    const fetchChats = async (id) => {
        try {
            const response = await fetch("https://avto-pro.kz/api-getAllChats", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ user_id: id })
            });

            const data = await response.json();
            if (data.success) {
                setChats(data.chats);
            }
        } catch (error) {
            console.error("Ошибка:", error);
        }
    };

    const toggleDeletePopUp = (chatId) => {
        setDeletePopUpState(chatId);
    };

    const deleteMyChat = async (chat_id) => {
        const response = await fetch("https://avto-pro.kz/api-deleteMyChat", {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ chat_id: chat_id })
        });

        if (response.status === 200) {
            // Редирект на страницу "/chats"
            navigate("/chats");
        }
    }

    const deleteAllChat = async (chat_id) => {
        const response = await fetch("https://avto-pro.kz/api-deleteAllChat", {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ chat_id: chat_id })
        });

        if (response.status === 200) {
            // Редирект на страницу "/chats"
            navigate("/chats");
        }
    }

    return (
        <div className='chats rounded-tl-2xl rounded-bl-2x mobile:w-full laptop:w-[30vw] desktop2:w-[20vw] h-full overflow-x-auto pr-3'>
            { chats.length > 0 && (
                <>
                    {chats.map(chat => (
                        <div key={chat.chat_id} className="chat-item flex flex-row items-center">
                            <Link state={{ user_id: chat.user_id }} to={`/chats/dialog/${chat.chat_id}`} className='w-full items-center flex flex-row p-4 space-x-2 hover:opacity-50'>
                                <img src={`https://avto-pro.kz/api-userImage/${chat.photo}`} className='text bg-[#BDBDBD] w-14 h-14 rounded-full flex-shrink-0 overflow-hidden'/>
                                <div className='w-full flex flex-col items-start justify-start'>
                                    <div className='flex flex-row justify-between w-full items-center'>
                                        <p className='text-lg text-[#0D0D0D] font-medium'>{chat.fullname} {chat.surname}</p>
                                        <p className='text-[#0D0D0D]/50 text-lg'>{chat.date}</p>
                                    </div>
                                    <p className='text-md text-[#0D0D0D]/70'>{chat.lastMessage}</p>
                                </div>
                            </Link>
                            <div>
                                <div className="relative chat-options flex flex-row justify-center items-center">
                                    <button onClick={() => toggleDeletePopUp(chat.chat_id)} className="hover:opacity-50 items-center justify-center flex">
                                        <BiDotsVerticalRounded className="text-[#0D0D0D]/50 text-2xl"/>
                                    </button>
                                    { deletePopUpState === chat.chat_id && (
                                        <div className="absolute flex flex-row items-center border-[1px] border-black/25 p-2 px-3 z-10 bg-[#FFF] flex-shrink-0 rounded-2xl -ml-40">
                                            
                                            <div className="flex flex-col items-end border-r-[1px] border-black/25 pr-2">
                                                <button onClick={() => deleteMyChat(chat.chat_id)} className="hover:opacity-0">
                                                    <p className="text-[#FF0000] text-nowrap">Удалить у меня</p>
                                                </button>
                                                <button onClick={() => deleteAllChat(chat.chat_id)} className="hover:opacity-0">
                                                    <p className="text-[#FF0000] text-nowrap">Удалить у всех</p>
                                                </button>
                                            </div>
                                            <button onClick={() => setDeletePopUpState('')}  className="hover:opacity-50 rotate-180">
                                                <IoIosArrowBack className="text-[#0D0D0D]/25 text-2xl"/>
                                            </button>
                                        </div>
                                    ) }
                                </div>
                                
                            </div>
                        </div>
                    ))}
                </>
            ) }
            { chats.length === 0 && (
                <div className="w-full h-full flex justify-center items-center">
                    <p className="text-xl">Чатов нет</p>
                </div>
            ) }
        </div>
    )
};

export default ChatsList;
