import React, { Component, useState, useEffect } from "react";
import Navbar from "../ux/Navbar";
import NewsModal from "../ux/NewsModal";
import { LuEye } from "react-icons/lu";

function NewsPage() {
    const [isOpenNewModal, setIsOpenNewModal] = useState(false);
    const [data, setData] = useState([]);
    const [selectedData, setSelectedData] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        fetch(`https://avto-pro.kz/api-getSalesAndAds`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка сети');
                }
                return response.json();
            })
                .then(data => {
                setData(data.news);
            })
                .catch(error => {
            });
    }

    const fetchUpdateViews = async (id) => {
        try {
            const response = await fetch(`https://avto-pro.kz/api-updateView/${id}`);
            if (!response.ok) {
                throw new Error('Ошибка при загрузке данных');
            }
            // Если требуется обработка данных из ответа, то добавьте соответствующий код здесь
        } catch (error) {
            console.error('Ошибка:', error);
        }
    };


    return (
        <div>
            <Navbar activePage='News'/>
            <div className="mobile:px-6 sm:px-12 xl:px-24  2xl:px-52 w-screen mt-24">
                <p className="text-2xl font-medium">Акции и Объявления</p>
                <div className="w-full overflow-x-auto flex-row flex items-center justify-center flex-wrap">
                    { data.length > 0 ? (
                        <>
                            { data.map((item, index) => (
                            <div key={index} className="border-[1px] border-[#4D89E3] w-96 relative rounded-xl h-64 m-6 items-center flex justify-center">
                                <img loading='eager' src={`https://avto-pro.kz/api-newsImage/${item.photoPreview}`} className="w-full h-full rounded-xl object-cover bg-[#bdbdbd]"/>
                                <div className="mobile:w-64 lg:w-80 xl:w-80 absolute z-10 bottom-4 rounded-xl bg-white p-3 border-[1px] border-black/25">
                                    <div className="flex flex-row items-center justify-between">
                                        <p className="lg:text-xl xl:text-xl font-semibold mt-1">{item.title}</p>
                                        <div className="flex flex-row items-center lg:space-x-1.5 xl:space-x-3">
                                            <div className="flex flex-row items-center space-x-1">
                                                <p className="lg:text-md xl:text-xl text-black/50">{item.time}</p>
                                            </div>
                                            <div className="flex flex-row space-x-1 items-center">
                                                <LuEye className="lg:text-md xl:text-xl text-black/50"/>
                                                <p className="lg:text-lg xl:text-xl text-black/50">{item.views}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-xl text-black/50 font-light">{item.title} <button onClick={() => (setIsOpenNewModal(true), setSelectedData(item), fetchUpdateViews(item.id))}><p className="text-[#4D89E3] hover:opacity-50">Ещё...</p></button></p>
                                </div>
                            </div>
                        )) }
                        </>
                    ) : (
                        <p className="text-xl text-black/50 font-semibold mt-12">Пока новостей не выложено</p>
                    ) }
                    
                    
                </div>
                    
            </div>
            { isOpenNewModal && <NewsModal data={selectedData} onClose={() => setIsOpenNewModal(false)}/> }
        </div>
    )
};

export default NewsPage;