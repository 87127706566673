import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import MainPage from "./components/pages/MainPage";
import AdScreen from './components/pages/Admin/AdScreen';
import NewsPage from './components/pages/NewsPage';
import Chats from './components/pages/Chats';
import './App.css';
import './index.css'
import { Link } from 'react-router-dom';
import { IoHomeOutline } from "react-icons/io5";
import { FaRegUser , FaHistory, FaRegHeart } from "react-icons/fa";
import { GoHistory } from "react-icons/go";
import { LuFileSpreadsheet, LuUser2  } from "react-icons/lu";
import { IoSettingsOutline } from "react-icons/io5";
import { CiUser } from "react-icons/ci";
import HistoryMobile from './components/ux/HistoryMobile';
import MyAdsMobile from './components/ux/MyAdsMobile';
import SettingsMobile from './components/ux/SettingsMobile';
import { BsChatSquare } from "react-icons/bs";
import MyFavourite from './components/ux/MyFavourite';
import CarsPage from './components/pages/CarsPage';
import AuthAdmin from './components/pages/AuthAdmin';
import AdminPage from './components/pages/Admin/AdminPage';
import Footer from './components/ux/Footer';
import './CusstomScrollBar.css'
import Navbar from './components/ux/Navbar';
import DialogPage from './components/pages/DialogPage';
import Dialog from './components/pages/DialogPage';
import StartDialog from './components/ux/StartDialog';
import TestPage from './components/pages/TestPage';
import PolicyPage from './components/pages/PolicyPage';
import PublicOfferPage from './components/pages/PublicOfferPage';
import PaymentPage from './components/pages/PaymentPage';

const BottomTabs = () => {
  return (
    <nav className="fixed bottom-0 left-0 right-0 bg-white mobile:flex lg:hidden z-[10000000] justify-around border-t border-gray-300 p-2">
      <Link to="/cars/ads?city=Все&page=1" className="text-gray-500 hover:text-gray-900 flex flex-col items-center group">
        <IoHomeOutline className='text-lg text-black/50 group-hover:text-[#6398E7]'/>
        <p className='text-[10px] group-hover:text-[#6398E7]'>Главная</p>
      </Link>
      <Link to="/history" className="text-gray-500 hover:text-gray-900 flex flex-col items-center group">
        <GoHistory className='text-lg text-black/50 group-hover:text-[#6398E7]'/>
        <p className='text-[10px] group-hover:text-[#6398E7]'>История</p>
      </Link>
      <Link to="/myAds" className="text-gray-500 hover:text-gray-900 flex flex-col items-center group">
        <LuFileSpreadsheet className='text-lg text-black/50 group-hover:text-[#6398E7]'/>
        <p className='text-[10px] group-hover:text-[#6398E7]'>Мои объявления</p>
      </Link>
      <Link to="/favourite" className="text-gray-500 hover:text-gray-900 flex flex-col items-center group">
        <FaRegHeart className='text-lg text-black/50 group-hover:text-[#6398E7]'/>
        <p className='text-[10px] group-hover:text-[#6398E7]'>Избранное</p>
      </Link>
      {/* <Link to="/chats" className="text-gray-500 hover:text-gray-900 flex flex-col items-center group">
        <BsChatSquare className='text-lg text-black/50 group-hover:text-[#6398E7]'/>
        <p className='text-[10px] group-hover:text-[#6398E7]'>Чаты</p>
      </Link> */}
      <Link to="/settings" className="text-gray-500 hover:text-gray-900 flex flex-col items-center group">
        <IoSettingsOutline className='text-lg text-black/50 group-hover:text-[#6398E7]'/>
        <p className='text-[10px] group-hover:text-[#6398E7]'>Настройки</p>
      </Link>
    </nav>
  );
};

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUserData] = useState({});

  useEffect(() => {
    // Проверка наличия токена в cookie
    
    const authToken = getCookie('auth_token_admin');
    setIsLoggedIn(!!authToken);
    fetchUserInfo();
  }, [isLoggedIn]);

  // Функция для получения значения cookie по имени
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  const fetchUserInfo = async () => {
    const authToken = document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*=\s*([^;]*).*$)|^.*$/, "$1");
    
    // Отправляем запрос на сервер для получения информации о пользователе
    fetch(`https://avto-pro.kz/api-getUserInfo?token=${authToken}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    })
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            setUserData(data.user[0]);
        } else {
        }
    })
    .catch(error => {
    });
  }

  return (
    <Router>
      <div className="flex flex-col min-h-screen App overflow-y-hidden">
        <Routes>
          <Route path="/:item/:category" element={<MainPage />} />
          <Route path="/:item" element={<AdScreen />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/chats" element={<Chats />} />
          <Route path="/history" element={<HistoryMobile />} />
          <Route path="/myAds" element={<MyAdsMobile />} />
          <Route path="/settings" element={<SettingsMobile/>} />
          <Route path='/favourite' element={<MyFavourite/>}/>
          <Route path='/chats/dialog/:id' element={<Dialog/>}/>
          <Route path='/dialog/:id' element={<StartDialog/>}/>
          <Route path='/privacy-policy' element={<PolicyPage/>}/>
          <Route path='/public-offer' element={<PublicOfferPage/>}/>
          <Route path='/payment' element={<PaymentPage/>}/>
          <Route path='/admin-panel' element={ isLoggedIn ? <AdminPage/> : <AuthAdmin/>}/>
        </Routes>
        { window.location.pathname !== '/admin-panel' && user && <BottomTabs/> }
        { window.location.pathname !== '/admin-panel' && <Navbar/> }
        {/* { window.location.pathname !== '/admin-panel' && <Footer/> } */}
      </div>
    </Router>
  )
}

export default App;
