import NavbarAdmin from "../../ux/NavbarAdmin";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AdminAdsList from "./AdminAdsList";
import AdminUsersList from "./AdminUsersList";
import AdminWriteAd from "./AdminWriteAd";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import AdminTariffs from "./AdminTariffs";

function AdminPage() {
    const [data, setData] = useState([]);
    const [carsCount, setCarsCount] = useState();
    const [trucksCount, setTrucksCount] = useState();
    const [waterCount, setWaterCount] = useState();
    const [totalCount, setTotalCount] = useState();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);

    useEffect(() => {
        const interval = setInterval(fetchData, 1000);
    
        // Возвращаем функцию очистки эффекта
        return () => clearInterval(interval);
    }, [data]);
    

    const fetchData = async () => {
        fetch(`https://avto-pro.kz/api-allAds`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка сети');
                }
                return response.json();
            })
                .then(data => {
                setData(data.allAds);
                setCarsCount(data.carsCount);
                setTrucksCount(data.trucksCount);
                setWaterCount(data.waterCount);
                setTotalCount(data.carsCount + data.trucksCount + data.waterCount);
            })
                .catch(error => {
            });
    }

    return (
        <div className="overflow-y-hidden">
            <NavbarAdmin/>
            { queryParams.tab === 'ads' && <AdminAdsList/> }
            { queryParams.tab === 'users' && <AdminUsersList/> }
            { queryParams.tab === 'writeAd' && <AdminWriteAd/> }
            { queryParams.tab === 'tariffs' && <AdminTariffs/> }
        </div>
    )
};


export default AdminPage;