import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function AdminAdsList() {
    const [data, setData] = useState([]);
    const [carsCount, setCarsCount] = useState();
    const [trucksCount, setTrucksCount] = useState();
    const [waterCount, setWaterCount] = useState();
    const [totalCount, setTotalCount] = useState();
    const [servicesCount, setServicesCount] = useState();
    const [toolsCount, setToolsCount] = useState();

    useEffect(() => {
        const interval = setInterval(fetchData, 1000);
    
        // Возвращаем функцию очистки эффекта
        return () => clearInterval(interval);
    }, [data]);
    

    const fetchData = async () => {
        fetch(`https://avto-pro.kz/api-allAds`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка сети');
                }
                return response.json();
            })
                .then(data => {
                setData(data.allAds);
                setCarsCount(data.carsCount);
                setTrucksCount(data.trucksCount);
                setWaterCount(data.waterCount);
                setServicesCount(data.servicesCount);
                setToolsCount(data.toolsCount);
                setTotalCount(data.carsCount + data.trucksCount + data.waterCount + data.servicesCount + data.toolsCount);
            })
                .catch(error => {
            });
    }

    const blockAd = async (category, id) => {
        try {
            const response = await fetch(`https://avto-pro.kz/api-blockAd/${category}/${id}`, {
                method: 'DELETE'
            });
            
            if (!response.ok) {
                throw new Error('Ошибка сети');
            }
            
            const data = await response.json();
        } catch (error) {
        }
    };

    const turnOnTop = async (category, id) => {
        try {
            const response = await fetch(`https://avto-pro.kz/api-turnOnTop/${category}/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        } catch (error) {
            throw error;
        }
    };

    const turnOffTop = async (category, id) => {
        try {
            const response = await fetch(`https://avto-pro.kz/api-turnOffTop/${category}/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        
        } catch (error) {
            throw error;
        }
    };

    return (
        <div className="overflow-x-auto overflow-y-hidden mt-12">
            <div className="flex flex-row space-x-6 mt-4 justify-center items-center">
                <div className="w-48 h-48 rounded-xl border-[1px] bg-white p-2 flex flex-col justify-center items-center">
                    <p className="text-center text-black/25 font-medium text-xl">Общее кол-во объявлений</p>
                    <p className="text-[#6398E7] text-2xl font-bold">{totalCount}</p>
                </div>
                <div className="w-48 h-48 rounded-xl border-[1px] bg-white p-2 flex flex-col justify-center items-center">
                    <p className="text-center text-black/25 font-medium text-xl">Общее кол-во транспорта</p>
                    <p className="text-[#6398E7] text-2xl font-bold">{carsCount}</p>
                </div>
                <div className="w-48 h-48 rounded-xl border-[1px] bg-white p-2 flex flex-col justify-center items-center">
                    <p className="text-center text-black/25 font-medium text-xl">Общее кол-во лодок</p>
                    <p className="text-[#6398E7] text-2xl font-bold">{waterCount}</p>
                </div>
                <div className="w-48 h-48 rounded-xl border-[1px] bg-white p-2 flex flex-col justify-center items-center">
                    <p className="text-center text-black/25 font-medium text-xl">Общее кол-во грузовиков</p>
                    <p className="text-[#6398E7] text-2xl font-bold">{trucksCount}</p>
                </div>
                <div className="w-48 h-48 rounded-xl border-[1px] bg-white p-2 flex flex-col justify-center items-center">
                    <p className="text-center text-black/25 font-medium text-xl">Общее кол-во оборудований</p>
                    <p className="text-[#6398E7] text-2xl font-bold">{toolsCount}</p>
                </div>
                <div className="w-48 h-48 rounded-xl border-[1px] bg-white p-2 flex flex-col justify-center items-center">
                    <p className="text-center text-black/25 font-medium text-xl">Общее кол-во услуг</p>
                    <p className="text-[#6398E7] text-2xl font-bold">{servicesCount}</p>
                </div>
            </div>
            <div className="w-full flex flex-row justify-between items-center flex-wrap px-16 overflow-y-hidden">
            {data && (
                <>
                    {data.map((item, index) => (
                        <div key={index} className="p-6 border-[1px] bg-white rounded-xl flex flex-row space-x-16 mt-6">
                            <div className="flex flex-row space-x-4">
                                <div className="flex flex-col justify-between">
                                    <img className="bg-[#BDBDBD] h-[70px] w-[70px] rounded-xl object-cover" src={`https://avto-pro.kz/api-carImage/${item.photoPreview1}`} alt="preview1"/>
                                    <img className="bg-[#BDBDBD] h-[70px] w-[70px] rounded-xl object-cover" src={`https://avto-pro.kz/api-carImage/${item.photoPreview2}`} alt="preview2"/>
                                    <img className="bg-[#BDBDBD] h-[70px] w-[70px] rounded-xl object-cover" src={`https://avto-pro.kz/api-carImage/${item.photoPreview3}`} alt="preview3"/>
                                    <img className="bg-[#BDBDBD] h-[70px] w-[70px] rounded-xl object-cover" src={`https://avto-pro.kz/api-carImage/${item.photoPreview4}`} alt="preview4"/>
                                </div>
                                <img className="bg-[#BDBDBD] h-80 w-80 rounded-xl object-cover" src={`https://avto-pro.kz/api-carImage/${item.photoPreview5}`} alt="preview5"/>
                            </div>
                            <div className="flex flex-col">
                                <div className="flex flex-row justify-between w-[355px]">
                                    <div>
                                        <p className="text-xl text-black/25">Название</p>
                                        <p className="text-xl text-black/25">Город</p>
                                        <p className="text-xl text-black/25">Контактные данные</p>
                                        <p className="text-xl text-black/25">Цена</p>
                                        <p className="text-xl text-black/25">В топе</p>
                                        { item.yearOfIssue && (
                                            <p className="text-xl text-black/25">Год выпуска</p>
                                        ) }
                                    </div>
                                    <div>
                                        <p className="text-xl text-black text-right">{item.title}</p>
                                        <p className="text-xl text-black text-right">{item.city}</p>
                                        <p className="text-xl text-black text-right">{item.contactPhone}</p>
                                        <p className="text-xl text-black text-right">{item.price}</p>
                                        <p className="text-xl text-black text-right">{item.isTop === 1 ? 'Да' : 'Нет'}</p>
                                        { item.yearOfIssue && (
                                            <p className="text-xl text-black text-right">{item.yearOfIssue}</p>
                                        ) }
                                    </div>
                                </div>
                                <button onClick={() => blockAd(item.category, item.id)} className="border-2 p-2 border-red-500 w-full rounded-xl group hover:bg-red-500 mt-10">
                                    <p className="text-xl text-red-500 group-hover:text-white">Заблокировать объявление</p>
                                </button>
                                { item.isTop === 1 ? (
                                    <button onClick={() => turnOffTop(item.category, item.id)} className="border-2 p-2 border-[#6398E7] w-full rounded-xl group hover:bg-[#6398E7] mt-4">
                                        <p className="text-xl text-[#6398E7] group-hover:text-white">Отключить ТОП</p>
                                    </button>
                                ) : (
                                    <button onClick={() => turnOnTop(item.category, item.id)} className="border-2 p-2 border-[#6398E7] w-full rounded-xl group hover:bg-[#6398E7] mt-4">
                                        <p className="text-xl text-[#6398E7] group-hover:text-white">Продвинуть в ТОП</p>
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
                </>
            )}
        </div>
    </div>
    )
};

export default AdminAdsList;