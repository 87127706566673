import React, { Component } from 'react';

class PasswordRecovery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            methodRecovery: 'email',
            isNotFoundUser: false
            
        };
        this.setMethodRecovery = this.setMethodRecovery.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
    }

    setMethodRecovery = (method) => {
        this.setState({ methodRecovery: method });
        this.props.handleRecoveryMethod(method);
    };

    componentDidMount() {
        this.props.handleRecoveryMethod(this.state.methodRecovery);
    }

    sendMessage = async () => {
        try {
            const response = await fetch('https://avto-pro.kz/api-passwordRecovery', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ methodRecovery: this.state.methodRecovery, recoveryValue: this.props.recoveryValue })
            });
            const data = await response.json();
            if (data.success) {
                this.props.onToggleMethod('CodeConfrim')
                this.setState({ isNotFoundUser: false });
            } else {
                if (data.message === 'Пользователь с таким email не найден') {
                    this.setState({ isNotFoundUser: true });
                } else {
                    this.setState({ isNotFoundUser: false });
                }
            }
        } catch (error) {
            console.error('Error sending code:', error);
        }
    }


    render() {
        return (
            <div>
                <p className='text-2xl font-semibold'>Восстановить пароль</p>
                <p className='text-xl mt-1'>Выберите способ восстановления</p>
                <div className='w-full flex flex-row justify-center space-x-4 mt-4'>
                    <button onClick={() => this.setMethodRecovery('email')} className={ this.state.methodRecovery === 'email' ? 'border-b-2 border-b-[#6398E7]' : 'border-b-2 border-[#6398E7] opacity-25'}>
                        <p className='text-xl'>Email</p>
                    </button>
                </div>
                { this.state.methodRecovery === 'email' && (
                    <>
                        <div className='mt-4'>
                            <p className='ml-6 text-lg'>Email</p>
                            <div className='bg-[#FAFAFA] w-full rounded-lg p-3 px-6'>
                                <input  onChange={(event) => this.props.onChangeRevoceryValue(event.target.value)} className="outline-none bg-transparent text-xl w-full" placeholder='Введите email'/>
                            </div>
                        </div>
                    </>
                )}
                <button disabled={!this.props.recoveryValue} onClick={() => this.sendMessage()} className={ !this.props.recoveryValue ? 'w-full p-2 mt-6 justify-center bottom-0 items-center flex bg-[#4D89E3] rounded-lg group opacity-50' : 'w-full p-2 mt-6 justify-center bottom-0 items-center flex bg-[#4D89E3] rounded-lg group hover:opacity-50' }>
                    <p className='text-white text-xl'>Отправить код</p>
                </button>
                <button onClick={() => this.props.onToggleMethod('Authorization')}>
                    <p className="text-xl mt-4 text-[#4D89E3] hover:opacity-50">Вернуться к авторизации</p>
                </button>
                { this.state.isNotFoundUser && <p className='text-xl text-red-400 text-center mt-6'>Такого пользователя нет</p> }
            </div>
        )
    }
};

export default PasswordRecovery;