import React,{ Component, useState, useEffect } from 'react';
import Navbar from '../ux/Navbar';
import Categories from '../ux/Categories';
import Selector from '../ux/Selector';
import Cards from '../ux/Cards';
import Pagination from '../ux/Pagination';
import Footer from '../ux/Footer';


function PaymentPage() {
    return (
        <div className='overflow-x-hidden'>
            <div className='mt-24 mobile:px-12 lg:px-28 space-y-4 mb-32'>
                <p className='text-4xl font-medium text-black/80'>Оплата</p>
                <p className='text-2xl font-medium text-black/80'>Способы получения товара</p>
                <p className='text-2xl font-medium text-black/80'>1. Доставка.</p>
                <p className='text-2xl font-medium text-black/25'>
                    Доска осуществляется Арендодателем или же самовывозом Покупателя на ранее обговоренный адрес путем переписки с Арендодателем в мессенджере AutoPro
                </p>
                <p className='text-2xl font-medium text-black/80'>2. Оплата</p>
                <p className='text-2xl font-medium text-black/25'>
                    Оплата осуществляется разными способами:<br/><br/>

                    Наличная - подразумевает под собой передачу денежной суммы, которая указана в объявлении транспортного средства. AutoPro снимает с себя ответсвенность за любые транзакции или денежные передачи вне онлайн-сервиса AutoPro<br/><br/>

                    Безналичная - подразумывает под собой денежный перевод по номеру телефона, по номеру банковской карты. Транзакция на месте при получении арендованного автомобиля или же онлайн транзакция обсуждается между Покупателем и Арендодателем в личной переписке в мессенджере AutoPro
                </p>
            </div>
            <Footer/>
        </div>
    ) 
};

export default PaymentPage;