import React, { Component, useState, useEffect } from 'react';
import { IoArrowBack } from "react-icons/io5";
import { FaGripfire } from "react-icons/fa6";
import TariffModal from './TariffModal';
import ChoosePayments from './ChoosePayments';
import { GoKebabHorizontal } from "react-icons/go";

function MyAds({ onClose, onOpenWriteAd }) {
    const [data, setData] = useState([]);
    const [user, setUser] = useState({});
    const [selectedData, setSelectedData] = useState({});
    const [isOpenChoosePayments, setIsOpenChoosePayments] = useState(false);
    const [isOpenTariffModal, setIsOpenTariffModal] = useState(false);
    const [typePayments, setTypePayments] = useState('');

    useEffect(() => {
        // Получаем значение токена из cookie
        const authToken = document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*=\s*([^;]*).*$)|^.*$/, "$1");
    
        // Отправляем запрос на сервер для получения информации о пользователе
        fetch(`https://avto-pro.kz/api-getUserInfo?token=${authToken}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                setUser(data.user[0]);
                fetchData();
            } else {
            }
        })
        .catch(error => {
        });
    }, [user]);

    const fetchData = async () => {
        fetch(`https://avto-pro.kz/api-myAds/${user.id}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка сети');
                }
                return response.json();
            })
                .then(data => {
                setData(data);
            })
                .catch(error => {
            });
    }

    const moveToTop = async (adId, userId) => {
        try {
            const response = await fetch('https://avto-pro.kz/api-payToTop', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ tariff: 1, price: 1000, userId: userId, adId: adId })
            });

            const data = await response.json();
            if (data.success) {
                window.location.href = data.url; // Редирект на полученный URL
            } else {
                console.error('Ошибка при получении URL для оплаты');
            }
        } catch (error) {
            console.error('Произошла ошибка при отправке запроса к серверу:', error);
        }
    };

    return (
        <div className='py-6 px-8 mobile:w-11/12 xl:w-8/12'>
            <button onClick={() => onClose()} className='w-12 h-12 rounded-full bg-[#4D89E3]/50 absolute z-50 items-center justify-center flex -ml-14 mt-[12%] group hover:opacity-50'>
                <IoArrowBack className='text-xl text-white'/>
            </button>
            <p className='text-2xl text-nowrap font-medium'>Мои объявления</p>
            <div className='space-y-3 overflow-x-hidden h-[400px]'> 
                {data.ads && data.ads.length !== 0 ? (
                    data.ads.map((car, index) => (
                        <div key={index} className='w-full shadow-lg bg-white p-3 rounded-xl'>
                            <div className='mt-2'>
                                <div className='flex flex-row'>
                                    <div className='space-x-2 relative flex flex-row w-48 h-32'>
                                        <img src={`https://avto-pro.kz/api-carImage/${car.photoPreview1}`} className='bg-[#BDBDBD] w-full h-full rounded-xl object-center'/>
                                        { car.isTop && (
                                            <div className="flex flex-row absolute z-[1000] right-0 p-4">             
                                                <div className='w-8 h-8 rounded-full bg-white justify-center items-center flex'>
                                                    <FaGripfire color='#FF3F3F' size={24}/>
                                                </div>
                                            </div>
                                        ) }
                                    </div>
                                    <div className='ml-4'>
                                        <p className='text-xl'>{car.title.length > 10 ? car.title.slice(0, 10) + '...' : car.title}</p>
                                        <p className='text-lg mt-1'>{car.price} тг/день</p>
                                        { car.status === 'Waiting' && (
                                            <button onClick={() => (setIsOpenChoosePayments(true), setSelectedData(car))} className='bg-[#4D89E3] w-32 justify-center items-center flex p-2 rounded-lg mt-6 hover:opacity-50'>
                                                <p className='text-white mobile:text-lg xl:text-xl'>Оплатить</p>
                                            </button>
                                        ) }
                                        { car.status === 'Payed' && (
                                            <div className='flex flex-row items-center space-x-2 mt-6'>
                                                <div className='border-[#4D89E3] border-2 w-32 justify-center items-center flex p-2 rounded-lg'>
                                                    <p className='text-[#4D89E3] mobile:text-lg xl:text-xl'>Оплачено</p>
                                                </div>
                                                { !car.isTop && (
                                                    <button onClick={() => moveToTop(car.id, car.userId)} className='bg-[#4D89E3] w-24 justify-center items-center flex p-2 rounded-lg hover:opacity-50'>
                                                        <p className='text-[#FFF] mobile:text-lg xl:text-xl'>в TOP</p>
                                                    </button>
                                                ) }
                                            </div>
                                        ) }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className='w-full h-full justify-center items-center flex'>
                        <p>Нет объявлений в данном списке</p>
                    </div>
                )}
            </div>
            <button onClick={onOpenWriteAd} className='w-full p-4 bg-[#4D89E3] rounded-xl mt-6 hover:opacity-50'>
                <p className='text-white text-xl font-medium'>Добавить объявление</p>
            </button>
            { isOpenTariffModal && <TariffModal typePayments={typePayments} selectedData={selectedData} onClose={() => setIsOpenTariffModal(false)}/> }
            { isOpenChoosePayments && <ChoosePayments setBalance={() => setTypePayments('Balance')} setOnline={() => setTypePayments('Online')} openTariff={() => setIsOpenTariffModal(true)} onClose={() => setIsOpenChoosePayments(false)}/> }
        </div>
    )
}

export default MyAds;