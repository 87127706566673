import { useState, useEffect } from "react";
import { LuPlus, LuEye } from "react-icons/lu";
import { IoCloseOutline, IoTrashOutline  } from "react-icons/io5";
import { IoMdCheckmark } from "react-icons/io";
import NewsModal from "../../ux/NewsModal";

function AdminWriteAd() {
    const [category, setCategory] = useState('add');
    const [data, setData] = useState([]);

    const [title, onChangeTitle] = useState();
    const [description, onChangeDescription] = useState();
    const [photoPreview, setPhotoPreview] = useState(null);
    const [isSecondPhoto, setIsSecondPhoto] = useState(false);
    const [secondPhoto, setSecondPhoto] = useState(null);
    const [isError, setIsError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const [isOpenNewModal, setIsOpenNewModal] = useState(false);
    const [selectedData, setSelectedData] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        fetch(`https://avto-pro.kz/api-getSalesAndAds`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка сети');
                }
                return response.json();
            })
                .then(data => {
                setData(data.news);
            })
                .catch(error => {
            });
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const name = event.target.name;
        setPhotoPreview(file);
    }

    const handleFileChangePhotoSecond = (event) => {
        const file = event.target.files[0];
        const name = event.target.name;
        setSecondPhoto(file);
    }

    const addNew = async () => {
        if (isSecondPhoto) {
            if (title && description && photoPreview && secondPhoto) {
                setIsError(false);
                
                const formData = new FormData();
                formData.append('title', title);
                formData.append('description', description);
                formData.append('time', getTimeString());
                formData.append('photoPreview', photoPreview);
                formData.append('secondPhoto', secondPhoto);
    
                try {
                    const response = await fetch('https://avto-pro.kz/api-createNewTwoPhoto', {
                        method: 'POST',
                        body: formData,
                    });
    
                    if (!response.ok) {
                        throw new Error('Ошибка при отправке файла на сервер');
                    } 
    
                    const data = await response.json(); // Получаем данные из ответа
    
                    if (data.success) {
                        setIsSuccess(true);
                        onChangeTitle('');
                        onChangeDescription('');
                        setPhotoPreview(null);
                        setIsSecondPhoto(false);
                        setSecondPhoto(null);
                    } else {
                        setIsSuccess(false);
                    }
    
                } catch (error) {
                    console.error(error);
                }
            } else {
                setIsError(true);
            }
        } else {
            if (title && description && photoPreview) {
                setIsError(false);
                setIsError(false);
                
                const formData = new FormData();
                formData.append('title', title);
                formData.append('description', description);
                formData.append('time', getTimeString());
                formData.append('photoPreview', photoPreview);
    
                try {
                    const response = await fetch('https://avto-pro.kz/api-createNewOnePhoto', {
                        method: 'POST',
                        body: formData,
                    });
    
                    if (!response.ok) {
                        throw new Error('Ошибка при отправке файла на сервер');
                    } 
    
                    const data = await response.json(); // Получаем данные из ответа
    
                    if (data.success) {
                        setIsSuccess(true);
                        onChangeTitle('');
                        onChangeDescription('');
                        setPhotoPreview(null);
                    } else {
                        setIsSuccess(false);
                    }
    
                } catch (error) {
                    console.error(error);
                }
            } else {
                setIsError(true);
            }
        }
    };
    
    const getTimeString = () => {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    }

    const removeNew = async (id) => {
        try {
            const response = await fetch(`https://avto-pro.kz/api-removeNew/${id}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                const data = await response.json();
            } else {

            }
        } catch (error) {
        }
    }

    return (
        <div className="w-full h-screen items-center flex flex-col justify-center space-y-4">
            <div className="flex flex-row space-x-6 items-center">
                <button onClick={() => setCategory('add')}>
                    <p className={ category === 'add' ? "text-[#4D89E3] text-xl hover:opacity-50" : "text-[#4D89E3] text-xl opacity-50 hover:opacity-50" }>Новое</p>
                </button>
                <button onClick={() => setCategory('list')}>
                    <p className={ category === 'list' ? "text-[#4D89E3] text-xl hover:opacity-50" : "text-[#4D89E3] text-xl opacity-50 hover:opacity-50" }>Текущие</p>
                </button>
            </div>
            { category === 'add' && (
                <>
                    <p className="text-2xl font-light text-[#000]">Написать Объявление</p>
                    <div className="border-2 border-[#4D89E3] w-96 p-2 px-4 rounded-2xl mt-4">
                        <p className="bg-white px-2 absolute z-10 text-xl text-[#4D89E3] font-light -mt-6">Название</p>
                        <input value={title} onChange={e => onChangeTitle(e.target.value)} className="outline-none bg-transparent text-2xl font-light" placeholder="Введите название"/>
                    </div>
                    <div className="border-2 border-[#4D89E3] w-96 p-2 px-4 rounded-2xl mt-4">
                        <p className="bg-white px-2 absolute z-10 text-xl text-[#4D89E3] font-light -mt-6">Описание</p>
                        <textarea value={description} onChange={e => onChangeDescription(e.target.value)} className="outline-none bg-transparent text-2xl font-light w-full" placeholder="Введите описание"/>
                    </div>
                    <p className="bg-white px-2 text-xl text-[#4D89E3] font-light -mt-6">Фото превью</p>
                    <div className='w-96 h-32 border-[1px] border-[#4D89E3] rounded-lg bg-[#FAFAFA] justify-center items-center flex'>
                        {photoPreview === null ? (
                            <label htmlFor={`photoPreview`}>
                                <LuPlus className='text-black/50' size={32}/>
                                <input
                                    type='file'
                                    id={`photoPreview`}
                                    name={`photoPreview`}
                                    accept='image/*'
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />
                            </label>
                        ) : (
                            <div className="w-full h-full flex justify-center items-center">
                                <img src={URL.createObjectURL(photoPreview)} alt={`Photo Preview`} className='w-full h-full object-cover rounded-lg'/>
                                <button onClick={() => setPhotoPreview(null)} className="bg-white h-10 w-10 justify-center items-center flex rounded-full hover:opacity-50 absolute z-10">
                                    <IoCloseOutline className="text-4xl text-[#4D89E3]"/>
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="flex flex-row space-x-4 items-center">
                        <button onClick={() => setIsSecondPhoto(!isSecondPhoto)} className={ isSecondPhoto ? 'border-2 rounded-md border-[#4D89E3] h-6 w-6 bg-[#4D89E3] hover:opacity-50 flex justify-center items-center' : 'border-2 rounded-md border-[#4D89E3] h-6 w-6 hover:opacity-50 flex justify-center items-center' }>
                            <IoMdCheckmark className="text-xl text-white"/>
                        </button>
                        <p className="text-xl w-80 font-light">Добавить к объявлению вторую фотографию</p>
                    </div>
                    {isSecondPhoto && (
                        <div className='w-96 h-32 border-[1px] border-[#4D89E3] rounded-lg bg-[#FAFAFA] justify-center items-center flex'>
                            {secondPhoto === null ? (
                                <label htmlFor={`secondPhoto`}>
                                    <LuPlus className='text-black/50' size={32}/>
                                    <input
                                        type='file'
                                        id={`secondPhoto`}
                                        name={`secondPhoto`}
                                        accept='image/*'
                                        style={{ display: 'none' }}
                                        onChange={handleFileChangePhotoSecond}
                                    />
                                </label>
                            ) : (
                                <div className="w-full h-full flex justify-center items-center">
                                    <img src={URL.createObjectURL(secondPhoto)} alt={`Second Photo`} className='w-full h-full object-cover rounded-lg'/>
                                    <button onClick={() => setSecondPhoto(null)} className="bg-white h-10 w-10 justify-center items-center flex rounded-full hover:opacity-50 absolute z-10">
                                        <IoCloseOutline className="text-4xl text-[#4D89E3]"/>
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                    { isError && <p className="text-xl text-red-400 font-light">Все поля обязательны к заполнению</p> }
                    { isSuccess && <p className="text-xl text-green-400 font-light">Объявление успешно поля добавлено</p> }
                    <button onClick={() => addNew()} className="w-96 border-[#4D89E3] border-[1px] rounded-xl p-2 flex justify-center items-center group hover:bg-[#4D89E3]">
                        <p className="text-xl text-[#4D89E3] font-light group-hover:text-white">Опубликовать</p>
                    </button>
                </>
            ) }
            { category === 'list' && (
                <div className="w-full overflow-x-auto flex-row flex items-center justify-center flex-wrap">
                    { data.map((item, index) => (
                        <div key={index} className="w-[22vw] relative rounded-xl h-64 m-6 items-center flex justify-center border-[1px] border-[#4D89E3]">
                            <img loading='eager' src={`https://avto-pro.kz/api-newsImage/${item.photoPreview}`} className="w-full h-full rounded-xl bg-[#bdbdbd]"/>
                            <div className="w-96 absolute z-10 bottom-4 rounded-xl bg-white p-3 border-[1px] border-black/25">
                                <div className="flex flex-row items-center justify-between">
                                    <p className="text-xl font-semibold mt-1">{item.title}</p>
                                    <div className="flex flex-row items-center space-x-3">
                                        <div className="flex flex-row items-center space-x-1">
                                            <p className="text-xl text-black/50">{item.time}</p>
                                        </div>
                                        <div className="flex flex-row space-x-1 items-center">
                                            <LuEye className="text-xl text-black/50"/>
                                            <p className="text-xl text-black/50">{item.views}</p>
                                        </div>
                                        <button onClick={() => removeNew(item.id)} className="hover:opacity-50">
                                            <IoTrashOutline className="text-xl text-red-400"/>
                                        </button>
                                    </div>
                                </div>
                                <p className="text-xl text-black/50 font-light">{item.title} <button onClick={() => (setIsOpenNewModal(true), setSelectedData(item))}><p className="text-[#4D89E3] hover:opacity-50">Ещё...</p></button></p>
                            </div>
                        </div>
                    )) }
                </div>
            ) }
            { isOpenNewModal && <NewsModal data={selectedData} onClose={() => setIsOpenNewModal(false)}/> }
        </div>
    )
};

export default AdminWriteAd;
