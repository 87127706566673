import React, { Component, useState, useEffect } from 'react';
import Navbar from '../ux/Navbar';
import { IoImageOutline, IoCloseOutline  } from "react-icons/io5";
import { LuPlus } from "react-icons/lu";
import { FiSend } from "react-icons/fi";
import { Link } from 'react-router-dom';
import ChatsList from './ChatsList';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';

function StartDialog() {
    const [companion, setCompanion] = useState([]);
    const { id } = useParams();
    const location = useLocation();
    const [message, setMessage] = useState();
    const navigate = useNavigate();
    const [userData, setUserData] = useState({});
    const [messageImages, setMessageImages] = useState([null, null, null, null, null]);

    useEffect(() => {
        fetchUserInfo();
    }, [id]);

    const fetchCompanion = async () => {
        try {
            const response = await fetch("https://avto-pro.kz/api-getCompanionInfo", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ user_id: id })
            });

            const data = await response.json();
            if (data.success) {
                setCompanion(data.companion);
            }
        } catch (error) {
            console.error("Ошибка:", error);
        }
    };

    const fetchUserInfo = async () => {
        try {
            const authToken = document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*=\s*([^;]*).*$)|^.*$/, "$1");
    
            // Отправляем запрос на сервер для получения информации о пользователе
            const response = await fetch(`https://avto-pro.kz/api-getUserInfo?token=${authToken}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
    
            if (!response.ok) {
                throw new Error('Ошибка при получении данных о пользователе');
            }
    
            const data = await response.json();
            setUserData(data.user[0]);
            await fetchCompanion();
            
            // Обработка полученных данных
        } catch (error) {
            console.error('Произошла ошибка:', error);
        }
    }

    const sendMessage = async () => {
        if (!messageImages.some(image => image !== null)) {
            try {
                const response = await fetch('https://avto-pro.kz/api-sendMessage', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        user1_id: userData.id,
                        user2_id: id,
                        message: message,
                        time: getTimeString(),
                        date: getDateString()
                    })
                });

                const data = await response.json();

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                } else {
                    const state = { user_id: id }
                    navigate(`/chats/dialog/${data.chat_id}`, { state });
                    window.location.reload();
                }
                
            } catch (error) {
                console.error('There was a problem sending the message:', error);
            }
        } else {
            try {
                const formData = new FormData();
                formData.append('user1_id',  userData.id);
                formData.append('user2_id', id);
                formData.append('message', message);
                formData.append('time', getTimeString());
                formData.append('date', getDateString());

                // Добавляем изображения
                messageImages.forEach((image, index) => {
                    if (image !== null) {
                        formData.append('photos', image);
                    }
                });

                // Отправляем запрос на сервер
                const response = await fetch('https://avto-pro.kz/api-sendMessageWithImages', {
                    method: 'POST',
                    body: formData,
                });

                const data = await response.json();

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                } else {
                    setMessage('');
                    setMessageImages([null, null, null, null, null]);
                    const state = { user_id: id }
                    navigate(`/chats/dialog/${data.chat_id}`, { state });
                    window.location.reload();
                }
                
            } catch (error) {
                console.error('There was a problem sending the message:', error);
            }
        }
    }

    

    const getTimeString = () => {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    }

    const getDateString = () => {
        const now = new Date();
        const day = String(now.getDate()).padStart(2, '0');
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Месяцы в JavaScript начинаются с 0
        const year = now.getFullYear();
        return `${day}.${month}.${year}`;
    }
    
    const handleFileChange = (index, file) => {
        const newImages = [...messageImages];
        newImages[index] = file;
        setMessageImages(newImages);
    };

    const removeImage = (index) => {
        const newImages = [...messageImages];
        newImages[index] = null;
        setMessageImages(newImages);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            // Запускаем метод sendMessage
            sendMessage();
        }
    };

    return (
        <div className="mobile:px-6 sm:px-12 xl:px-52 mt-24 mb-24">
            <div className='flex flex-row space-x-2 items-center'>
                <Link to='/chats' className='hover:opacity-50 mobile:block laptop:hidden'>
                    <IoIosArrowBack className='text-xl'/>
                </Link>
                <p className="text-2xl font-medium">Сообщения</p>
            </div>
            <div className='flex flex-row mt-2  mobile:h-[70vh] laptop:h-[80vh] rounded-2xl border-[1px] border-black/25'>
                <div className='mobile:hidden desktop:block'>
                    <ChatsList/>
                </div>
                { companion[0] && (
                    <div className='dialog w-full h-full flex flex-col rounded-tr-2xl desktop:border-l-[1px] border-black/25'>
                        { companion[0] && (
                            <div className='w-full flex flex-row p-2 px-4 items-center space-x-4 border-b-[1px] border-black/25'>
                                <img src={`https://avto-pro.kz/api-userImage/${companion[0].photo}`} className='text w-14 h-14 rounded-full flex-shrink-0 overflow-hidden'/>
                                <p className='text-xl text-[#0D0D0D] font-medium'>{companion[0] && companion[0].fullname} {companion[0] && companion[0].lastname}</p>
                            </div>
                        ) }
                        <div className='w-full overflow-x-auto h-full flex justify-center items-center flex-grow px-2 rounded-tr-2xl'>
                            <p>Начните своё общение</p>
                        </div>
                        {messageImages.some(image => image !== null) && (
                            <div className='p-2 flex flex-row items-center space-x-2'>
                                {messageImages.map((image, index) => (
                                    <>
                                        {image ? (
                                            <div key={index} className='h-20 w-20 flex justify-center items-center border-[1.5px] border-[#6398E7] border-dashed rounded-2xl relative'>
                                                <img src={URL.createObjectURL(image)} alt={`Photo ${index}`} className='w-full h-full rounded-2xl object-cover'/>
                                                <button onClick={() => removeImage(index)} className='w-6 h-6 bg-[#6398E7]/50 flex justify-center items-center rounded-full hover:opacity-50 absolute z-20'>
                                                    <IoCloseOutline className='text-xl text-[#6398E7]'/>
                                                </button>
                                            </div>
                                        ) : (
                                            <label key={index} className='h-20 w-20 relative flex justify-center items-center border-[1.5px] border-[#6398E7] border-dashed rounded-2xl' htmlFor={`messageImage${index}`}>
                                                <LuPlus className='text-[#6398E7] text-4xl'/>
                                                <input
                                                    type='file'
                                                    id={`messageImage${index}`}
                                                    name={`messageImage${index}`}
                                                    accept='image/*'
                                                    style={{ display: 'none' }}
                                                    onChange={(event) => handleFileChange(index, event.target.files[0])}
                                                />
                                            </label>
                                        )}
                                    </>
                                ))}
                            </div>
                        )}
                        <div className='w-full bg-[#6398E7] flex flex-row p-2 px-4 items-center space-x-4 mobile:rounded-bl-2xl desktop:rounded-bl-none rounded-br-2xl'>
                            {!messageImages.some(image => image !== null) && (
                                <label className='hover:opacity-50' htmlFor={`messageImage`}>
                                    <IoImageOutline className='text-white text-4xl'/>
                                    <input
                                        type='file'
                                        id={`messageImage`}
                                        name={`messageImage`}
                                        accept='image/*'
                                        style={{ display: 'none' }}
                                        onKeyDown={handleKeyDown}
                                        onChange={(event) => handleFileChange(0, event.target.files[0])}
                                    />
                                </label>
                            )}
                            <div className='bg-white p-1 rounded-xl px-4 w-full'>
                                <input value={message} onChange={e => setMessage(e.target.value)} placeholder='Напишите сообщение...' className='text-lg outline-none bg-transparent w-full'/>
                            </div>
                            {message !== '' && (
                                <button onClick={sendMessage} className='hover:opacity-50'>
                                    <FiSend className='text-white text-2xl'/>
                                </button>
                            )}
                        </div>
                    </div>
                ) }
                { !companion[0] && (
                    <div className='w-full h-full border-l-[1px] border-black/25 flex flex-col justify-center items-center space-y-1'>
                        <p className='text-[#6398E7] font-bold text-7xl'>404</p>
                        <p className='font-semibold'>Пользователь был удален или заблокирован</p>
                    </div>
                ) }
            </div>
        </div>
    )
    
};

export default StartDialog;