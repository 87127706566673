import React, { Component } from 'react';
import { IoIosClose } from "react-icons/io";
import Auth from '../ui/Auth';
import PasswordRecovery from '../ui/PasswordRecovery';
import Reg from '../ui/Reg';
import CodeConfrim from '../ui/CodeConfirm';
import PasswordConfrim from '../ui/PasswordConfrim';

class AutorizationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            method: 'Authorization',
            recoveryMethod: '',
            recoveryValue: '',
        };
        this.onToggleMethod = this.onToggleMethod.bind(this);
        this.handleRecoveryMethod = this.handleRecoveryMethod.bind(this);
        this.onChangeRevoceryValue = this.onChangeRevoceryValue.bind(this);
    }

    onToggleMethod = (setMethod) => {
        this.setState({ method: setMethod });
    };

    handleRecoveryMethod = (method) => {
        this.setState({ recoveryMethod: method })
    }

    onChangeRevoceryValue = (value) => {
        this.setState({ recoveryValue: value })
    }

    render() {
        return (
            <div className='fixed top-0 left-0 right-0 bottom-0 bg-black/50 z-[10000] justify-center items-center flex overflow-x-hidden'>
                <div className='bg-white mobile:rounded-none mobile:h-screen xl:h-[600px] xl:rounded-lg mobile:p-3 lg:p-8 mobile:w-screen sm:w-screen xl:w-4/12'>
                    <div className='w-full justify-end flex'>
                        <button onClick={() => this.props.onClose()} className='w-7 h-7 mobile:m-6 laptop:m-0 justify-center items-center bg-black/80 rounded-full flex group hover:opacity-50'>
                            <IoIosClose className='group-hover:opacity-50' size={24} color='#FFF'/>
                        </button>
                    </div>
                    <div className='px-6 py-6'>
                        { this.state.method === 'Authorization' && <Auth onToggleMethod={this.onToggleMethod} /> }
                        { this.state.method === 'ChangePassword' && <PasswordRecovery onChangeRevoceryValue={this.onChangeRevoceryValue} recoveryValue={this.state.recoveryValue} handleRecoveryMethod={this.handleRecoveryMethod} onToggleMethod={this.onToggleMethod}/> }
                        { this.state.method === 'Registration' && <Reg onToggleMethod={this.onToggleMethod} onClose={() => this.props.onClose()}/> }
                        { this.state.method === 'CodeConfrim' && <CodeConfrim recoveryMethod={this.state.recoveryMethod} recoveryValue={this.state.recoveryValue} onToggleMethod={this.onToggleMethod}/> }
                        { this.state.method === 'PasswordConfrim' && <PasswordConfrim recoveryMethod={this.state.recoveryMethod} recoveryValue={this.state.recoveryValue} onToggleMethod={this.onToggleMethod}/> }
                    </div>
                </div>
            </div>
        )
    }
};

export default AutorizationModal;